import React, { useContext } from "react";
import InlineButton from "../components/InlineButton";
import { Link } from "react-router-dom";
import Prompt from "./../components/Prompt";
import { Container, Title, Accordion, createStyles } from "@mantine/core";
import LandingCenterBlock from "../components/LandingCenterBlock";

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingBottom: theme.spacing.xl * 2,
    minHeight: 650,
  },
  title: {
    marginBottom: theme.spacing.sm * 1.5,
  },
  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,
    border: `0px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

export default function Price() {
  const { classes } = useStyles();

  return (
    <Container size="sm" className={classes.wrapper}>
      <div className="LandingCenterBlock" message="OR" />

      <div message="No credit card required" />
      <LandingCenterBlock message="" />
      <LandingCenterBlock message="" />

      {/* Starter Plan */}
      <div className="LandingCenterBlockFrame">
        <LandingCenterBlock message="Starter" />
        <LandingCenterBlock message="" />
        <LandingCenterBlock message="$29/mo" />
        <LandingCenterBlock message="" />
        {/* <LandingCenterBlock
          txtclass="landingCenterSmallBlock"
          message="Up to 3 servers/users"
        /> */}
        <LandingCenterBlock
          txtclass="landingCenterSmallBlock"
          message="1TB transfer *"
        />
        <a href="https://buy.stripe.com/28o8yX33E3XTdOw4gh">
          <Prompt prompt={"BUY NOW"} />
        </a>
      </div>

      {/* Advanced Plan */}
      <div className="LandingCenterBlockFrame">
        <LandingCenterBlock message="Advanced" />
        <LandingCenterBlock message="" />
        <LandingCenterBlock message="$59/mo" />
        <LandingCenterBlock message="" />
        <LandingCenterBlock
          txtclass="landingCenterSmallBlock"
          message="2TB data transfer *"
        />
        <a href="https://buy.stripe.com/00gcPddIi6614dW3ce">
          <Prompt prompt={"BUY NOW"} />
        </a>
      </div>

      {/* Pro Plan */}
      <div className="LandingCenterBlockFrame">
        <LandingCenterBlock message="Pro" />
        <LandingCenterBlock message="" />
        <LandingCenterBlock message="$89/mo" />
        <LandingCenterBlock message="" />
        <LandingCenterBlock
          txtclass="landingCenterSmallBlock"
          message="3TB data transfer *"
        />
        <a href="https://buy.stripe.com/00g02r0Vw0LHfWEaEH">
          <Prompt prompt={"BUY NOW"} />
        </a>
      </div>

      {/* Pro Max Plan */}
      <div className="LandingCenterBlockFrame">
        <LandingCenterBlock message="Pro Max" />
        <LandingCenterBlock message="" />
        <LandingCenterBlock message="$129/mo" />
        <LandingCenterBlock message="" />
        <LandingCenterBlock
          txtclass="landingCenterSmallBlock"
          message="4TB data transfer *"
        />
        <a href="https://buy.stripe.com/6oE9D1dIi2TP4dWeUY">
          <Prompt prompt={"BUY NOW"} />
        </a>
      </div>

      {/* Data Transfer Note */}
      <div className="LandingCenterBlockFrame">
        <LandingCenterBlock
          txtclass="landingCenterSmallBlock"
          message="* For all plans, excess data transfer"
        />
        <LandingCenterBlock
          txtclass="landingCenterSmallBlock"
          message="is billed at $0.02/GiB"
        />
      </div>

      {/* Enterprise Plan */}
      <div className="LandingCenterBlockFrame">
        <LandingCenterBlock message="Enterprise" />
        <Link to="/demorequest">
          <InlineButton name={"Request Call"} />
        </Link>
      </div>
    </Container>
  );
}
