import React from "react";

export default function SelfHosted() {
  return (
    <div className="page" style={{ justifyContent: "center" }}>
      <h2 className="abouttxttitle">Self-hosting resources</h2>

      <p className="abouttxt">
        Self-hosting is the practice of locally hosting and managing
        applications instead of renting from SaaSS providers. This is a list of
        Free Software network services and web applications which can be hosted
        locally. Non-Free software is listed on the Non-Free page.
      </p>
    </div>
  );
}
