import React, { useStyles } from "react";
import LandingCenterBlock from "../components/LandingCenterBlock";
 
import Prompt from "../components/Prompt";
import ReactPlayer from "react-player/youtube";
import { MantineProvider, Code,createStyles, Text } from "@mantine/core";
import { Prism } from '@mantine/prism';

import {
  AppShell,
  Anchor,Space,
  Card,Image,
  Button,Badge,Group,
  Paper,Box,
  Container,
  NavLink,
  Navbar,
  Title,
  Header,
  Aside,
  Footer,
} from "@mantine/core";

import "../App.css";
import { FaBox } from "react-icons/fa";
export default function UrlyUp() {
  const useStyles = createStyles((theme) => ({
    wrapper: {
      paddingTop: theme.spacing.xl * 2,
      paddingBottom: theme.spacing.xl * 2,
      minHeight: 650,
    },

    title: {
      marginBottom: theme.spacing.xl * 1.5,
    },

    item: {
      borderRadius: theme.radius.md,
      marginBottom: theme.spacing.lg,

      border: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[3]
      }`,
    },
  }));
  return (
    <Container size="sm" className={useStyles.wrapper}>
      {/* 
</MyHeader> */}




      <Card shadow="sm" padding="lg" radius="md" withBorder>
      {/* <Card.Section>
        <Image
          src="https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80"
          height={160}
          alt="Norway"
        />
      </Card.Section> */}

 
      <Card  padding="lg" radius="md" >

      <Group position="apart" mt="md" mb="xs">
        <Text weight={700}>Free self-deploy URL with UrlyUp</Text>

        <Badge color="green" variant="light">
          Free
        </Badge>
      </Group>

      <Title sx={(theme) => ({
        textAlign: 'left',
        padding: theme.spacing.sm,
        borderRadius: theme.radius.md,
      })}
      order={5}>Overview</Title>

<Text size="md" color="black">
 UrlyUp is a simple to use debug and deployment service to instantly and securely expose your app's HTTP port to the Internet with a URL to keep.
</Text>

<Title sx={(theme) => ({
        textAlign: 'left',
        padding: theme.spacing.sm,
        borderRadius: theme.radius.md,
      })}order={5}>Simply for developers</Title>


<Text size="md" color="black">
  UrlyUp helps you during development and let you show the site or api you code to the world, with your own TLS terminated static URL. 
 
</Text>
<Space h="md" />
<Text size="md" color="black">
  Due to responses to security risks by SDK vendors such as Apple, more and more project
   require SSL connections to be enabled on a local dev host during development and UrlyUp is an instant solution to this new hurdle.

</Text>



<Title sx={(theme) => ({
        textAlign: 'left',
        padding: theme.spacing.sm,
        borderRadius: theme.radius.md,
      })}order={5}>Simply for self-hosters</Title>


<Text size="md" color="black">
 UrlyUp allows for self-hosting  applications to replace expensive cloud based alternatives more costly to run.<Anchor href="https://github.com/awesome-selfhosted/awesome-selfhosted" target="_blank">
 Check them out here!
    </Anchor>
</Text>



<Title  sx={(theme) => ({
        textAlign: 'left',
        padding: theme.spacing.sm,
        borderRadius: theme.radius.md,
      })}
      order={5}>Install.</Title>


<Text size="md" color="black">
Simply use a terminal or your favorite API tester, or even your web browser to point to https://urlyup.com/free and you will receive: your CurlyURL https url to publish the world and configuration file to active this URL for any device.


</Text>

<Box  sx={(theme) => ({
        textAlign: 'left',
        padding: theme.spacing.sm,
        borderRadius: theme.radius.md,
      })}>
<Prism language="bash">{"curl https://urlyup.com/free"}</Prism>

</Box>
<Space h="md" />
<Text size="md" color="black">
In case there is no error, the JSON API call result will look like:

</Text>
<Space h="md" />
<Box>
<Prism language="json">{`{
  "error": null,
  "url": "XXXX-free.urlyup.com",
  "help": "Use the wireguard configuration...",
  "config": "[Interface]\n# Name = peer4\nAddress..."{
    "error": null,
    "url": "XXXX-free.urlyup.com",
    "help": "Use the wireguard configuration in ...",
    "config": "[Interface]\n# Name = peer4\nAddress..."
  }
}
`}</Prism>
  <Space h="md"></Space>

<Text size="md" color="black">
  The URL points to a private IP and port number that will be accessible only to your UrlyUp URL only after you install Wireguard on your Mac or Windows.
   </Text>
  <Text size="md" color="black">
  Linux already includes WireGuard so no need to install, just copy and paste 
  the conf returned in the JSON inside the wg0.conf file located in:
  <Box>
  <Space h="md"></Space>

<Prism language="bash">/etc/wireguard/wg0.conf</Prism>
<Space h="md"></Space>
</Box>

  and start wireguard with the command: 
  <Space h="md"></Space>
  <Box>
<Prism language="bash">{"wg-quick up wg0"}</Prism>

<Space h="md"></Space>

</Box>

Add the port number and a "-" before your unique ID to expose the specific port to your UrlyUp URL.
<Space h="md"></Space>
 For example to reach port 3000, commonly used by backend framework, the correct URL will be looking like: <Prism language="bash">{"https://3000-R4D5R-free.urlyup.com"}</Prism>
 <Space h="md"></Space>Make sure that the ports you use to listen on your app are not only open but that you only listen on the IP address provided by WireGuard for security reason.
To test you can listen on "0.0.0.0" but this will then expose your port to non secured connections.
<Space h="md"></Space>
Your UrlUp URL will remain active as long as you use it, even if there is not a lot or no traffic to start with.


  </Text>


</Box>






<Title sx={(theme) => ({
        textAlign: 'left',
        padding: theme.spacing.sm,
        borderRadius: theme.radius.md,
      })}
      
      order={5}>Get a free URL from your code</Title>

<Text size="md" color="black">
  Ship your app with its own URL when running the first time. Include the code below to automatically return a new URL so your users can immediately access your app from the Internet when running it on premises.
</Text>
<Space h="md"></Space>

<Title order={6}>Go</Title>

<Prism language="go">{`
package main

import (
	"fmt"
	"io"
	"net/http"
)

func sendRequest() {
	// Request (GET https://urlyup.com/free/)

	// Create client
	client := &http.Client{}

	// Create request
	req, err := http.NewRequest("GET", "https://urlyup.com/free/", nil)

	// Fetch Request
	resp, err := client.Do(req)
	
	if err != nil {
		fmt.Println("Failure : ", err)
	}

	// Read Response Body
	respBody, _ := io.ReadAll(resp.Body)

	// Display Results
	fmt.Println("response Status : ", resp.Status)
	fmt.Println("response Headers : ", resp.Header)
	fmt.Println("response Body : ", string(respBody))
}



`}
</Prism>
<Space h="md"></Space>

<Title order={6}>Python</Title>

      <Prism language="python">{`
      # Install the Python Requests library:
      # pip install requests\`
      
      import requests
      
      
      def send_request():
          # Request
          # GET https://urlyup.com/free/
      
          try:
              response = requests.get(
                  url="https://urlyup.com/free/",
              )
              print('Response HTTP Status Code: {status_code}'.format(
                  status_code=response.status_code))
              print('Response HTTP Response Body: {content}'.format(
                  content=response.content))
          except requests.exceptions.RequestException:
              print('HTTP Request failed')
        `}
</Prism>
<Space h="md"></Space>

<Title order={6}>Java</Title>

      <Prism language="java">{`
import java.io.IOException;
import org.apache.http.client.fluent.*;

public class SendRequest
{
  public static void main(String[] args) {
    sendRequest();
  }
  
  private static void sendRequest() {
    
    // Request (GET )
    
    try {
      
      // Create request
      Content content = Request.Get("https://urlyup.com/free/")
      
      // Fetch request and return content
      .execute().returnContent();
      
      // Print content
      System.out.println(content);
    }
    catch (IOException e) { System.out.println(e); }
  }
}


   `}
</Prism>
<Space h="md"></Space>

<Title order={6}>JS Axios</Title>

      <Prism language="js">{`axios({
	"method": "GET",
	"url": "https://urlyup.com/free/"
})`}</Prism>

<Space h="md"></Space>

<Title order={6}>JS SuperAgent</Title>

      <Prism language="js">{`request
  .get('https://urlyup.com/free/')
  .redirects(0)
  .end(function(err, res){
    if (err || !res.ok) {
      console.log('Oh no! error');
    } else {
      console.log('yay got ' + JSON.stringify(res.body));
    }
  });

`}</Prism>
<Space h="md"></Space>

<Title order={6}>Swift Alamo</Title>

      <Prism language="swift">{`func sendRequestRequest() {
    /**
     Request
     get https://urlyup.com/free/
     */

    // Fetch Request
    Alamofire.request("https://urlyup.com/free/", method: .get)
        .validate(statusCode: 200..<300)
        .responseJSON { response in
            if (response.result.error == nil) {
                debugPrint("HTTP Response Body: \(response.data)")
            }
            else {
                debugPrint("HTTP Request failed: \(response.result.error)")
            }
        }
}

`}</Prism>
<Space h="md"></Space>

<Title order={6}>Swift Moya</Title>

      <Prism language="swift">{`
      //
      //  API.swift
      //  
      //  Generated usign MoyaPaw Export https://github.com/narlei/SwiftMoyaCodeGenerator.git
      
      import Moya
      
      
      
      public enum API {
          case Request
      }
      
      extension API: TargetType {
      
          public var baseURL: NSURL { return NSURL(string: "https://urlyup.com")! }
      
          public var path: String {
              switch self {
              case .Request(let ):
                  return "/free/"
              }
          }
      
          public var method: Moya.Method {
              switch self {
              case .Request:
                  return .get
              }
          }
      
          var task: Task {
              switch self {
              case .Request:
                  return .requestPlain
              }
          }
      
          var headers: [String: String]? {
              return []
          }
      
          public var sampleData: NSData {
              switch self {
              case .Request:
                  return "{}".data(using: String.Encoding.utf8)!
              }
          }
      }
      
      
      
      
      `}</Prism>
<Space h="md"></Space>

<Title order={6}>JS</Title>

<Prism language="js">{`      // request Request 
(function(callback) {
    'use strict';
        
    const httpTransport = require('https');
    const responseEncoding = 'utf8';
    const httpOptions = {
        hostname: 'urlyup.com',
        port: '443',
        path: '/free/',
        method: 'GET',
        headers: {}
    };
    httpOptions.headers['User-Agent'] = 'node ' + process.version;
 
    // Paw Store Cookies option is not supported

    const request = httpTransport.request(httpOptions, (res) => {
        let responseBufs = [];
        let responseStr = '';
        
        res.on('data', (chunk) => {
            if (Buffer.isBuffer(chunk)) {
                responseBufs.push(chunk);
            }
            else {
                responseStr = responseStr + chunk;            
            }
        }).on('end', () => {
            responseStr = responseBufs.length > 0 ? 
                Buffer.concat(responseBufs).toString(responseEncoding) : responseStr;
            
            callback(null, res.statusCode, res.headers, responseStr);
        });
        
    })
    .setTimeout(0)
    .on('error', (error) => {
        callback(error);
    });
    request.write("")
    request.end();
    

})((error, statusCode, headers, body) => {
    console.log('ERROR:', error); 
    console.log('STATUS:', statusCode);
    console.log('HEADERS:', JSON.stringify(headers));
    console.log('BODY:', body);
});`}</Prism>

     </Card>

{/* </Paper>
<Paper shadow="xs" p="md"> */}




      <Button component="a" href="https://docs.setip.io/quickstart/quickstart/" target="_blank" variant="light" color="blue" fullWidth mt="md" radius="md">
      Read the Quick Start
      </Button>
    </Card>
   


    </Container>
  );
}
