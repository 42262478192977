import { React } from "react";
import "../App.css";
import { NavLink, Link } from "react-router-dom";
import { Box, Group } from "@mantine/core";
import InlineButton from "./InlineButton";

// import { SocialShareAndFollow } from 'react-share-follow'
// import '../../node_modules/react-share-follow/dist/SocialShareAndFollow.css'

const support_link =
  "https://mm.setip.io/setipio/channels/setipio-community-help";

export default function NewFooter() {

  return (
    <>
      <Group>
        <Box component="a" href="/privacy">
          privacy
        </Box>

        {/* <Box component="a" href="https://blog.setip.io" target="_blank">
          blog
        </Box> */}

        <Box component="a" href="/support">
          support
        </Box>

        
        <Box component="a" href="https://stats.uptimerobot.com/6lOj7fV1KP">
          uptime
        </Box>

        {/* <a href={`https://kuma.setip.io/status/setipio`} target="_blank">
        {" "}
        <InlineButton name={"Status"} />{" "}
      </a> */}
        <Box>
          <span>
            © Copyright 2021-2023 SETIPIO LLC. - All rights reserved. Patents
            pending. - v.1.2.1.
          </span>
          <span>
            "WireGuard" and the "WireGuard" logo are registered trademarks of
            Jason A. Donenfeld.
          </span>
        </Box>
        {/* <SocialShareAndFollow
        url={'https://setip.io'}
        title='SETIP.IO'
        description='Deploy development environments directly from a dev machine or locally hosted containers. Simply provide easy to remember URLs to locally running full stack development environments.'
        labelShare='Share our page!'
        labelFollow='Follow us for cool updates'
        iconColor='black'
        share={{
          copy: true,
          email: false,
          sms: false,
          facebook: true,
          twitter: true,
          reddit: false,
          pinterest: false,
          linkedin: true
        }}
        follow={{
          //email: ()=>{},//signupEmailFunction,
          web: 'https://blog.setip.io/',
          reddit: 'setipio',
          twitter: 'setip_io',
          instagram: 'setip.io',
          linkedin: 'company/setipio'
        }}
        onShare={({ message }) => window.alert(message)}
      /> */}
        <Box>
          <div>
            <span>
              © Copyright 2021-2023 SETIPIO LLC - NICOLAS FODOR. - All rights reserved. Patents
              pending. - v.1.3.1
            </span>
            <span>
              "WireGuard" and the "WireGuard" logo are registered trademarks of
              Jason A. Donenfeld.
            </span>
          </div>
        </Box>
      </Group>
    </>
  );
}
