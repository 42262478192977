import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext, UserProvider } from "./UserContext";

const superagent = require("superagent");

export default function useAuth() {
  //const { setUser } = useContext(UserContext);
  const [error, setError] = useState(null);

  //set user
  const setUserContext = async () => {
//     return await axios.get("/user").then((res) => {
//       setError(null);
//       //setUser(res.data.currentUser);
//       //setAuth(res.data.currentUser);
//       //let navigate = useNavigate();
// 
//       //navigate('/home');
//     }).catch((err) => {
//       if (err.response && err.response.data) {
//         //setUser(null);
//         setError(err.response.data);
//       }
//     });
  };

  //register user
  const registerUser = (data) => {
    const {
      username,
      email,
      dns,
      password,
      passwordConfirm,
      region,
      vendor_id,
      invite,
      op_mode,
      st_userid,
    } = data;

    debugger;
    superagent
      .post("/prov") // /prov assumes the proxy forwards to the registration endpoint when responding to /prov.
      .send({
        "region_pref_ipio": region,
        "password": password,
        "password_confirm": passwordConfirm,
        "invite": invite,
        "vendor_pref_ipio": vendor_id,
        "email": email,
        "username": username,
        "op_mode": op_mode,
        "dns": dns,
        "region": region,
        "st_userid": st_userid,
      })
      .set("Content-Type", "application/json; charset=utf-8")
      .redirects(0)
      .end(function (err, res) {
        if (err || !res.ok) {
          if (err.response && err.response.data) {
            console.log("ERROR with Registration: " + err.response.data);
            return err.response.data;
          } else return err;
        } else {
          console.log("Registration request ack received.");
          return "OK";

          //navigate('/st_auth');
          //await setUserContext();
        }
      });

    // return axios.post({
    //     "method": "POST",
    //     //"url": "https://nick.homelab.wg0.io/prov",
    //     "url":"/prov",
    //     "headers": {
    //         "Content-Type": "application/json; charset=utf-8"
    //     },
    //     "data": {
    //         "region_pref_ipio": region,
    //         "password": password,
    //         "password_confirm": passwordConfirm,
    //         "invite": invite,
    //         "vendor_pref_ipio": vendor_id,
    //         "email": email,
    //         "username": username,
    //         "op_mode": op_mode,
    //         "dns": dns,
    //         "region": region,
    //     }
    //
    //       }).then(async () => {
    //           console.log("Registration request ack received.")
    //           history.push('/regcomplete');
    //           //await setUserContext();
    //       })
    //       .catch((err) => {
    //           console.log("ERROR with Registration: " + err)
    //           if(err.response && err.response.data){
    //               console.log("ERROR with Registration: " + err.response.data)
    //               return setError( err.response.data );
    //
    //           } else return setError(err);
    //   })

    //             return axios.post(`auth/register`, {
    //                   username,
    //                   email,
    //                   password,
    //                   passwordConfirm,
    //                   dns,
    //                   region,
    //                   vendor_id,
    //                   invite,
    //                   op_mode,
    //
    //                 }).then(async () => {
    //                     console.log("Registration request ack received.")
    //                     history.push('/regcomplete');
    //                     //await setUserContext();
    //                 })
    //                 .catch((err) => {
    //                     console.log("ERROR with Registration.")
    //                     if(err.response && err.response.data){
    //                         console.log("ERROR with Registration: " + err.response.data)
    //                         return setError(err.response.data);
    //
    //                     }
    //             })
  };

  //login user
  const loginUser = async (data) => {
    const { username, password } = data;

    return axios.post("auth/login", {
      username,
      password,
    }).then(async () => {
      await setUserContext();
    }).catch((err) => {
      console.log(err);
      setError(err ? err : "Login error.");
    });
  };

  return {
    registerUser,
    loginUser,
    error,
  };
}
