import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../hooks/UserContext";
import LandingCenterBlock from "../components/LandingCenterBlock";
 
import Prompt from "./../components/Prompt";
import { Container, Title, Accordion, createStyles } from "@mantine/core";
import { leftPaneLinkIndex } from "../recoil_state";

// import timeLineAnimated from "../assets/TimelineAnimated.gif";
const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    minHeight: 650,
  },

  title: {
    marginBottom: theme.spacing.xl * 1.5,
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,

    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

export default function Landing() {
  const { classes } = useStyles();

  const { user } = useContext(UserContext);
  if (user) {
    <Navigate to="/home" />;
  }

  return (
    <Container size="sm" className={classes.wrapper}>
      {/* <div className="LandingCenterBlockFrame">

<LandingCenterBlock message="Cloud based VPN"/>
<LandingCenterBlock txtclass="landingCenterSmallBlock"  message="for"/> 
<LandingCenterBlock txtclass="landingCenterSmallBlock"  message="Devs and DevOps"/> 
<LandingCenterBlock txtclass="landingCenterSmallBlock"  message="from solo to teams."/> 
<LandingCenterBlock txtclass="landingCenterSmallBlock"  message="Blazing fast, secure."/> 


</div>  */}

      {/*<img  className="timeLineAnimated" alt="timeLineAnimated" src={timeLineAnimated}/>*/}

      {/*<LandingCenterBlock message=""/>*/}

      <LandingCenterBlock message="Our Mission" />

      <LandingCenterBlock message="" />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="The cloud had not evolved in the last 20 years and became a highly centralized version of the original Internet."
      />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="Before the Cloud, and foundational to its existence is the global Domain Name Service (DNS) infrastructure, which has been progressively “hijacked” by highly centralized entities (3 global cloud vendors + Cloudflare). 
                  "
      />
      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="Due to the DNS current evolution and concentration, it has been impossible to distribute the cloud the way the Internet has originally been designed to be: A peer to peer network where individuals and corporations have equal access rights."
      />
      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="Instead, by concentrating DNS hosting away from its owner, vendors have locked the most significant technology evolutions. 
                  "
      />
      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="By reimagining the global DNS infrastructure, while remaining compatible with DNS resolvers, we have made the equally distributed Internet possible again."
      />
      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="We added the ability for all of us to rent our unused bandwidth so we can all economically directly benefit from our Internet use, just as cloud vendors do it so far off our backs, consumers and startups, all the same."
      />
      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="This shift is now under government radar globally as massive amounts of power have been transferred from Netizens to ISPs/CPs with very high pricing power.
                  Realizing this strategic trap, the EU has launched a DNS4EU project for vendor-independent DNS resolution.
                  But it won’t be operational before 2025."
      />

      <LandingCenterBlock message="Now!" />

      <LandingCenterBlock message="" />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="Imagine a world where you have your own portable fixed Internet Protocol (IP) address. What happens in this world?
                                     "
      />
      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="
                                     Your Internet traffic (the apps you visit) remains private.
                                     "
      />
      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="
                                     You can self-host your apps and host them where you want.
                                     "
      />
      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="
                                     You can easily give access to your PC/server to whom you wish.
                                     "
      />
      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="
                                     You can safely rent out your PC/server spare capacity (uplink, processing, storage) so others can use your uplink to host their apps.
                                     "
      />
      <LandingCenterBlock message="The Internet we re-create." />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="Our proprietary technology provides all of us with a Point of Contact (PoCs), a combination of code, interface, and fixed IP address that can be placed anywhere, including on existing cloud vendors."
      />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="We re-enable an original Internet, on top or beside the existing Internet, while changing the business model towards a model similar to Airbnb where hosts are also profit-making Internet hosts, and without having to vacate the premises.
                                     "
      />

      <LandingCenterBlock message="Global distributed DNS" />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="A DNS infrastructure we all participate in, to deploy stronger apps globally with less vendor lock."
      />

      <LandingCenterBlock message="Self-hosting" />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="No need for expensive Cloud vendors. You securely host your app where you wish while saving big over cloud costs and benefitting from our crowd-sourced DNS infrastructure to avoid vendor-locking yourself where your spend the most."
      />

      <LandingCenterBlock message="Uplink Market place" />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="Imagine Airbnb for renting out your spare bandwidth capacity. (Roadmap)"
      />

      <LandingCenterBlock message="In summary" />

      <LandingCenterBlock message="" />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="Because you don’t need expensive Cloud vendors anymore, we are cheaper..."
      />
      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="Because decentralization minimizes DDOS risk, we are safer."
      />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="Because the technology doesn’t primarily use centralized server farms, we may also save massive amounts of energy used for cooling these data centers."
      />

      <LandingCenterBlock message="Join the Revolution" />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="Become one of us..."
      />

      <LandingCenterBlock message="" />

      {/*
 <a href='/work'>
     <Prompt prompt={"WORK"}/>
 </a>              
                                    
                <a href='/invest'>
                    <Prompt prompt={"INVEST"}/>
                </a>
                
  */}

      <div className="LandingCenterBlockFrame">
        <LandingCenterBlock message="Try free" />
        <LandingCenterBlock
          txtclass="landingCenterSmallBlock"
          message="14 days. No Credit Card."
        />

        <a href="/st_auth">
          <Prompt prompt={"SIGN UP"} />
        </a>
      </div>
    </Container>
  );
}
