import React from "react";
import styled from "styled-components";
import {CopyToClipboard} from 'react-copy-to-clipboard';

function SaveAction(props) {
  const { saveVal, saveFn, src } = props;

return (
<>



<CopyToClipboard 
  text={"none"}
  onCopy={() => {
    //console.log(tocopy)
  }}>

  <SaveAction1 className="smart-layers-pointers" 
  style={{ backgroundImage: `url(${src})` }}
  >
    dd
  </SaveAction1>
 
</CopyToClipboard>;
</>)
;
}

const SaveAction1 = styled.div`
  width: 27px;
  height: 30px;
  display: left;
  margin-left: 20%;
  margin-top: 5 px;
  transition: all 0.2s ease-in-out;
  background-size: 100% 100%;
  pointer-events: auto;

  &:hover {
    transform: scale(1.1);
  }
`;

export default SaveAction;
