import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { Box, NavLink,Navbar,ScrollArea,Text, Anchor,TextInput, Checkbox, Button, Group } from "@mantine/core";

import { Container, Title, Accordion, createStyles,PasswordInput,Stack,Space,Divider } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import FormInput from "./../components/FormInput";
import CTA from "./../components/CTA";
import Prompt from "./../components/Prompt";
import Error from "./../components/Error";
import { useFormLocal } from "./../hooks/useForm";
import PocketBase from 'pocketbase';
import { useForm } from '@mantine/form';
import {GithubLoginButton} from 'react-social-login-buttons';

import {
  showManageSubState,
  leftPaneLinkIndexState,
  pocketBaseState,
  randomTokenState,
  burgerPaneOpenedState
} from "../recoil_state";

import useAuth from "./../hooks/useAuth";

import {
  useRecoilState,
} from "recoil";




import { UserContext } from "../hooks/UserContext";
import AuthContext from "./../hooks/AuthProvider";

// setAuth({ user, pwd, roles, accessToken });
const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    minHeight: 650,
  },

  title: {
    marginBottom: theme.spacing.xl * 1.5,
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,

    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

export default function Login() {
  const [burgerPaneOpened, setBurgerPaneOpened] = useRecoilState(burgerPaneOpenedState);

  const [leftPaneLinkIndex, setLeftPaneLinkIndex] = useRecoilState(
    leftPaneLinkIndexState
  );
  const [randomToken,setRandomToken] = useRecoilState(randomTokenState);
  const [showManageSub, setShowManageSub] = useRecoilState(showManageSubState);
  const [pocketBase,setPocketBase] = useRecoilState(pocketBaseState);
  const { classes } = useStyles();

  const { setAuth } = useContext(AuthContext);
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const { values, handleChange } = useFormLocal({
    initialValues: {
      login: "",
      password: "",
      termsOfService:true
    },
  });
  let navigate = useNavigate();

  const form = useForm({
    mode: 'controlled',
    initialValues: {
      login: '',
      password:'',
      termsOfService: false,
    },

    validate: {
      // email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  useEffect(() => {
    //userRef.current.focus();
    // debugger;
    // if (pocketBase.authStore == null) {
    //   console.log("No pocketBase");
    //   const pb =  new PocketBase("https://pb1.setip.io");
  
    //   setPocketBase(pb);
    // }
  }, []);
  // useEffect(() => {
  //   setErrMsg("");
  // }, [user, pwd]);

  // useEffect(() => {
  //   //navigate('/home');
  // }, []);

  // const { loginUser, error, setError } = useAuth();

  const handleLogin = async (values) => {
    // e.preventDefault();
    try {
      //await loginUser(values);
      //
      const { login, password,accept } = values;
      const newRandomToken = await getRandomIntInclusive(1000000000000000, 9999999999999999);
      setRandomToken(newRandomToken);
  
      // const pb = new PocketBase('http://173.21.4.9:8090');

      const pb = new PocketBase('https://pb.setip.io');
      
      let authData = await pb.collection('users').authWithPassword(
        login, 
        password
      );
  
      const record = pb.collection('tokens').create({
        userid:pb.authStore.model?pb.authStore.model.id:null,
        access: newRandomToken
      } ).then((res) => {
       console.log(randomToken);
       console.log(res);
      });
      setPocketBase(pb);
      setLeftPaneLinkIndex("url");
      setBurgerPaneOpened(false);
      setShowManageSub(pb.authStore?.isValid);
     
      navigate("/home");

//       const userData = await pocketBase.collection('users').authWithPassword(username, password);
// console.log(userData);
      // return axios
      //   .post("auth/login", {
      //     username,
      //     password,
      //   })


      //   .then(async () => {

      //     await setUserContext();

      //   })
      //   .catch((err) => {

      //     console.log(err);

      //     //setError(err  ? err : "Login error.");
      //   });


    } catch (err) {
      console.log(err);
    }
  };
  const getRandomIntInclusive = async (min, max) => {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled); // The maximum is inclusive and the minimum is inclusive
  }


  return (
    <Container>
<Stack

  direction="column"
  spacing="md"
  align="center"
  justify="center"
  styles={{ marginTop: "50px" }}
>
  <Box>
  
  <GithubLoginButton
  style={{ 
    backgroundColor: 'black', FontFace: 'Roboto', color: 'white', borderRadius: '5px', border: 'none', 
  width: '280px', 
  height: '40px', 
  fontSize: '16px', 
  fontWeight: 'bold', 
  cursor: 'pointer', display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', padding: '0 16px', 
  margin: '0', textDecoration: 'none', 
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', transition: '0.3s', overflow: 'hidden', position: 'relative', zIndex: '0', WebkitTapHighlightColor: 'transparent', WebkitTouchCallout: 'none', WebkitUserSelect: 'none', KhtmlUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none', userSelect: 'none', WebkitAppearance: 'none', MozAppearance: 'none', appearance: 'none', WebkitBoxSizing: 'border-box', MozBoxSizing: 'border-box', boxSizing: 'border-box', WebkitTextDecoration: 'none', textDecoration: 'none', WebkitTransition: '0.3s', transition: '0.3s', WebkitBackfaceVisibility: 'hidden', backfaceVisibility: 'hidden', WebkitPerspective: '1000', perspective: '1000', WebkitFilter: 'blur(0)', filter: 'blur(0)', WebkitFontSmoothing: 'antialiased', fontSmoothing: 'antialiased', WebkitOverflowScrolling: 'touch', overflowScrolling: 'touch', WebkitTouchCallout: 'none', WebkitUserSelect: 'none', KhtmlUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none', userSelect: 'none', WebkitTapHighlightColor: 'transparent', WebkitAppearance: 'none', MozAppearance: 'none', appearance: 'none', WebkitBoxSizing: 'border-box', MozBoxSizing: 'border-box', boxSizing: 'border-box', WebkitTextDecoration: 'none', textDecoration: 'none', WebkitTransition: '0.3s', transition: '0.3s', WebkitTransform: 'translateZ(0)'}}
   text='Login with GitHub'
  onClick={async () => {
   try {
    const newRandomToken = await getRandomIntInclusive(1000000000000000, 9999999999999999);
    setRandomToken(newRandomToken);

    // const pb = new PocketBase('http://173.21.4.9:8090');

    const pb = new PocketBase('https://pb.setip.io');
    let w = window.open()
    let authData = await pb.collection('users').authWithOAuth2({ 
      provider: 'github',
      urlCallback: (url) => {
        // detect mobile device based on useragent or some other special navigator.* field
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

     
          w.location.href = url
   
    },
    });

    const record = pb.collection('tokens').create({
      userid:pb.authStore.model?pb.authStore.model.id:null,
      access: newRandomToken
    } ).then((res) => {
     console.log(randomToken);
     console.log(res);
    });
    setPocketBase(pb);
    setLeftPaneLinkIndex("url");
    setBurgerPaneOpened(false);
    setShowManageSub(pb.authStore?.isValid);
   
    navigate("/home");


} catch (e) { console.log(e); } 
  }} />
  {/* <Space 
 h="md" 
/> */}
<Divider my="md"></Divider>
  </Box>


</Stack>


{/* <NavLink
          label="GitHub"
          // description="Deployment URLs"
          onClick={async () =>   {


            try {
              const newRandomToken = await getRandomIntInclusive(1000000000000000, 9999999999999999);
              setRandomToken(newRandomToken);
          
              // const pb = new PocketBase('http://173.21.4.9:8090');

              const pb = new PocketBase('https://pb1.setip.io');
              let w = window.open()
              let authData = await pb.collection('users').authWithOAuth2({ 
                provider: 'github',
                urlCallback: (url) => {
                  // detect mobile device based on useragent or some other special navigator.* field
                  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  
               
                    w.location.href = url
             
              },
              });
          
              const record = pb.collection('tokens').create({
                userid:pb.authStore.model?pb.authStore.model.id:null,
                access: newRandomToken
              } ).then((res) => {
               console.log(randomToken);
               console.log(res);
              });
              setPocketBase(pb);
              setLeftPaneLinkIndex("url");
              setBurgerPaneOpened(false);
              setShowManageSub(pb.authStore?.isValid);
             
              navigate("/home");


          } catch (e) { console.log(e); } 
          }}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "left",
            padding: theme.spacing.sm,
            borderRadius: theme.radius.md,
            cursor: "pointer",
          
            "&:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[5]
                  : theme.colors.white,
            },
          })}
          
          // icon={<SiCurl />}
        /> */}

<Space h="md" />

<Text align="center" >
  Or
</Text>
<Space h="md" />
<Box maw={340} 
mx="auto"

>

      <form onSubmit={form.onSubmit((values) => {
        handleLogin(values)

      })}>

        <TextInput
          withAsterisk
          label="Email or User name"
          placeholder="Type your email or user name"
          {...form.getInputProps('login')}
        />

<PasswordInput
      label="Password"
      description="Password"
      placeholder="Type your password"
      {...form.getInputProps('password')}
    />
    
       {/* <Checkbox
          mt="md"
          label="I accept the terms of service"
          {...form.getInputProps('termsOfService', { type: 'checkbox' })}
        />  */}

<Space h="md" />
        <Stack align="right" >
        
          <Button 
          variant="filled" 
          color="lime.5"
          size="md"
          type="submit">
            Login
            </Button>

        </Stack>

      </form>
    </Box>
         
    </Container>
  );
}
