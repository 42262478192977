import React from 'react';

export default function LandingCenterBlock(props){
    var finalclass;
    if (!props.txtclass){
         finalclass= "LandingCenterBlock";
    } else {
        finalclass = props.txtclass
    }
    return(
        <span  className={finalclass}>{props.message} </span>
    )
}