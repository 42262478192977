import  {React, useState} from 'react';
import InlineButton from '../components/InlineButton';
import '../App.css';
import axios from 'axios';
import offCircle from '../assets/OffCircle.png';
import onCircle from '../assets/OnCircle@3x.png';
import logoNodejs  from '../assets/nodejs.png';
import logoMongodb  from '../assets/mongodb.png'; 
import logoSystem  from '../assets/system.png'; 
import logoNoCode  from '../assets/nocode.png'; 
import logoMobyjs  from '../assets/Moby-logo.png';
import logoExec  from '../assets/exec@3x.png';
import followLnk from '../assets/icons8-chain.png'

import Mustache from 'mustache';

export default function AppRunners (props) {


        const propedPocState = props.poc_state;
        // const propedPocRuleState = props.poc_rules_state ;

        // const [PocAppState, setPocAppState] = useState({current_ix:0});
        
       // const [error, setError] = useState(null);


    
        const handleAppChange = async (index, e) => {
          //e.preventDefault();
        }
 
 
      async function saveAppRunnerByAppIndex(poc_id,app_index) {
          
        return axios.post(`/poc/apps/${propedPocState["poc-id"]}`,  
        
      {  
        app_home: propedPocState.apps[app_index].app_home,
        git_url: propedPocState.apps[app_index].git_url,
        image:propedPocState.apps[app_index].image,
        name: propedPocState.apps[app_index].name,
        npm_script: propedPocState.apps[app_index].npm_script,
        port: propedPocState.apps[app_index].port,
        type: propedPocState.apps[app_index].type,
        next_action: propedPocState.apps[app_index].property,
        cmd_startup:propedPocState.apps[app_index].cmd_startup,
        app_startup: propedPocState.apps[app_index].app_startup,
      }
       
        ).then(async () => {
            //await setUserContext();
        }).catch((err) => {
            //setError(err.message ? err.message : err);
        })
    
      }
    
    
      async function startAppRunner(name,command,poc_id) {
        
        return axios.post(`/poc/apps/nextaction/${poc_id}`, 
        {
            app_name: name,
            next_action: command,
            poc_id: poc_id
        }).then(async () => {
          //TODO Add confirmation
            //await setUserContext();
        }).catch((err) => {
            //setError(err.response.data);
        })
    
      }

// return(


//         <>
// <div></div>
//         </>
                         
//       );


// const publicURL = propedPocRuleState.map((rule, index) => {
    
//     const ruleLocalHost = rule.name;
   
    
//     if(rule.target) {

//         return  (<p>{ruleLocalHost}</p>);
//     } else {
//          return (<p></p>);
//     }
// });

const appList = propedPocState.apps.map(({ 
  type, 
  ready, 
  name, 
  link,
  memo,
  git_url, 
  port,
  app_home,
  npm_script,
  next_action,
  exec, 
  image ,
  group,
  target,
  order ,
  wait_ms,
  last_action_timestamp,
  next_last_action_rst,
  last_status_timestamp
}, index) => (


<div key={index} className="appManageBlockFrame">

  <div  className="appManageBlockLeft">

                    <div className="divTabTextGroup"> 
                        
                        <div className="divTabTextLabel">Status</div>
                        
                        <div className="circleContainer">
                      {(ready?ready:false) === true ? 
                              <img className="onCircle" alt="On" src={onCircle}/> : 
                                <img className="offCircle" alt="Off" src={offCircle}/>} 
                        </div>
                        

                        </div>


                                            

                        {/* <div className="divTabTextGroup"> 
                        
                        <div className="divTabTextLabel">URL Public</div>
                        <div className="divTabOutlinedNonEdit">{name} </div>
                        <span>{publicURL}</span>

                        <input type='text' className="divTabTextLabel"
                   onChange={()=>{ }}  defaultValue={ `.${propedPocState.dns_name_cust}`} />
                        </div> */}






{type === "exec" &&
                        <>



<div className="divTabTextGroup">


                        <div className="divTabTextLabel">Group</div>
                        <input type="text" value={group} onChange={handleAppChange} />
                        </div>


                        <div className="divTabTextGroup"> 
                        <div className="divTabTextLabel">Exec</div>
                        <input type="text" value={exec} onChange={handleAppChange} />
                        </div>

                        <div className="divTabTextGroup"> 
                        <div className="divTabTextLabel">Target</div>
                        <input type="text" value={target} onChange={handleAppChange} />
                        </div>


                        <div className="divTabTextGroup"> 
                        <div className="divTabTextLabel">Order</div>
                        <input type="text" value={order} onChange={handleAppChange} />
                        </div>



                        <div className="divTabTextGroup"> 
                        <div className="divTabTextLabel">Wait ms</div>
                        <input type="text" value={wait_ms} onChange={handleAppChange} />
                        </div>

                        <div className="divTabTextGroup"> 
                        <div className="divTabTextLabel">Last action</div>
                        <input type="text" value={last_action_timestamp} onChange={handleAppChange} />
                        </div>

                        <div className="divTabTextGroup"> 
                        <div className="divTabTextLabel">Result</div>
                        <input type="text" value={next_last_action_rst} onChange={handleAppChange} />
                        </div>


                        <div className="divTabTextGroup"> 
                        <div className="divTabTextLabel">Result time</div>
                        <input type="text" value={  last_status_timestamp
} onChange={handleAppChange} />
                        </div>

                        <div className="divTabActionButtonGroup"> 
                            <InlineButton name={'Save'} 
                                        handleClick={
                                            async () =>   {
                                                    await saveAppRunnerByAppIndex(propedPocState["poc-id"],index);
                                                    console.log(`REQUEST RECEIVED TO POST APP INFO CHANGE`);                   
                                                }
                                                }   
/> 

                            <InlineButton name={'Run'} 
                                        handleClick={async () =>   {
                                          await startAppRunner(propedPocState.apps[index].name,"start", propedPocState["poc-id"]);
                                          console.log(`REQUEST RECEIVED TO START APP`);                   
                                                             }}   />
                            {/* <InlineButton name={'Stop'} 
                                        handleClick={async () =>   {
                                          await startAppRunner(propedPocState.apps[index].name,"stop", propedPocState["poc-id"]);
                                          console.log(`REQUEST RECEIVED TO STOP APP`);                   
                                                             }} />
                            <InlineButton name={'Restart'} handleClick={async () =>   {
                                          await startAppRunner(propedPocState.apps[index].name,"restart", propedPocState["poc-id"]);
                                          console.log(`REQUEST RECEIVED TO SAVE APP INFO`);                   
                                                             }} /> */}
                            </div>




 
  


                        </>
}

{type === "container" &&

                        
                        <>


                        <div className="divTabTextGroup"> 
                        
                        <div className="divTabTextLabel">Internal Address</div>
                        <div className="divTabOutlinedNonEdit">{Mustache.render(name,propedPocState)} </div>

                        </div>


                        <div className="divTabTextGroup"> 
                        
                        <div className="divTabTextLabel">Name</div>
                        <div className="divTabOutlinedNonEdit">{Mustache.render(name,propedPocState)} </div>

                        </div>

                        <div className="divTabTextGroup"> 
                        
                        <div className="divTabTextLabel">Image</div>
                        <div className="divTabOutlinedNonEdit">{image} </div>

                        </div>


                        <div className="divTabTextGroup"> 
                        <div className="divTabTextLabel">Container Port</div>
                        <input type="text" value={port} onChange={handleAppChange} />
                        </div>

                        <div className="divTabActionButtonGroup"> 
                            <InlineButton name={'Save'} 
                                        handleClick={
                                            async () =>   {
                                                    await saveAppRunnerByAppIndex(propedPocState["poc-id"],index);
                                                    console.log(`REQUEST RECEIVED TO POST APP INFO CHANGE`);                   
                                                }
                                                }   
/> 

                            <InlineButton name={'Start'} 
                                        handleClick={async () =>   {
                                          await startAppRunner(propedPocState.apps[index].name,"start", propedPocState["poc-id"]);
                                          console.log(`REQUEST RECEIVED TO START APP`);                   
                                                             }}   />
                            <InlineButton name={'Stop'} 
                                        handleClick={async () =>   {
                                          await startAppRunner(propedPocState.apps[index].name,"stop", propedPocState["poc-id"]);
                                          console.log(`REQUEST RECEIVED TO STOP APP`);                   
                                                             }} />
                            <InlineButton name={'Restart'} handleClick={async () =>   {
                                          await startAppRunner(propedPocState.apps[index].name,"restart", propedPocState["poc-id"]);
                                          console.log(`REQUEST RECEIVED TO SAVE APP INFO`);                   
                                                             }} />
                            </div>


                        </>

}
{type === "mongodb" &&
                        <>
                        <div className="divTabTextGroup"> 
                        
                        <div className="divTabTextLabel">Name</div>
                        <div className="divTabOutlinedNonEdit">{Mustache.render(name,propedPocState)}  </div>

                        </div>

                        <div className="divTabTextGroup"> 
                        
                        <div className="divTabTextLabel">Type</div>
                        <div className="divTabOutlinedNonEdit"><p>Mongo DB</p> </div>
                        
                        </div>

                        <div className="divTabActionButtonGroup"> 

                            <InlineButton name={'Reset'} handleClick={async () =>   {
                                          await startAppRunner(propedPocState.apps[index].name,"reset", propedPocState["poc-id"]);
                                          console.log(`REQUEST RECEIVED TO SAVE APP INFO`);                   
                                                             }} />
                            </div>


                        </>
}
{type === "nocode" &&
                        <>
                        
                        

                        
                        
                        <div className="divTabTextGroup"> 
                        
                        <div className="divTabTextLabel">Name</div>
                        <div className="divTabOutlinedNonEdit">{Mustache.render(name,propedPocState)}  </div>

                        </div>
                        
                        <div className="divTabTextGroup"> 
                        
                        <div className="divTabTextLabel">Url</div>
                        <div className="divTabOutlinedNonEdit">{Mustache.render(link,propedPocState)}  </div>
                        
                        <div style={{ display: 'inline-block' }}>
                             <a href={Mustache.render(link,propedPocState)} rel="noopener noreferrer" target="_blank"> <img className="followLnk" alt="visit" src={followLnk}/> </a>
                        </div>
                        
                        </div>

                        <div className="divTabTextGroup"> 
                        <div className="divTabTextLabel">Type</div>
                        <div className="divTabOutlinedNonEdit"><p>No Code</p> </div>
                        </div>

                        <div className="divTabTextGroup"> 
                        <div className="divTabTextLabel">Memo</div>
                        <div className="divTabOutlinedNonEdit"><p>{Mustache.render(memo,propedPocState)}</p> </div>
                        </div>
                        

                        
                        
                        <div className="divTabActionButtonGroup"> 

                            <InlineButton name={'Reset'} handleClick={async () =>   {
                                          await startAppRunner(propedPocState.apps[index].name,"reset", propedPocState["poc-id"]);
                                          console.log(`REQUEST RECEIVED TO SAVE APP INFO`);                   
                                                             }} />
                            </div>


                        </>
}
{type === "system" &&
                        <>
                        <div className="divTabTextGroup"> 
                        
                        <div className="divTabTextLabel">Name</div>
                        <div className="divTabOutlinedNonEdit">{Mustache.render(name,propedPocState)}  </div>

                        </div>

                        <div className="divTabTextGroup"> 
                        
                        <div className="divTabTextLabel">Type</div>
                        <div className="divTabOutlinedNonEdit"><p>System</p> </div>
                        
                        </div>

                        <div className="divTabActionButtonGroup"> 

                            <InlineButton name={'Reset'} handleClick={async () =>   {
                                          await startAppRunner(propedPocState.apps[index].name,"reset", propedPocState["poc-id"]);
                                          console.log(`REQUEST RECEIVED TO SAVE APP INFO`);                   
                                                             }} />
                            </div>


                        </>
}



{type === "apprunner" &&
                        <>



                        <div className="divTabTextGroup"> 
                        
                        <div className="divTabTextLabel">Name</div>
                        <div className="divTabOutlinedNonEdit">{Mustache.render(name,propedPocState)}  </div>

                        </div>




                        <div className="divTabTextGroup"> 
                        
                        <div className="divTabTextLabel">DNS Private</div>
                        <div className="divTabOutlinedNonEdit">{Mustache.render(name,propedPocState)} </div>

                        </div>


                            <div className="divTabTextGroup"> 
                            <div className="divTabTextLabel">Git</div>
                            {/* <div className="divTabTextLabel"></div> */}
                            <input type='text' className="divTabTextEditAreaLocked"
                            onChange={()=>{
                              
                            }} 
                            defaultValue={git_url} />
          
                            </div>

                        <div className="divTabTextGroup"> 
                        <div className="divTabTextLabel">App Port</div>
                        <input type="text" value={port} onChange={handleAppChange} />
                        </div>

                        <div className="divTabTextGroup"> 
                        <div className="divTabTextLabel">App Home</div>
                        <input type="text" value={app_home} onChange={handleAppChange} />
                        </div>

                        <div className="divTabTextGroup"> 
                        <div className="divTabTextLabel">NPM Script</div>
                        <input type="text" value={npm_script} onChange={handleAppChange} />
                        </div>

                        <div className="divTabTextGroup"> 
                        <div className="divTabTextLabel">Command</div>
                        <input type="text" value={next_action} onChange={handleAppChange} />
                        </div>

                        <div className="divTabTextGroup"> 
                        <div className="divTabTextLabel">Base</div>

                        <div className="divTabTextEditAreaLocked">{image} </div>

                        </div>

                        <div className="divTabActionButtonGroup"> 
                            <InlineButton name={'Save'} 
                                        handleClick={
                                            async () =>   {
                                                    await saveAppRunnerByAppIndex(propedPocState["poc-id"],index);
                                                    console.log(`REQUEST RECEIVED TO POST APP INFO CHANGE`);                   
                                                }
                                                }   
/> 

                            <InlineButton name={'Start'} 
                                        handleClick={async () =>   {
                                          await startAppRunner(propedPocState.apps[index].name,"start", propedPocState["poc-id"]);
                                          console.log(`REQUEST RECEIVED TO START APP`);                   
                                                             }}   />
                            <InlineButton name={'Stop'} 
                                        handleClick={async () =>   {
                                          await startAppRunner(propedPocState.apps[index].name,"stop", propedPocState["poc-id"]);
                                          console.log(`REQUEST RECEIVED TO STOP APP`);                   
                                                             }} />
                            <InlineButton name={'Restart'} handleClick={async () =>   {
                                          await startAppRunner(propedPocState.apps[index].name,"restart", propedPocState["poc-id"]);
                                          console.log(`REQUEST RECEIVED TO SAVE APP INFO`);                   
                                                             }} />
                            </div>


                        </>
}


 
   </div>


   <div  key={index} className="appManageBlockRight">


   {type === "exec" &&
                        <>
                        <img className="imageLogo" alt="nodejs logo" src={logoExec}/>
                        <div className="appHelpBlock">
                            Set Group
                        </div>
                        <div className="appHelpBlock">
                            Set Exec
                        </div>
                        <div className="appHelpBlock">
                            Set Target
                        </div>
                        <div className="appHelpBlock">
                            Set Order (Pre/Post)
                        </div>

                        <div className="appHelpBlock">
                            Set Wait (ms)
                        </div>
                        <div className="appHelpBlock">
                            Save
                        </div> 
                        <div className="appHelpBlock">
                            Start to queue
                        </div>    
                        <div className="appHelpBlock">
                            Restart queues
                        </div>                      
</>
                     }
{type === "apprunner" &&
                        
                        <>
   <img className="imageLogo" alt="nodejs logo" src={logoNodejs}/>

<div className="appHelpBlock">
Set "Git URL"
</div>

<div className="appHelpBlock">
Set "App Port"

</div>
<div className="appHelpBlock">
Set "NPM Script"

</div>
<div className="appHelpBlock">
Click Start

</div>
<div className="appHelpBlock">
Visit app URL

</div>
</>
   
}

{type === "nocode" &&
                        
<>

<img className="imageLogo" alt="system logo" src={logoNoCode}/>




</>
   
}


{type === "mongodb" &&
                        
<>

<img className="imageLogo" alt="system logo" src={logoMongodb}/>




</>
   
}

{type === "system" &&
                        
<>

<img className="imageLogo" alt="system logo" src={logoSystem}/>




</>
   
}

{type === "container" &&
                        <>
                        <img className="imageLogo" alt="nodejs logo" src={logoMobyjs}/>



</>
                        
                     }


                                                            









    </div>
</div>


  )); 

    return(
            <>



<div className="List">

<div>{appList}</div>
    
    
    
    </div>

         </>
        )

}