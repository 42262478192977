import React from 'react';
import {
    RiAddBoxFill,
    RiAddCircleFill,
    RiAddCircleLine,
    RiPlayListAddLine,
    RiMailOpenLine,
    RiRefreshLine,
    RiSaveLine,
    RiCloseFill
  } from "react-icons/ri";
export default function PopUp(props) {
 const {msg} = props;
        return (
          <div className='popup'>

            <div className='popup_inner'>

            <button className="" onClick={props.closePopup}>
          <RiCloseFill
            style={{ color: "#7b7b7b", width: "30px", height: "30px" }}
          />
        </button>
        
 

            <h4><span>Date: </span>{props.date}</h4>
            ------
            <h4><span>subject: </span>{props.subject}</h4>
            ------
            <h4><span>From: </span>{props.fr_email}</h4>
            ------
            <h4><span>To: </span>{props.to_email}</h4>
            ------
            <h4><span>Attach. #: </span>{props.attachments_count}</h4>
            ------
            <h4><span>Text: </span>{props.message_text}</h4>
            <div>{ props.message_html }</div>
            </div>
          </div>
        )
 
}