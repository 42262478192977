import React from "react";
import styled from "styled-components";
import {CopyToClipboard} from 'react-copy-to-clipboard';

function CopyAction(props) {
  const { src, tocopy } = props;

return <CopyToClipboard 
  text={decodeURI(tocopy)}
  onCopy={() => {
    //console.log(tocopy)
  }}>

  <CopyAction1 className="smart-layers-pointers" 
  style={{ backgroundImage: `url(${src})` }}
  >
    
  </CopyAction1>
 
</CopyToClipboard>;
}

const CopyAction1 = styled.div`
  width: 27px;
  height: 30px;
  display: right;
  margin-left: 60%;
  margin-top: 5 px;
  transition: all 0.2s ease-in-out;
  background-size: 100% 100%;
  pointer-events: auto;

  &:hover {
    transform: scale(1.1);
  }
`;

export default CopyAction;
