import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../hooks/UserContext";
import LandingCenterBlock from "../components/LandingCenterBlock";
 
import Prompt from "./../components/Prompt";
import { Container, Title, Accordion, createStyles } from "@mantine/core";

// import timeLineAnimated from "../assets/TimelineAnimated.gif";
const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    minHeight: 650,
  },

  title: {
    marginBottom: theme.spacing.xl * 1.5,
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,

    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

export default function Product() {
  const { classes } = useStyles();

  return (
    <Container size="sm" className={classes.wrapper}>
      <LandingCenterBlock message="URLs for self-hosters" />

      <LandingCenterBlock message="" />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="Adding Saas subscription plans to cover all ends can skyrocket. With URLs for self-hosters, simply spin  any Saas open source alternatives in-house for a fraction of the cost."
      />

      <LandingCenterBlock message="" />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="Popular Open Source package such as: Supabase, a Firebase alternative or BaseRow, an alternative to AirTable, are ready to use.  Spin your own URL to securely provide public access to open source, or your own in-house apps, just like any Saas service."
      />
      <LandingCenterBlock message="" />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="Ditch per-seat costs for most of your SaaS popular services with Open Source."
      />

      <LandingCenterBlock message="URLs to dev. environments." />

      <LandingCenterBlock message="" />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="Reach code running on a local machine, any port and securely, protected by SSL, with a URL you keep private or provide anyone."
      />

      <LandingCenterBlock message="" />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="Debug and Deploy development environments directly from a dev machine or locally hosted containers. Simply provide easy to remember URLs to locally running full stack development environments."
      />

      <LandingCenterBlock message="Advanced URLs to staging/prod" />

      <LandingCenterBlock message="" />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="Move your containers and workloads, without downtime, anytime, to never suffer from cloud vendor lock again. Works with all cloud vendors, even without, 100% bare metal if wanted."
      />
      <LandingCenterBlock message="" />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="Associate public DNS entries, such as the one you are now visiting, directly and securely, to code running anywhere."
      />

      <LandingCenterBlock message="" />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="You can also handle it all, and enable 'Transparent mode' for direct connectivity trough a dedicated IP address. Flow all UDP and TCP traffic or some ports only."
      />

      <LandingCenterBlock message="Crowd powered DNS" />

      <LandingCenterBlock message="" />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="DNS changes are reflected automatically when editing URLs and paths, instantly, over the globe,  starting from a DNS server you own and a peer infrastructure you can already rely upon."
      />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="Controlling your IP address independantly from your cloud vendors or connectivity provider, but yet on a nearby network, comes with advantages: All developer's accounts all come with an independant IP address this allows each of them to act as primary DNS on multiple cloud vendors and globally for each other."
      />

      <LandingCenterBlock
        txtclass="landingCenterSmallBlock"
        message="Since all developer's accounts all come with an independant IP address this allows each of them to act as primary DNS on multiple cloud vendors and globally."
      />

      <LandingCenterBlock message="" />

      {/* <LandingCenterBlock  message=""/>
  <LandingCenterBlock txtclass="landingCenterSmallBlock" message="Pick any name"/>
  <LandingCenterBlock txtclass="landingCenterSmallBlock" message="under you.wg0.io or your domain.tld"/>
  <LandingCenterBlock txtclass="landingCenterSmallBlock" message="for device's URLs"/>
  
  
  <LandingCenterBlock  message=""/>
  
  <LandingCenterBlock txtclass="landingCenterSmallBlock" message="Magic DNS/SSL."/> 
  <LandingCenterBlock txtclass="landingCenterSmallBlock" message="Zero DNS and SSL knowledge needed."/> 
  
  <LandingCenterBlock  message=""/>
  
  <LandingCenterBlock txtclass="landingCenterSmallBlock" message="Magic URLs."/> 
  <LandingCenterBlock txtclass="landingCenterSmallBlock" message="All devices and ports"/> 
  <LandingCenterBlock txtclass="landingCenterSmallBlock" message="get easy to remember https"/> 
  <LandingCenterBlock txtclass="landingCenterSmallBlock" message="access URLs."/>  */}

      {/* <LandingCenterBlock  message=""/>
  
  <div   message="- Full Class C Wireguard Private WAN."/>
  
  
  
  <LandingCenterBlock  message=""/>
  
  
  
  
  <LandingCenterBlock txtclass="landingCenterSmallBlock" message="- Public gateway with reserved IP address."/> 
  
  
  <LandingCenterBlock txtclass="landingCenterSmallBlock" message=""/> 
  <LandingCenterBlock txtclass="landingCenterSmallBlock" message="- Wireguard encrypted Inbound data free"/> 
  <LandingCenterBlock txtclass="landingCenterSmallBlock" message=""/>  */}
    </Container>
  );
}
