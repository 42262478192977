import { atom, selector } from "recoil";



const authTupleState  = atom({
  key: "authTupleState",
  default: {},
});

const randomTokenState = atom({
  key: "randomTokenState",
  default: '',
});

const pocketBaseState = atom({
  key: "pocketBaseState",
  default: {},
});

const burgerPaneOpenedState = atom({
  key: "burgerPaneOpenedState",
  default: false,
});

const navBarHiddenState
= atom({
  key: "navBarHiddenState",
  default: false,
});



const showManageSubState = atom({
  key: "showManageSubState",
  default: false,
});

const leftPaneLinkIndexState = atom({
  key: "leftPaneLinkIndexState",
  default: "url",
});

const showLeftSideState = atom({
  key: "showLeftSideState",
  default: false,
});

const todoListState = atom({
  key: "todoListState",
  default: [],
});

const todoListFilterState = atom({
  key: "todoListFilterState",
  default: "Show All",
});

const filteredTodoListState = selector({
  key: "filteredTodoListState",
  get: ({ get }) => {
    const filter = get(todoListFilterState);
    const list = get(todoListState);

    switch (filter) {
      case "Show Completed":
        return list.filter((item) => item.isComplete);
      case "Show Uncompleted":
        return list.filter((item) => !item.isComplete);
      default:
        return list;
    }
  },
});

const todoListStatsState = selector({
  key: "todoListStatsState",
  get: ({ get }) => {
    const todoList = get(todoListState);
    const totalNum = todoList.length;
    const totalCompletedNum = todoList.filter((item) => item.isComplete).length;
    let allText = "";
    todoList
      .filter((item) => !item.isComplete)
      .map((item) => (allText = allText + " " + item.text));
    const totalUncompletedNum = totalNum - totalCompletedNum;
    const percentCompleted = totalNum === 0 ? 0 : totalCompletedNum / totalNum;

    return {
      totalNum,
      totalCompletedNum,
      totalUncompletedNum,
      percentCompleted,
      allText,
    };
  },
});

export {
  todoListState,
  todoListFilterState,
  filteredTodoListState,
  todoListStatsState,
  leftPaneLinkIndexState,
  showLeftSideState,
  burgerPaneOpenedState,
  showManageSubState,
  navBarHiddenState,
  pocketBaseState,
  randomTokenState,
  authTupleState

};
