import React from 'react';
import { Container, Title, createStyles } from '@mantine/core';
import ReactAudioPlayer from 'react-audio-player';

const podcasts = [
  {
    title: "Google AI Podcast",
    url: "https://b7.setip.io/SETIP.IO_Podcast.wav"
  }
  // Add more podcasts here
];

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    minHeight: 650,
  },

  title: {
    marginBottom: theme.spacing.xl * 1.5,
  },

  podcastItem: {
    marginBottom: theme.spacing.lg,
  },
}));

export default function Podcasts() {
  const { classes } = useStyles();

  return (
    <Container size="sm" className={classes.wrapper}>
      <Title className={classes.title}>Podcasts</Title>
      {podcasts.map((podcast, index) => (
        <div key={index} className={classes.podcastItem}>
          <h3>{podcast.title}</h3>
          <ReactAudioPlayer
            src={podcast.url}
            controls
          />
        </div>
      ))}
    </Container>
  );
}
