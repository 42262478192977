import React from "react";
import LandingCenterBlock from "../components/LandingCenterBlock";
import Prompt from "./../components/Prompt";
import ReactPlayer from "react-player/youtube";
import "../App.css";
export default function UseCases() {
  return (
    <div className="page" style={{ justifyContent: "center" }}>

      <div className="LandingCenterBlockFrame">
        <LandingCenterBlock message="Cloud IP Address" />
        <LandingCenterBlock message="Transparent mode" />
        <LandingCenterBlock
          txtclass="landingCenterSmallBlock"
          message=""
        ></LandingCenterBlock>
        <LandingCenterBlock
          txtclass="landingCenterSmallBlock"
          message="Allow public access to code running
           in container as if your dev machine had a public IP."
        />
        <ReactPlayer
          url="https://youtu.be/4DveF1Tom3c"
          width="100%"
          height="100%"
          controls={true}
          config={{
            youtube: {
              playerVars: {
                showinfo: 1,
                fs: 1,
              },
            },
          }}
        />
        <a href="st_auth">
          <Prompt prompt={"LEARN MORE"} />
        </a>
      </div>

      <div className="LandingCenterBlockFrame">
        <LandingCenterBlock message="Cloud IP Address" />
        <LandingCenterBlock message="Auto Proxy/SSL/DNS" />
        <LandingCenterBlock
          txtclass="landingCenterSmallBlock"
          message=""
        ></LandingCenterBlock>
        <LandingCenterBlock
          txtclass="landingCenterSmallBlock"
          message="Allow public access to code running
           in container as if your dev machine had a public IP. SSL and DNS wrapped in, no sweat."
        />
        <ReactPlayer
          url="https://www.youtube.com/watch?v=mxfLW8-iHHQ"
          width="100%"
          height="100%"
          controls={true}
          config={{
            youtube: {
              playerVars: {
                showinfo: 1,
                fs: 1,
              },
            },
          }}
        />
        <a href="/st_auth">
          <Prompt prompt={"LEARN MORE"} />
        </a>
      </div>

      <div className="LandingCenterBlockFrame">
        <LandingCenterBlock message="Magic DNS to localmachine." />
        <LandingCenterBlock
          txtclass="landingCenterSmallBlock"
          message=""
        ></LandingCenterBlock>
        <LandingCenterBlock
          txtclass="landingCenterSmallBlock"
          message="Learn how to use setip.io admin panel to simply create a custom URL with your domain name so it will point to an app running on any of your private network peers. This is an example where the setip.io peer is configured on your laptop."
        />

        <ReactPlayer
          url="https://youtu.be/LEuiaQDEi20"
          width="100%"
          height="100%"
          controls={true}
          config={{
            youtube: {
              playerVars: {
                showinfo: 1,
                fs: 1,
              },
            },
          }}
        />
        <a href="/st_auth">
          <Prompt prompt={"LEARN MORE"} />
        </a>
      </div>

      <div className="LandingCenterBlockFrame">
        <LandingCenterBlock message="Sync Stripe with Mongodb" />
        <LandingCenterBlock
          txtclass="landingCenterSmallBlock"
          message=""
        ></LandingCenterBlock>
        <LandingCenterBlock
          txtclass="landingCenterSmallBlock"
          message="Expose webhooks without code to a private Mongodb database with public URLs."
        />
        <LandingCenterBlock
          txtclass="landingCenterSmallBlock"
          message="Build your own private data zap fast."
        />

        <ReactPlayer
          url="https://youtu.be/5k4UhwvThiY"
          width="100%"
          height="100%"
          controls={true}
          config={{
            youtube: {
              playerVars: {
                showinfo: 1,
                fs: 1,
              },
            },
          }}
        />
        <a href="/st_auth">
          <Prompt prompt={"LEARN MORE"} />
        </a>
      </div>

      {/* 
<div className="LandingCenterBlockFrame">
           <LandingCenterBlock message="Deploy server apps anywhere"/>
           <LandingCenterBlock txtclass="landingCenterSmallBlock" message=""></LandingCenterBlock>
           <LandingCenterBlock txtclass="landingCenterSmallBlock" message="Deploy apps in containers running on multiple cloud vendors off one private network..."/>

           <a href='st_auth'>
<Prompt prompt={"LEARN MORE"}/>
</a>

</div>

<div className="LandingCenterBlockFrame">
           <LandingCenterBlock message="Instant private network"/>
           <LandingCenterBlock txtclass="landingCenterSmallBlock" message=""></LandingCenterBlock>
           <LandingCenterBlock txtclass="landingCenterSmallBlock" message="Connect remote workers on your encrypted private network 
           over their home Wifi, or while on the move..."/>

           <a href='/st_auth'>
<Prompt prompt={"LEARN MORE"}/>
</a>
</div>


<div className="LandingCenterBlockFrame">
           <LandingCenterBlock message="Layer 2"/>

           <LandingCenterBlock txtclass="landingCenterSmallBlock" message=""></LandingCenterBlock>
           <LandingCenterBlock txtclass="landingCenterSmallBlock" message="Leverage existing connectivity to deploy layer 2 Ethereum nodes outside centralized cloud platforms."/>
           <a href='/register'>
<Prompt prompt={"LEARN MORE"}/>
</a>
</div>

<div className="LandingCenterBlockFrame">
           <LandingCenterBlock message="Zero Trust Access/Push"/>
           <LandingCenterBlock message="Mobile/Edge"/>

           <LandingCenterBlock txtclass="landingCenterSmallBlock" message=""></LandingCenterBlock>
           <LandingCenterBlock txtclass="landingCenterSmallBlock" message="Directly reach devices connected on public mobile networks from your backend."/>


           <a href='/st_auth'>
<Prompt prompt={"LEARN MORE"}/>
</a>
</div> */}

      {/* <div className="DemoCenterBlockFrame">
<LandingCenterBlock message="Allow public access to code running on localmachine." />
<LandingCenterBlock txtclass="landingCenterSmallBlock"   message=" As a web backend developer, it is common " />
<LandingCenterBlock txtclass="landingCenterSmallBlock"   message="requirement to expose ports on a dev" />
<LandingCenterBlock txtclass="landingCenterSmallBlock"   message="machine to test with how other machines over" />

<LandingCenterBlock txtclass="landingCenterSmallBlock"   message=" the Internet would connect to code once it will run in production." />
</div> */}
    </div>
  );
}
