import "./App.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { MantineProvider, Global } from "@mantine/core";
import { StrictMode } from "react";
import { leftPaneLinkIndexState, showLeftSideState } from "./recoil_state";
// import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';


import {
  AppShell,
  Navbar,
  Box,
  Header,
  Aside,
  Footer,
  NavLink,
} from "@mantine/core";
import { ActionIcon, Button, Group, rem } from "@mantine/core";
import { Text, MediaQuery, Burger, useMantineTheme } from "@mantine/core";
import { leftPaneLinkIndex } from "./recoil_state";

import * as reactRouterDom from "react-router-dom";
// import { hookstate, useHookstate } from "@hookstate/core";
import { Notifications } from "@mantine/notifications";


import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  Switch,
  useLocation,
} from "react-router-dom";
// import { UserContext } from "./hooks/UserContext";
import { SetIPIORoot } from "./components/SetIPIORoot";
import {
  RecoilRoot,
  useRecoilState,
} from "recoil";

import { useState } from "react";


import {
  IconDashboard,
  IconFileText,
  IconHome,
  IconSearch,
} from "@tabler/icons-react";



const actions = [
  {
    title: "Home",
    description: "Get to home page",
    onTrigger: () => console.log("Home"),
    icon: <IconHome size="1.2rem" />,
  },
  {
    title: "Dashboard",
    description: "Get full information about current system status",
    onTrigger: () => console.log("Dashboard"),
    icon: <IconDashboard size="1.2rem" />,
  },
  {
    title: "Documentation",
    description: "Visit documentation to lean more about all features",
    onTrigger: () => console.log("Documentation"),
    icon: <IconFileText size="1.2rem" />,
  },
];

const support_link =
  "https://mm.setip.io/setipio/channels/setipio-community-help";
const docs_link = "https://docs.setip.io/quickstart/quickstart/";

/* const commands = [{
    name: "Deploy",
    command() {
      console.log("deploy")

    }
  },{
    name: "Docs",
    command() {
      let a= document.createElement('a');
      a.target= '_blank';
      a.href= 'https://docs.setip.io/quickstart/quickstart/';
      a.click();

    }
  }

 ];

 //const container = document.getElementById('root');
 //const root = createRoot(container);
 const root = createRoot(
  document.getElementById('root')
);
 root.render
 (<CommandPalette

  header="Type a command to jump directly to it wihtout using the UI."
  hotKeys="command+option+p" commands={commands} />) */



Sentry.init({
  dsn: "https://a9e3452f7f964d6c90c78ae65b3fec5f@o1353992.ingest.sentry.io/4505557986639872",
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    // if (event.exception) {
    //   Sentry.showReportDialog({ eventId: event.event_id });
    // }
    return event;
  },
  integrations: [
    // new BrowserTracing(),
    //  Sentry.feedbackIntegration(
    //   {
    //     colorScheme: "system",
    //     xlocation: "middle-right",
    //   }
    // )
    // new Sentry.Feedback({

    //   // Additional SDK configuration goes in here, for example:
    //   colorScheme: "system",
    //   location: "middle-right",
    // }),
  ],
});

// Sentry.init({
//   dsn: "https://d9516a17081a43a3946376c11757f6f2@o1353992.ingest.sentry.io/6637073",
//   integrations: [new BrowserTracing()],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

// const defaultOptions1 = {
//   loop: true,
//   autoplay: true,
//   animationData: location.default,
//   rendererSettings: {
//     preserveAspectRatio: "xMidYMid slice",
//   },
// };

// const defaultOptions2 = {
//   loop: true,
//   autoplay: true,
//   animationData: success.default,
//   rendererSettings: {
//     preserveAspectRatio: "xMidYMid slice",
//   },
// };

function App() {
  //const { user, setUser, isLoading } = useFindUser();

  //const globalState = hookstate({});
  //globalState.set(p => p + 1)

  // window.Intercom("boot", {
  //   api_base: "https://api-iam.intercom.io",
  //   app_id: "kiouvbiv",
  // });
  // const useUserStore = create((set) => ({
  //     loggedIn: false,
  //     //increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
  //     //removeAllBears: () => set({ bears: 0 }),
  //   }));

  const theme = useMantineTheme();



 

  if (false) {
    return <></>;
  } else {
    return (
      <StrictMode>
        <RecoilRoot>
          <SetIPIORoot></SetIPIORoot>
        </RecoilRoot>
      </StrictMode>
    );
  }
}

export default App;
