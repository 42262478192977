import React, { useState, useEffect, useCallback } from "react";
import { useReactToPrint } from 'react-to-print';
import {
  ActionIcon, Tooltip, rem,
  Modal,
  CopyButton,
  Button,
 Space,
  Group,
  Table,
  TextInput,
  Textarea,
  Box,
  Text,
  ScrollArea,
  Checkbox,
  Divider,
} from "@mantine/core";
import QRCode from "react-qr-code";
import { leftPaneLinkIndex } from "../recoil_state";
import { IconCopy, IconCheck } from '@tabler/icons-react';

//import { PocContext } from './../hooks/PocContext';
// import sslON from '../assets/sslON.png'
// import sslOFF from '../assets/sslOFF.png'
// import onCircle from '../assets/OnCircle.png'
// import offCircle from '../assets/OffCircle.png'
// import followLnk from '../assets/icons8-chain.png'
// import { Link } from 'react-router-dom';

import { useForm } from "@mantine/form";

import {
  //RiAddBoxFill,
  RiAddCircleFill,
  //RiAddCircleLine,
  //RiEditFill,
  RiEditLine,
  //RiPlayListAddLine,
  RiRefreshLine,
  RiDeleteBinLine,
} from "react-icons/ri"; //https://react-icons.github.io/react-icons/icons?name=ri

const gridStyle = {
  //minWidth: 100,
  height: "80vh",
  width: "100%",
  //marginTop: 20,

  backgroundColor: "#fcf7f3",
};

const columnsX = [
  {
    field: "host",
    id: "host",
    name: "host",
    headerName: "URL Host",
    width: 150,
    editable: true,
    valueParser: (value, params) => {
      console.log("Host value is now: " + value.toLowerCase());
      return value.toLowerCase();
    },
    // headerClassName: 'super-app-theme--header',
    // headerAlign: 'center',
    //
  },
  {
    field: "link",
    id: "link",
    name: "link",
    headerName: "",
    width: 30,
    editable: true,
    valueParser: (value, params) => {
      console.log("Link value is now: " + value.toLowerCase());
      return value.toLowerCase();
    },
  },
  {
    field: "filter",
    id: "filter",
    name: "filter",
    headerName: "URL path",
    width: 150,
    editable: true,
    valueParser: (value, params) => {
      console.log("Filter value is now: " + value.toLowerCase());
      return value.toLowerCase();
    },
  },
  {
    field: "target",
    id: "target",
    name: "target",
    headerName: " -> Origin",
    width: 350,
    editable: true,
    valueParser: (value, params) => {
      //This is to reflect the grid editing to the value for the POC rules
      console.log(
        params.id +
          ": Link value is now: " +
          value.toLowerCase() +
          " params " +
          JSON.stringify(params)
      );

      return value.toLowerCase();
    },
  },
  {
    //field: 'action',
    id: "action",
    name: "action",
    headerName: "QR",
    width: 150,
    editable: true,
    valueParser: (value, params) => {
      //This is to reflect the grid editing to the value for the POC rules
      console.log(
        params.id +
          ": Link value is now: " +
          value.toLowerCase() +
          " params " +
          JSON.stringify(params)
      );

      return value.toLowerCase();
    },
  },
  {
    //field: 'action',
    id: "edit",
    name: "edit",
    headerName: "Edit",
    width: 60,
    editable: false,
    valueParser: (value, params) => {
      //This is to reflect the grid editing to the value for the POC rules
      console.log(
        params.id +
          ": Edit value is now: " +
          value.toLowerCase() +
          " params " +
          JSON.stringify(params)
      );

      return value.toLowerCase();
    },
  },
];

export default function PocTable(props) {
  const [QROpened, setQROpened] = useState(false);
  const [RuleEditing, setRuleEditing] = useState(false);

  const [RuleDeleting, setRuleDeleting] = useState(false);

  const [EditedRuleKey, setEditedRuleKey] = useState({});

  const [QRCodeModalURL, setQRCodeModalURL] = useState("");

  const [dataSource, setDataSource] = React.useState([]);

  const [lastEdit, setLastEdit] = useState(null);
  const { rules, addRule } = props;
  const [gridRef, setGridRef] = useState(null);

  const [URLCheck, setURLCheck] = React.useState(false);

 

  const form = useForm({
    initialValues: {
      host: "",
      path: "",
      target: "",
      notes: "",
      urlcheck: "",
    },

    // validate: {
    //     host: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid host name'),
    //     path: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid path'),
    //     target: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid target'),
    // },
  });

  // const onEditComplete = useCallback(
  //   ( { value, columnId, rowIndex } ) => {
  //     console.log("on edit complete");
  //       const data = [...dataSource];
  //       data[rowIndex][columnId] = value;
  //       setDataSource(data)
  //   },
  //   [dataSource]
  //   )

  useEffect(() => {
    if (!rules) {
      console.log("no rules");
    } else {
      setDataSource(rules);
    }
  }, [dataSource, rules]);

  async function setURLCheckVal(val, props) {
    setURLCheck(props.urlcheck);
  }



  function returnStaticSiteScript(target){
      return `
      #!/usr/bin/env sh
      zipLocation=FULL_PATH_TO_WHERE_TO_STORE_GENERATED_ZIP_FILE_REQUIRED_HERE
      JWT=${props.deploy_key}

      # uncomment below if needed.
      #source ./env.local
      
      # build command below, change with your own.
      yarn build
      
      cd $buildDirectory

      #zip files in build directory and preserve the structure.
      zip -r -D $zipLocation *
      
      # upload files
      curl --location 'https://${target}.${props.dns_name_cust?props.dns_name_cust:props.dns_name}/upload' \
      --header 'Authorization: Bearer \${JWT}' \
      --form files=@\${zipLocation}
         
`
  }
  async function addToRuleBuffer() {



    /*
      1.Pull rules stored in component props to a buffer.
      2.Edit the buffer by adding empty rule.
      3.Save back buffer to rules through the setRules passed in promise.then(value => {
        component props.setRules(buffer)
      })
      4.Move the grid selection to the new empty row at the top.
      */

    //1.
    const localBuffer = props.rules;

    //2.
    localBuffer.unshift({
      ssl: "",
      host: "",
      target: "",
      ipsource: "*",
      filter: "",
      notes: "",
      urlcheck: false,
    });

    //3.
    props.setRules(localBuffer);

    setEditedRuleKey({
      ssl: "",
      host: "",
      filter: "",
      target: "",
      ipsource: "*",
      notes: "",
      urlcheck: false,
    });

    form.setValues({
      ssl: "",
      host: "",
      filter: "",
      target: "",
      ipsource: "*",
      notes: "",
      urlcheck: false,
    });

    setRuleEditing(!RuleEditing);

    //const index = 0;

    //gridRef.refresh();
    //
    // gridRef.current.selectRow (0,true,true);
    // const selected = gridRef.current.isRowSelected(index)
    // gridRef.current.focus()
    // gridRef.current.setRowSelected(index, !selected)
  }
  let rows = [];

  if (dataSource && dataSource.length > 0) {

    
    
    
    rows = dataSource.map((element) => (

      <tr key={element.host + element.filter + element.ipsource}>
        <td>
          <div>
            <button
              className=""
              onClick={() => {
                setEditedRuleKey({
                  host: element.host,
                  filter: element.filter,
                  target: element.target,
                  ipsource: element.ipsource,
                  notes: element.notes,
                  urlcheck: element.urlcheck,
                  repourl: element.repourl,
                });

                form.setValues({
                  host: element.host,
                  filter: element.filter,
                  target: element.target,
                  ipsource: element.ipsource,
                  notes: element.notes,
                  repourl: element.repourl,
                  urlcheck: element.urlcheck,
                });

                setRuleEditing(!RuleEditing);
              }}
            >
              <RiEditLine
                style={{ color: "#7b7b7b", width: "30px", height: "30px" }}
              />
            </button>
          </div>
        </td>
        <td>
          <div>
            <button
              className=""
              onClick={() => {
                form.reset();
                setRuleDeleting(!RuleDeleting);
                setEditedRuleKey({
                  host: element.host,
                  filter: element.filter,
                  target: element.target,
                  ipsource: element.ipsource,
                  urlcheck: element.urlcheck,
                  notes: element.notes,
                  repourl: element.repourl,
                });
              }}
            >
              <RiDeleteBinLine
                style={{ color: "#7b7b7b", width: "30px", height: "30px" }}
              />
            </button>
          </div>
        </td>


        <td >
          <span style={{"margin--left":"30px","margin--right":"30px"}} >{element.ipsource}</span>
        </td>

        <td>
          <span>{element.host}</span>
        </td>
        <td>
          <span>{element.filter}</span>
        </td>

        <td>
        <Group>
          <span>{element.target}</span>   
          
{ (
  // true
  ['b1','b2','b3','b4','b5'].indexOf(element.target)>-1
  )
   ?
<>
          {/* <CopyButton value="ls"> */}
     

  
           <CopyButton value={returnStaticSiteScript(element.target)} timeout={2000}>
           {({ copied, copy }) => (

             <Tooltip label={copied ? 'Copied install script' : 'Copy upload script for shell and CI/CD'} withArrow position="right">
               <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                 {copied ? (
                   <IconCheck style={{ width: rem(16) }} />
                 ) : (
                   <IconCopy style={{ width: rem(16) }} />
                 )}
               </ActionIcon>
             </Tooltip>
           )}
         </CopyButton>
            
      
    {/* </CopyButton> */}
    </>
    :
    <></>
  }
 </Group> 
        </td>
        <td>
          <div
            style={{
              // height: "auto",
              // margin: "0 auto",
              maxWidth: 280,
              width: "100%",
            }}
          >
            <QRCode
              onClick={() => {
                setQROpened(true);
                setQRCodeModalURL("https://" + element.host + element.filter);
              }}
              size={128}
              style={{ height: "80px", maxWidth: "100%", width: "100%" }}
              value={"https://" + element.host + element.filter}
              title={"https://" + element.host + element.filter}
              level="Q"
              viewBox={`0 0 280 280`}
            />
          </div>
        </td>
      </tr>
    ));
  }

  return (
    <div>
      <Modal
        opened={RuleEditing}
        onClose={() => setRuleEditing(false)}
        title="DNS name to Target rule"
      >
        <Box sx={{ maxWidth: 600 }} mx="auto">
          <form
            onSubmit={form.onSubmit((values) => {
              console.log("Saving");
              {
                //const theSource =
                let netData = [];
                if (EditedRuleKey) {
                  for (const sample in dataSource) {
                    if (
                      dataSource[sample].host === "" &&
                      dataSource[sample].filter === "" &&
                      dataSource[sample].target === "" &&
                      dataSource[sample].ipsource === "" &&
                      dataSource[sample].notes === "" &&
                      dataSource[sample].urlcheck === "" &&
                      dataSource[sample].repourl === ""
                    ) {
                      dataSource[sample].host = values.host;
                      dataSource[sample].filter = values["filter"]
                        ? values["filter"]
                        : "/";
                      dataSource[sample].target = values.target
                        ? values.target
                        : "";
                      dataSource[sample].ipsource = values.ipsource
                        ? values.ipsource
                        : "*";
                      dataSource[sample].notes = values.notes
                        ? values.notes
                        : "";
                      dataSource[sample].urlcheck = values.urlcheck
                        ? values.urlcheck
                        : false;
                      dataSource[sample].repourl = values.urlrepourlcheck
                        ? values.repourl
                        : "";
                      //netData.push(dataSource[sample]);
                    } else if (
                      dataSource[sample].host === EditedRuleKey.host &&
                      dataSource[sample].filter === EditedRuleKey.filter &&
                      dataSource[sample].target === EditedRuleKey.target &&
                      dataSource[sample].ipsource === EditedRuleKey.ipsource &&
                      dataSource[sample].notes === EditedRuleKey.notes &&
                      dataSource[sample].repourl === EditedRuleKey.repourl
                    ) {
                      dataSource[sample].host = values.host;
                      dataSource[sample].filter = values["filter"]
                        ? values["filter"]
                        : "/";
                      dataSource[sample].target = values.target
                        ? values.target
                        : "";
                      dataSource[sample].ipsource = values.ipsource
                        ? values.ipsource
                        : "*";
                      dataSource[sample].notes = values.notes
                        ? values.notes
                        : "";
                      dataSource[sample].urlcheck = values.urlcheck
                        ? values.urlcheck
                        : false;
                      dataSource[sample].repourl = values.repourl
                        ? values.repourl
                        : "";

                      //netData.push(dataSource[sample]);
                    }
                  }

                  // let netData=[];
                  // if (EditedRuleKey){
                  //     for (const sample in dataSource) {
                  //         const check = (
                  //             (dataSource[sample].host == EditedRuleKey.host) &&
                  //             (dataSource[sample].filter == EditedRuleKey.filter) &&
                  //             (dataSource[sample].target == EditedRuleKey.target)
                  //         );
                  //         if (!check){
                  //             netData.push(dataSource[sample]);
                  //         }
                  //     }
                  props.savePoc();

                  // this.setState({ boardAddModalShow: true }, function () {
                  //     console.log(this.state.boardAddModalShow);
                  // });
                  setEditedRuleKey({});
                  setRuleEditing(false);
                }
              }
            })}
          >
            <TextInput
              withAsterisk
              label="Host"
              placeholder="mail.domain.com"
              {...form.getInputProps("host")}
            />
            <TextInput
              withAsterisk
              label="Links to"
              placeholder="http://peer3:8080"
              {...form.getInputProps("target")}
            />
            <TextInput
              withAsterisk
              label="URL Path"
              placeholder="/"
              {...form.getInputProps("filter")}
            />
            <TextInput
              withAsterisk
              label="Allow from IP"
              placeholder="*"
              {...form.getInputProps("ipsource")}
            />

            <TextInput
              label="Repo URL"
              placeholder=""
              {...form.getInputProps("repourl")}
            />

            <Textarea
              label="Notes"
              placeholder=""
              {...form.getInputProps("notes")}
            />

            <Divider my="sm" variant="dotted" />

            <Checkbox
              label="Health checked"
              checked={URLCheck}
              onChange={(event) => {
                //setURLCheckVal(event.currentTarget.checked,props)
              }}
            />

            <Group position="right" mt="sm">
              <Button color="lime" type="submit">
                Save
              </Button>
            </Group>
          </form>
        </Box>
      </Modal>

      <Modal
        opened={QROpened}
        onClose={() => setQROpened(false)}
        title={QRCodeModalURL}
      >
        <div
          style={{
            height: "auto",
            margin: "0 auto",
            maxWidth: 640,
            width: "100%",
          }}
        >
          <QRCode
            size={640}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={QRCodeModalURL}
            title={QRCodeModalURL}
            level="Q"
            viewBox={`0 0 640 640`}
          />
        </div>
      </Modal>

      <Modal
        opened={RuleDeleting}
        onClose={() => setRuleDeleting(false)}
        title="Delete inbound rule"
      >
        <div
          style={{
            height: "auto",
            margin: "0 auto",
            maxWidth: 640,
            width: "100%",
          }}
        >
          <Box sx={{ maxWidth: 600 }} mx="auto">
            <div
              style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: 640,
                width: "100%",
              }}
            >
              <Text fz="md">{props.host}</Text>
              <Text fz="md">{props.filter}</Text>
              <Text fz="md">{props.target}</Text>
              {/* <Text fz="md" >{props.notes}</Text>
                      <Text fz="md" >{props.repourl}</Text> */}
              {/* <Textarea fz="md"  
                         label="Notes"
                      
                         {...props.notes}
                     />
                      <Checkbox label="Health check" checked={URLCheck} onChange={(event) => setURLCheckVal(event.currentTarget.checked,props)} />
 */}
            </div>
            <div
              style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: 640,
                width: "100%",
              }}
            >
              <Button
                color="red"
                onClick={async () => {
                  //const theSource =
                  let netData = [];

                  if (EditedRuleKey) {
                    for (const sample in dataSource) {
                      if (dataSource[sample].filter === "") {
                        dataSource[sample].filter = "/";
                      }
                      if (dataSource[sample].ipfilter === "") {
                        dataSource[sample].filter = "*";
                      }
                      if (dataSource[sample].notes === "") {
                        dataSource[sample].notes = "";
                      }
                      if (dataSource[sample].repourl === "") {
                        dataSource[sample].repourl = "";
                      }
                      if (dataSource[sample].urlcheck === null) {
                        dataSource[sample].urlcheck = false;
                      }

                      const check =
                        dataSource[sample].host === EditedRuleKey.host &&
                        dataSource[sample].filter === EditedRuleKey.filter &&
                        dataSource[sample].target === EditedRuleKey.target &&
                        dataSource[sample].ipfilter ===
                          EditedRuleKey.ipfilter &&
                        dataSource[sample].notes === EditedRuleKey.notes &&
                        dataSource[sample].urlcheck ===
                          EditedRuleKey.urlcheck &&
                        dataSource[sample].repourl === EditedRuleKey.repourl;
                      if (!check) {
                        netData.push(dataSource[sample]);
                      } else {
                        console.log(
                          "removing rule: " + JSON.stringify(dataSource[sample])
                        );
                      }
                    }

                    props.saveRulesAndPoc(netData);

                    // this.setState({ boardAddModalShow: true }, function () {
                    //     console.log(this.state.boardAddModalShow);
                    // });
                    setEditedRuleKey({});
                    setRuleDeleting(false);
                  }
                }}
              >
                Confirm
              </Button>
            </div>
          </Box>
        </div>
      </Modal>

      <div className="buttonBarForRules">
        <button className="" onClick={addToRuleBuffer}>
          <RiAddCircleFill
            style={{ color: "#7b7b7b", width: "30px", height: "30px" }}
          />
        </button>

        {/*<button className="" onClick={props.savePoc}>*/}
        {/*   <RiSaveLine*/}
        {/*     style={{ color: "#7b7b7b", width: "30px", height: "30px" }}*/}
        {/*   />*/}
        {/* </button>*/}

        <button className="" onClick={props.loadPoc}>
          <RiRefreshLine
            style={{ color: "#7b7b7b", width: "30px", height: "30px" }}
          />
        </button>
      </div>

      <ScrollArea w={"100%"} h={"100%"}>
        <Table striped highlightOnHover withBorder fontSize="sm">
          <thead>
            <tr>
              <th></th>
              <th></th>

              <th>
                <Text style={{marginLeft:"30px",marginRight:"30px"}}>Src </Text>
              </th>
              <th>
                <Text>https://</Text>
              </th>
              <th>
                <Text>Path</Text>
              </th>
              <th>
              <Text>Origin</Text>
              </th>

              <th>
                <h3> </h3>
              </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
    </div>
  );
}
