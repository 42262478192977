import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import axios from "axios";
import { AuthProvider } from "./hooks/AuthProvider";


const container = document.getElementById("root");
const root = createRoot(container);

//TODO BASEURL
//Set this to the API URL if running the API server on a different port
//Otherwise leave alone.
//for example in development mode.
//axios.defaults.baseURL = 'https://7-5001.04f25-498e3.wg0.io';
//axios.defaults.baseURL = 'http://localhost:5000';
axios.defaults.baseURL = "/api";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;

root.render(
  <React.StrictMode>
    <AuthProvider>
        <App />
    </AuthProvider>
  </React.StrictMode>
);
