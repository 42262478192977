import React, { useContext } from 'react';
// import Footer from '../sections/Footer';

// import { Redirect } from 'react-router-dom';
// import { UserContext } from '../hooks/UserContext';
// import LandingCenterBlock from '../components/LandingCenterBlock';


export default function DemoRequest() {

    return(
        <div className="page" style={{justifyContent:'center'}}>

           

            <div className="DemoCenterBlockFrame">


            <iframe className='calcom' title="Schedule Demo" src="https://cal.setip.io/nicolas/30min" frameBorder="0" allowFullScreen></iframe>

            </div>







<div/>


        </div>
        
    )
}