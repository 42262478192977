import React, { useStyles } from "react";
import LandingCenterBlock from "../components/LandingCenterBlock";
 
import Prompt from "./../components/Prompt";
import ReactPlayer from "react-player/youtube";
import { MantineProvider, createStyles, Text } from "@mantine/core";
import {
  AppShell,
  Anchor,
  Card,Image,
  Button,Badge,Group,
  Paper,
  Container,
  NavLink,
  Navbar,
  Title,
  Header,
  Aside,
  Footer,
} from "@mantine/core";

import "../App.css";
export default function OnBoarding() {
  const useStyles = createStyles((theme) => ({
    wrapper: {
      paddingTop: theme.spacing.xl * 2,
      paddingBottom: theme.spacing.xl * 2,
      minHeight: 650,
    },

    title: {
      marginBottom: theme.spacing.xl * 1.5,
    },

    item: {
      borderRadius: theme.radius.md,
      marginBottom: theme.spacing.lg,

      border: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[3]
      }`,
    },
  }));
  return (
    <Container size="sm" className={useStyles.wrapper}>
      {/* 
</MyHeader> */}



      <Paper shadow="xs" p="md">

      <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Card.Section>
        <Image
          src="https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80"
          height={160}
          alt="Norway"
        />
      </Card.Section>

      <Group position="apart" mt="md" mb="xs">
        <Text weight={500}>Your Point of Contact (PoC) is being created</Text>

        <Badge color="pink" variant="light">
          Read me
        </Badge>
      </Group>


<Title order={5}>Service activation</Title>

{/* <Text size="sm" color="dimmed">
    Individual IP addresses are no longer provided for free. 
    Please credit your account to enable your setip.io account or send an email to support@setip.io and explain your need for a free trial.{" "}
</Text>

<Text size="sm" color="dimmed">
    You can still setup your account and various URLs for free so it will be ready to start when your IP address is enabled.
     If you want to try it out for free please use https://urlyup.com that runs on top of setip.io. This will allow you to immediatley use a URL to expose your host ports.
</Text> */}

<Title order={5}>Overview</Title>

<Text size="sm" color="dimmed">
  Your Point of Contact (Poc) serves as a bastion between your publicly
  accessible URLs and the hosts running code to expose through URLs.{" "}
</Text>

<Title order={5}>Security</Title>


<Text size="sm" color="dimmed">
  Your PoC is running on a seperate host, isolated from other customers
  hosts, and protected by TLS certificates, automatically generated for
  your project only. You don't have to worry about TLS while
  enjoying TLS encryption to your development environment even it is not
  configured for TLS directly.
</Text>

<Text size="sm" color="dimmed">
  Your private certificates allowing for TLS encryption of your URLs are
  being automatically generated now.
</Text>

<Title order={5}>Dashboard</Title>

<Text size="sm" color="dimmed">
  Your PoC's management dashboard is available from the setip.io
  site after your login.
</Text>

<Text size="sm" color="dimmed">
  The URL menu lets you manage your URLs and point them to the proper machine private IP and port number.
  </Text>
  <Text size="sm" color="dimmed">
   Each machine can be assigned a private ip address that URLs can reach directly and securely across the Internet, so all you need to do is expose http port without TLS.
  
</Text>

<Title order={5}>Network and Peers</Title>

<Text size="sm" color="dimmed">
  From the Network menu you will find the configurations needed for each
  machine (peer) that you wish to associate any of your URL to.
  Private IP address are assigned to your laptop and servers from the
  Network menu by running a simple Linux script you can Copy/Paste, or, if you use Windows and Mac, load in the Wireguard App.
  If you use Windows or Mac, you will need to download the Wireguard App first from this page.
</Text>

<Title order={5}>URLs</Title>

<Text size="sm" color="dimmed">
  URLs can be connected directly to your code or third-pary applications
  running on your own machine or containers anywhere, this is done
  automatically when using pre-configured URLs or manually if you want
  to add new URLs.
</Text>

<Text size="sm" color="dimmed">
  When creating new URLs from the dashboard you can simply point them to
  http://peer[peer number]:[portnumber], where X is the peer number in
  the Network's list you wish to arbitraraly pick to connect a host to
  one of your URLs.
</Text>

<Title order={5}>Example</Title>

<Text size="sm" color="dimmed">
  Setting a target to: http://peer2:8080 will send a URL to port 8080 on
  your laptop if your current Wireguard is connected through the
  configuration file available in the Network menu that matches "peer2".
  This allows to not have to remember the IP address assigned internally
  to your laptop or servers.
</Text>
{/* </Paper>
<Paper shadow="xs" p="md"> */}




      <Button component="a" href="https://docs.setip.io/quickstart/quickstart/" target="_blank" variant="light" color="blue" fullWidth mt="md" radius="md">
      Read the Quick Start
      </Button>
    </Card>
   

</Paper>
    </Container>
  );
}
