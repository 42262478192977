import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../hooks/UserContext";
import LandingCenterBlock from "../components/LandingCenterBlock";
 
import Prompt from "./../components/Prompt";
import { Container, Title, Accordion, createStyles } from "@mantine/core";
import { Link } from "react-router-dom";
import InlineButton from "./../components/InlineButton";
import { TypographyStylesProvider } from '@mantine/core';


// import timeLineAnimated from "../assets/TimelineAnimated.gif";

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    minHeight: 650,
  },

  title: {
    marginBottom: theme.spacing.xl * 1.5,
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,

    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

export default function CrowdFundingPhase1() {
  const { classes } = useStyles();

  const { user } = useContext(UserContext);
  if (user) {
    <Navigate to="/home" />;
  }
return (
  <TypographyStylesProvider>
  <div
    dangerouslySetInnerHTML={{ __html: '<h1>Coming soon...</h1>' }}
  />
</TypographyStylesProvider>
)
//   return (
//     <Container size="sm" className={classes.wrapper}>
//       {/* <MyHeader/> */}
//       <div className="page" style={{ justifyContent: "center" }}>
//         {/* <div className="LandingCenterBlockFrame">

// <LandingCenterBlock message="Cloud based VPN"/>
// <LandingCenterBlock txtclass="landingCenterSmallBlock"  message="for"/> 
// <LandingCenterBlock txtclass="landingCenterSmallBlock"  message="Devs and DevOps"/> 
// <LandingCenterBlock txtclass="landingCenterSmallBlock"  message="from solo to teams."/> 
// <LandingCenterBlock txtclass="landingCenterSmallBlock"  message="Blazing fast, secure."/> 


// </div>  */}

//         {/*<img  className="timeLineAnimated" alt="timeLineAnimated" src={timeLineAnimated}/>*/}

//         <div className="LandingCenterBlockFrame">
//           {/*<LandingCenterBlock message=""/>*/}

//           <LandingCenterBlock message="Why crowdfunding?" />

//           <LandingCenterBlock message="" />

//           <LandingCenterBlock
//             txtclass="landingCenterSmallBlock"
//             message="Three companies with the comparable market capitalization (MC) today:"
//           />

//           <LandingCenterBlock
//             txtclass="landingCenterSmallBlock"
//             message="Apple: 2,000 times its 1980 IPO market cap in constant $"
//           />

//           <LandingCenterBlock
//             txtclass="landingCenterSmallBlock"
//             message="Microsoft: 950 times it's 1986 IPO MC in constant $"
//           />

//           <LandingCenterBlock
//             txtclass="landingCenterSmallBlock"
//             message="Meta (Facebook): 2.2 times its IPO market cap in constant $"
//           />

//           <LandingCenterBlock
//             txtclass="landingCenterSmallBlock"
//             message="Why the difference? Because retail investors came in early for Apple and Microsoft IPOs, but they were only invited late to Meta, as its IPO only took place after the VCs had skimmed most of the appreciation potential.. 
//                   "
//           />
//           <LandingCenterBlock
//             txtclass="landingCenterSmallBlock"
//             message="setip.io’s mission is to bring back the décentralisation of the Internet, as it was in its early days, and make it less reliant on oligopolistic ISPs and Cloud Providers. Our technology empowers independent developers with big operational savings."
//           />
//           <LandingCenterBlock
//             txtclass="landingCenterSmallBlock"
//             message="Households can receive new Airbnb-like revenue streams against renting out their uplink connection to the Internet. And it restores privacy. We think our PoCs could be deployed in hundreds of millions of different locations. 
//                   "
//           />

//           <LandingCenterBlock
//             txtclass="landingCenterSmallBlock"
//             message="At setip.io, we believe in democracy, popular capitalism, and user empowerment. Everybody should have equal access to prosperity, especially our clients, as they help us build our company."
//           />

//           <LandingCenterBlock message="Our offer to you" />

//           <LandingCenterBlock
//             txtclass="landingCenterSmallBlock"
//             message="We were hoping you could participate in our success even before the IPO.
//                   We are issuing 1 million Class A shares at a face value of $1 for 5% of our equity. This represents a market cap of $20mil.
                  
//                   These Class A shares have a pro-rated right to the company’s dividends.
                  
//                   The Class A Shares do not have voting rights. 
                  
//                   Class A shares initial holders become automatic members of setip.io Founders Club.
                  
//                   Each crowdfunder can buy 100 Class A shares for a total of $100. No more, no less.
                  
//                   This issue is limited to 1 million Class A shares and can be stopped at any time."
//           />

//           <LandingCenterBlock message="Our plans with the money" />

//           <LandingCenterBlock message="" />

//           <LandingCenterBlock
//             txtclass="landingCenterSmallBlock"
//             message="IP Protection costs. Core Team on payroll: developers, QA, Docs, SRE, Marketing, Sales, Support, Operations, GA, Cost of sales"
//           />

//           <LandingCenterBlock
//             txtclass="landingCenterSmallBlock"
//             message="Finance cost of raising next round (Hardware phase)."
//           />

//           <LandingCenterBlock message="Share the wealth" />

//           <LandingCenterBlock message="" />

//           <LandingCenterBlock
//             txtclass="landingCenterSmallBlock"
//             message="Our success is driven by the speed at which we build our network of PoCs hosted by households and Internet carrier partners. "
//           />

//           <LandingCenterBlock
//             txtclass="landingCenterSmallBlock"
//             message="Talk to your friends about us. For each successful referral (someone who buys our service) from you, you will receive an option to acquire 100 Class A shares at $1/share at the next financing round. The more referrals you generate, the more options you receive."
//           />

//           <LandingCenterBlock
//             txtclass="landingCenterSmallBlock"
//             message="This is your investment number. Give it to your friend to get your referral credit."
//           />
//           <LandingCenterBlock message="Legal framework" />

//           <LandingCenterBlock message="" />
//           <a href="https://www.sec.gov/divisions/corpfin/ecfrlinks">
//             Read Crowdfunding rules and regulations
//           </a>

//           {/*
//  <a href='/work'>
//      <Prompt prompt={"WORK"}/>
//  </a>              
                                    
//                 <a href='/invest'>
//                     <Prompt prompt={"INVEST"}/>
//                 </a>
                
//   */}
//         </div>

//         <div className="LandingCenterBlockFrame">
//           <LandingCenterBlock message="Reserve Shares" />
//           <LandingCenterBlock
//             txtclass="landingCenterSmallBlock"
//             message="Reserve shares during our upcoming pre-offering phase. Come back soon."
//           />

//           {/*
//     <a href='/buyshares'>
//     <Prompt prompt={"RESERVE"}/>
//     </a>
//     */}
//         </div>

       
//       </div>
//     </Container>
//   );
}
