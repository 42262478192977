import React, {
  useCallback,
  useContext,
  // FormInput,
  // Link,
  // Prompt,
  useEffect,
  useMemo,
  useState,
  CSSProperties,
} from "react";
import useAuth from "../hooks/useAuth";
import { Menu } from '@mantine/core';
import {
  IconSettings,
  IconSearch,
  IconPhoto,
  IconMessageCircle,
  IconTrash,
  IconArrowsLeftRight,
} from '@tabler/icons-react';
import { CopyBlock } from 'react-code-blocks';

import { Excalidraw } from "@excalidraw/excalidraw";

// import {DNSEditor} from "../components/DNSEditor"
import ReactJson from 'react-json-view'
import Excali from "../components/Excali";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { 
  leftPaneLinkIndexState, 
  showLeftSideState,
  showManageSubState,
  pocketBaseState ,
  randomTokenState,
  authTupleState,
} from "../recoil_state";
import { CopyButton } from '@mantine/core';
import { notifications } from "@mantine/notifications";
import { Divider, Stack, Flex, Text, Accordion,Card, Paper } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { RingLoader } from "react-spinners/ClipLoader";
import store from "../store";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { PasswordInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { hookstate, useHookstate } from "@hookstate/core";
import PocWebWorker from "./poc.worker";

import {
  IconAt,
  // IconWorld,
  // IconMap2,
  IconNetwork,
  IconAppWindow,
} from "@tabler/icons";
import {
  TextInput,
  // Checkbox,
  // Progress,
  Button,
  Group,
  Code,
  Box,
  Select,
  // SelectProps,
  createStyles,
  // ScrollArea,
} from "@mantine/core";
import { useInterval } from "@mantine/hooks";

import CTA from "./../components/CTA";
import { Resizable } from "re-resizable";
import Error from "./../components/Error";
import FormInput from "./../components/FormInput";
import ConfirmPasswordInput from "./../components/ConfirmPasswordInput";

import { Link } from "react-router-dom";
import RiseLoader from "react-spinners/RiseLoader";

//import CTA from "./../components/CTA";
//import {useFormLocal} from "./../hooks/useForm";
import { Container, Modal, rem, Space } from "@mantine/core";
//import Register from './Register';
import { useNavigate } from "react-router-dom";
// import useAuth from "./../hooks/useAuth";
//import ConfirmPasswordInput from "./../components/ConfirmPasswordInput";

// import {
//   ErrorMatcher,
//   defaultErrorMatchers,
//   ErrorMatcherPatterns,
//   ErrorMatcherPattern,
// } from 'react-ansi';
// import {default as LogContent} from 'react-ansi';
// import { ScrollArea,Accordion } from '@mantine/core';



//import {webworker} from './poc.worker';

//import {useFormLocal} from "./../hooks/useForm";

import AuthContext from "./../hooks/AuthProvider";
import { LazyLog } from "react-lazylog";

import CopyAction from "../components/CopyAction";
import SaveAction from "../components/SaveAction";
import axios from "axios";
import PocTable from "../components/PocTable";
import EmailTable from "../components/EmailTable";
import PopUp from "./../components/PopUp";

import ConfTable from "../components/WGConfTable";
import GeoTable from "../components/GeoTable";
// import CTA from './../components/CTA';

import AppRunners from "./../components/AppRunners";
import AppStore from './../components/AppStore'; // Adjust the path as necessary

// import Tabs from "../components/Tabs";
// import offCircle from "../assets/OffCircle.png";
// import onCircle from "../assets/OnCircle.png";
import copyPaste from "../assets/copypaste.png";
import downLoad from "../assets/download.png";

import "../App.css";
// import LandingCenterBlock from "../components/LandingCenterBlock";
// import { MdClose } from "react-icons/md";
// import { FiMenu } from "react-icons/fi";
import {
  RiAddBoxFill,
  RiAddCircleFill,
  RiAddCircleLine,
  RiPlayListAddLine,
  RiRefreshLine,
  RiFullscreenLine,
  RiFullscreenExitLine,
  RiSaveLine,
} from "react-icons/ri";

var FileSaver = require("file-saver");

const useStylesForCreateButton = createStyles((theme) => ({
  button: {
    position: "relative",
    transition: "background-color 150ms ease",
  },

  progress: {
    ...theme.fn.cover(-1),
    height: "auto",
    backgroundColor: "transparent",
    zIndex: 0,
  },

  label: {
    position: "relative",
    zIndex: 1,
  },
}));
const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    minHeight: 650
  },

  title: {
    marginBottom: theme.spacing.xl * 1.5,
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,

    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));


export default function Home() {

  const [randomToken,setRandomToken] = useRecoilState(randomTokenState);

  // const [showSidebar, setShowSidebar] = useState(false);
  
  const [pocketBase,setPocketBase] = useRecoilState(pocketBaseState);

  const [showLeftSide, setShowLeftSide] = useRecoilState(showLeftSideState);
  const [authTuple, setAuthTuple] = useRecoilState(authTupleState);

  const [showManageSub, setShowManageSub] = useRecoilState(showManageSubState);
  const [isCopiedCodeDeploy , setIsCopiedCodeDeploy] = useState(false);
  const [Oldsession , setOldsession] = useState(true);
  // const { user, pocketBase.authStore?.isValid, isLoading , error } = useAuth0();
  const [leftPaneLinkIndex] = useRecoilState(
    leftPaneLinkIndexState
  );

  useEffect(() => {
    setShowManageSub(true);
    if (!showLeftSide) {
      setShowLeftSide(true);
    }

    return () => {
      //console.log("cleaned up");
     // setShowLeftSide(false);
      //setShowManageSub(false);

    };
  },[setShowLeftSide,setShowManageSub,showLeftSide]);



  //const [webworker] = useState(new window.Worker("./poc.worker.js"));
  //const webworker = React.useMemo(() => new Worker('./poc.worker.js'), []);

  const state = useHookstate(store);
  const [startDateTime, setStartDateTime] = useState(null);

  const [endDateTime, setEndDateTime] = useState(null);

  const { logWindowDim, setLogWindowDim } = useState({
    heigth: "1000px",
    width: "200px",
  });

  // const onResize = (event, {element, size, handle}) => {
  //   setLogWindowDim({width: size.width, heigth: size.heigth});
  // };

  const logScreen = useFullScreenHandle();



  const reportChange = useCallback(
    (state, handle) => {
      if (handle === logScreen) {
      }
    },
    [logScreen]
  );

  // On top layout
  const webworker = useMemo(() => new PocWebWorker(), []);

 const { registerUser, regError } = useAuth();

  const { classes } = useStyles();
  let navigate = useNavigate();
  const { auth } = useContext(AuthContext);

  // const { values, handleChange } = useFormLocal({
  //   initialValues: {
  //     vendor_id: "wg00001",
  //     internal_id: "",
  //     dns: "",
  //     active: false,
  //     email: "",
  //     username: "",
  //     // password: "",
  //     // passwordConfirm: "",
  //     region: "euro0002",
  //     world_region: "",
  //     region_pref_ipio: "",
  //     op_mode: "A02",
  //     invite: "",
  //     mobilenb: ""

  //   },
  // });
  //
  //console.log(JSON.stringify(session));

const [appError, setAppError] = useState(null);

  const [selected, setSelected] = useState({});

  const [showPopUp, setShowPopUp] = useState(false);
  const [geoOpOn, setGeoOpOn] = useState(false);
  const [LastRefreshTimeEmail, setLastRefreshTimeEmail] =
    React.useState("Never");
  const [LastRefreshTimeGeo, setLastRefreshTimeGeo] = React.useState("Never");

  const [WGConfState, setWGConfState] = useState({});
  const [PocRulesState, setPocRulesState] = useState({});
  const [PocDNSState, setPocDNSState] = useState({});

  const [POCRegistrationError, setPOCRegistrationError] = useState("");

  const [logSearchKey, setLogSearchKey] = useState("");

  const [PocInfosState, setPocInfoState] = useState({ ready: false, apps: [] });
  // const [logURL, setLogURL] = useState("/api/log?init=1");
  
  const [logURL, setLogURL] = useState("https://logs.setip.io/_logs/PROXY/0/0/4/4/100?k=&s=y");


  const [PocEmailsState, setPocEmailsState] = useState({
    ready: false,
    mailbox: [],
  });
  const [PocGeosState, setPocGeosState] = useState({ ready: false, geos: [] });

  const [AppScriptTemplState, setAppScriptTemplState] = useState({
    ready: false,
    templates: [],
  });
  const [isPocToBeCreated, setIsPocToBeCreated] = useState(false);
  const [isPocToBeUpdated, setIsPocToBeUpdated] = useState(false);

  const [SMTPWebHook, setSMTPWebHook] = useState(null);

  const [currentMsg, setCurrentMsg] = useState(null);
  const refreshInUrl = 10000;
  const [isPocLoaded, setIsPocLoaded] = useState(false);
  const [displayWaitForCreate, setDisplayWaitForCreate] = useState(false);
  const [displayOpenedCreate, setDisplayOpenedCreate] = useState(false);
  const [isPocLoading, setIsPocLoading] = useState(false);
  const [isEmailLoaded, setIsEmailLoaded] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(refreshInUrl || 0);
  //const [selectedRegion, setSelectedRegion] = useState(false);
  const [POCLoadingStatus, setPOCLoadingStatus] = useState("null");
  const [logLoadRefreshInterval, setLogLoadRefreshInterval] = useState(30000);
  const [userEmail, setUserEmail] = useState("");
  const [projectName, setProjectName] = useState("");
  const [selectedWorldRegion, setSelectedWorldRegion] = useState("");
  const [selectedVendor, setSelectedVendor] = useState("");
  const [filteredVendors, setFilteredVendors] = useState([]);
  const [opMode, setOpMode] = useState("A02");
  const [maxLogLines, setMaxLogLines] = useState(200);

  //Sets the POC as transparent or Proxy.
  const op_modes = [
    {
      name: "transparent",
      description: "Transparent mode with auto DNS",
      label: "Transparent mode with auto DNS",
      code: "A01",
      key: "A01",
      value: "A01",
    },
    {
      name: "proxy",
      description: "Proxy mode with auto SSL and auto DNS",
      label: "Proxy mode with auto SSL and auto DNS",
      code: "A02",
      key: "A02",
      value: "A02",
      ////isdefault:: true,
    },
  ];


  const codeSampleCodeDeployWithCode = `
  #!/usr/bin/env sh
  
  # copy and paste this into a bash script or run it from the command line.
  cd build
  # Zip files in the build directory, IMPORTANT: using -D option to restore files in the archive without a containing folder name.
  ZIP_PATH=$PWD/zipped.zip
  zip -r -D $ZIP_PATH *
  # Curl command to upload the zip file, change b1 to the bucket number you associate with the URL (1 to 5)
  curl --location 'https://b1.${PocInfosState.dns_name_cust}/upload' --header 'Authorization: Bearer ${PocInfosState.static_deploy_init_root_jwt}' --form files=@"$ZIP_PATH"
  `;

  const codeSampleCodeDeploy = `
#!/usr/bin/env sh

# copy and paste this into a bash script or run it from the command line.
cd build
# Zip files in the build directory, IMPORTANT: using -D option to restore files in the archive without a containing folder name.
ZIP_PATH=$PWD/zipped.zip
zip -r -D $ZIP_PATH *
# Curl command to upload the zip file, change b1 to the bucket number you associate with the URL (1 to 5)
curl --location 'https://b[bucket_number_from_6_to_10].[your_setip_io_domain]/upload' --header 'Authorization: Bearer [static_deploy_login_token]' --form files=@"$ZIP_PATH"
`;

  const regions_002 = [
    {
      vendor_id: "SELECTOR",
      region_name: "Select a region",
      region_display_name: "Select a region",
      region_display_code: "",
      region_ipio_code: "",
      label: "",
      value: "",
    },
    {
      vendor_id: "SE",
      region_name: "Space",
      region_display_name: "Space - Starlink",
      label: "Space - Starlink",
      region_display_code: "st00001",
      region_ipio_code: "st00001",
      si_label: "SST 1",
      value: "st00001",
    },
    // {
    //   vendor_id: "SE",
    //   region_name: "Africa North",
    //   region_display_name: "Africa North",
    //   region_display_code: "an0001",
    //   region_ipio_code: "an0001",
    //   label: "AFN 1",
    //   value: "an0001",
    // },
    //  {
    //    vendor_id: "SE",
    //    region_name: "Africa South",
    //    region_display_name: "Africa South",
    //    region_display_code: "afs0001",
    //    region_ipio_code: "afs0001",
    //    si_label: "AFS 1",
    //    label: "Space - Starlink",

    //    value: "afs0001",
    //  },
    // {
    //   vendor_id: "SE",
    //   region_name: "Africa West",
    //   region_display_name: "Africa West",
    //   region_display_code: "afw0001",
    //   region_ipio_code: "afw0001",
    //   label: "AFW 1",
    //   value: "afw0001",
    // },
    // {
    //   vendor_id: "SE",
    //   region_name: "Africa East",
    //   region_display_name: "Africa East",
    //   region_display_code: "afe0001",
    //   region_ipio_code: "afe0001",
    //   label: "AFE 4",
    //   value: "afe0001",
    // },
    {
      vendor_id: "SE",
      region_name: "Americas North",
      region_display_name: "Americas North",
      label: "Americas North",

      region_display_code: "amn0001",
      region_ipio_code: "amn0001",
      si_label: "AMN 1",
      value: "amn0001",
    },
    {
      vendor_id: "SE",
      region_name: "Americas South",
      region_display_name: "Americas South",
      label: "Americas South",

      region_display_code: "ams0001",
      region_ipio_code: "ams0001",
      si_label: "AMS 1",
      value: "ams0001",
    },
    {
      vendor_id: "SE",
      region_name: "Americas West",
      region_display_name: "Americas West",
      label: "Americas West",

      region_display_code: "amw0001",
      region_ipio_code: "amw0001",
      si_label: "AMW 1",
      value: "amw0001",
    },
    {
      vendor_id: "SE",
      region_name: "Americas East",
      region_display_name: "Americas East",
      label: "Americas East",

      region_display_code: "ame0001",
      region_ipio_code: "ame0001",
      si_label: "AME 1",
      value: "ame0001",
    },
    {
      vendor_id: "SE",
      region_name: "Asia North",
      region_display_name: "Asia North",
      label: "Asia North",

      region_display_code: "asn0001",
      region_ipio_code: "asn0001",
      si_label: "ASN 1",
      value: "asn0001",
    },
    {
      vendor_id: "SE",
      region_name: "Asia North-East",
      region_display_name: "Asia North-East",
      label: "Asia North-East",

      region_display_code: "asne0001",
      region_ipio_code: "asne0001",
      si_label: "ASNE 1",
      value: "asne0001",
    },
    {
      vendor_id: "SE",
      region_name: "Asia South",
      region_display_name: "Asia South",
      label: "Asia South",

      region_display_code: "ass0001",
      region_ipio_code: "ass0001",
      si_label: "ASS 1",
      value: "ass0001",
    },
    {
      vendor_id: "SE",
      region_name: "Asia South East",
      region_display_name: "Asia South-East",
      label: "Asia South-East",

      region_display_code: "asse0001",
      region_ipio_code: "asse0001",
      si_label: "ASSE 1",
      value: "asse0001",
    },
    {
      vendor_id: "SE",
      region_name: "Asia West",
      region_display_name: "Asia West",
      label: "Asia West",

      region_display_code: "asw0001",
      region_ipio_code: "asw0001",
      si_label: "ASW 1",
      value: "asw0001",
    },
    {
      vendor_id: "SE",
      region_name: "Asia East",
      region_display_name: "Asia East",
      label: "Asia East",

      region_display_code: "ase0001",
      region_ipio_code: "ase0001",
      si_label: "ASE 1",
      value: "ase0001",
    },
    {
      vendor_id: "SE",
      region_name: "Europe North",
      region_display_name: "Europe North",
      label: "Europe North",

      region_display_code: "eun0001",
      region_ipio_code: "eun0001", //Exception to Hetzner which has a vendor already set in Northern Europe for Starlink
      si_label: "EUN 1",
      value: "eun0001",
    },
    {
      vendor_id: "SE",
      region_name: "Europe South",
      region_display_name: "Europe South",
      label: "Europe South",

      region_display_code: "eus0001",
      region_ipio_code: "eus0001",
      si_label: "EUS 1",
      value: "eus0001",
    },
    {
      vendor_id: "SE",
      region_name: "Europe Central",
      region_display_name: "Europe Central",

      label: "Europe Central",

      region_display_code: "euc0001",
      region_ipio_code: "euc0001",
      si_label: "EUC 1",
      value: "euc0001",
    },
    {
      vendor_id: "SE",
      region_name: "Europe West",
      region_display_name: "Europe West",

      label: "Europe West",

      region_display_code: "euw0001",
      region_ipio_code: "euw0001",
      si_label: "EUW 1",
      value: "euw0001",
    },
    {
      vendor_id: "SE",
      region_name: "Europe East",
      region_display_name: "Europe East",

      label: "Europe East",

      region_display_code: "eue0001",
      region_ipio_code: "eue0001",
      si_label: "EUE 1",
      value: "eue0001",
    },
  ];

  //    const logRefreshIntervalRunner = useInterval(() => {
  //     if (PocInfosState && PocInfosState.ready && PocInfosState["dns_name_cust"] && PocInfosState["log_key"]) {
  //       console.log("Refreshing logs: " + (PocInfosState && PocInfosState["dns_name_cust"] && PocInfosState["log_key"]) )

  //       setLogURL(onGetLogURL());
  //     }

  // }
  // , logLoadRefreshInterval);

  const handleOnWorldRegionChange = (values) => {
    //(values);
    setSelectedWorldRegion(values);
    values.region = values;
    values.world_region = values;
    // console.log("Values Region and World Region set to: " + values);
    // console.log("Values Vendor set to: " + values["vendor_id"]);
    //Now we filter our vendor options source
  };

  const handleOnVendorChange = (values) => {
    setSelectedVendor(values);
    values["vendor_id"] = values;
    //console.log("Values Vendor set to: " + values["vendor_id"]);
  };

  const handleOnOpModeChange = (values) => {
    setOpMode(values);
    values["op_mode"] = values;
    //console.log("op_mode set to: " + values["op_mode"]);
  };

  const handleOnRegionChange = (values) => {
    setSelectedWorldRegion(values);
    values.region = values;
    //console.log("Values Region set to: " + values.region);
  };
  const form = useForm({
    initialValues: {
      vendor_id: "wg00001",
      internal_id: "",
      dns: "",
      active: false,
      email: "",
      username: "",
      // password: "",
      // passwordConfirm: "",
      region: "euro0002",
      world_region: "",
      region_pref_ipio: "",
      op_mode: "A02",
      invite: "",
      mobilenb: "",
      project: "",
      termsOfService: false,
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      project: (value) =>
        /^\S+@\S+$/.test(value) ? null : "Invalid project name",
    },
  });

  const handleRegister = async (values, callback) => {
    //values.preventDefault();
    //values.region = selectedRegion;
    //console.log(values);
    try {
      const result = await registerUser(values);
      callback(null, result);
    } catch (e) {
      callback(e.message, null);
    }
  };




  function OptionsOpModes() {
    return op_modes.map((option) => (
      <option
        //selected={option.isdefault}
        key={option.code}
        value={option.code}
      >
        {option.description}
      </option>
    ));
  }
  // generage select dropdown option list dynamically
  function OptionsRegions() {
    return regions_002.flatMap((option) => {
      //console.log("wewe" + values["vendor_id"]);
      if (
        "SELECTOR" === option.vendor_id ||
        "WG" === option.vendor_id ||
        "SE" === option.vendor_id
      ) {
        return (
          <option
            //selected={option.isdefault}
            key={option.region_ipio_code}
            value={option.region_ipio_code}
          >
            {option.region_display_name}
          </option>
        );
      } else {
        return null;
      }
    });
  }


  async function togglePopup() {
    setShowPopUp(!showPopUp);
  }


  async function setPocVal(da_key, da_value) {
    return axios
      .post("/poc/setter", {
        key: da_key,
        value: da_value,
      },
      {
        headers: {
            authentication: "Bearer " + 
            (pocketBase.authStore.model?pocketBase.authStore.model.id:null)
            + ":" + 
            randomToken
        }
    })
      .then(async () => {
        //await setUserContext();
        //setGeoOpOn(!geoOpOn);
        console.log("Setting " + da_key + "  to: " + da_value);
      })
      .catch((err) => {
        console.log("Error setting " + da_key + " to: " + da_value);
        setAppError(err.response && err.response.data ? err.response.data : err);
      });
  }

  async function postSMTPWebHook() {
    return axios
      .post("/poc/setter", {
        key: "smtp_webhook",
        value: SMTPWebHook,
      },
      {
        headers: {
            authentication: "Bearer " + 
            (pocketBase.authStore.model?pocketBase.authStore.model.id:null)
            + ":" + 
            randomToken
        }
    })
      .then(async () => {
        PocInfosState["smtp_webhook"] = SMTPWebHook;
        console.log("postSMTPWebHook: " + SMTPWebHook);
      })
      .catch((err) => {
        console.log("Error: " + err);
        setAppError(err.response && err.response.data ? err.response.data : err);
      });
  }

  async function toggleTrip() {
    await axios
      .get("/poc?key=geoOpOn")
      .then((res) => {
        setGeoOpOn(!res.geoOpOn);

        return axios
          .post("/poc/setter", {
            key: "geoOpOn",
            value: !res.data.geoOpOn,
          })
          .then(async () => {
            console.log("Geo Operation set to: " + !res.data.geoOpOn);
            return axios
              .post("/geo", {
                dns: "homelab.wg0.io",
                meta_type: res.data.geoOpOn ? "start" : "stop",
                longitude: null,
                reverse: "As Previous",
                payload: {
                  version: "5",
                  operating: !res.data.geoOpOn,
                },
                meta: "geo api test",
                latitude: null,
                profile: "auth",
              })
              .then(async () => {
                //await setUserContext();
                //setPocVal("geoOpOn",geoOpOn);
                //setGeoOpOn(!geoOpOn);
                console.log("Geo Operation set to: " + geoOpOn);
              })
              .catch((err) => {
                console.log("Error: " + err);
                setAppError(
                  err.response && err.response.data ? err.response.data : err
                );
              });
          })
          .catch((err) => {
            console.log("Error: " + err);
            setAppError(
              err.response && err.response.data ? err.response.data : err
            );
          });
      })
      .catch((err) => {
        console.log("error: " + err.message);
      });
  }
  async function dlWGScriptFile(value) {
    var blob = new Blob([value], { type: "text/plain;charset=utf-8" });

    FileSaver.saveAs(blob, "io.setip.peer.sh");
  }

  async function dlWGConfigFile(value) {
    var blob = new Blob([value], { type: "text/plain;charset=utf-8" });

    FileSaver.saveAs(blob, "io.setip.peer.conf");
  }

  async function getDOHMobileConfig() {
    FileSaver.saveAs(
      "https://" + PocInfosState["dns_name_cust"] + "/dohmobileconfig",
      PocInfosState["dns_name_cust"] + "/dohmobileconfig"
    );
  }

  async function saveRulesAndPOC(rules) {
    setPocRulesState(rules);
    // let newPOCWithRules = PocRulesState;
    // newPOCWithRules = rules;
    return axios
      .post("/poc/rules", {
        rules,
      },
      {
         headers: {
             authentication: "Bearer " + 
             (pocketBase.authStore.model?pocketBase.authStore.model.id:null)
             + ":" + 
             randomToken
         }
     })
      .then(async (res) => {
        setPocRulesState(res.data);
      })
      .catch((err) => {
        setAppError(err.response && err.response.data ? err.response.data : err);
      })
      .finally(() => {
        //setIsPocToBeUpdated(false);
      });
  }


  async function saveDNSAndPOC(zone) {
    setPocDNSState(zone);
    // let newPOCWithRules = PocRulesState;
    // newPOCWithRules = rules;
    return axios
      .post("/poc/dns", {
        zone,
      },
      {
        headers: {
            authentication: "Bearer " + 
            (pocketBase.authStore.model?pocketBase.authStore.model.id:null)
            + ":" + 
            randomToken
        }
    })
      .then(async (res) => {
        setPocDNSState(res.data);
      })
      .catch((err) => {
        setAppError(err.response && err.response.data ? err.response.data : err);
      })
      .finally(() => {
        //setIsPocToBeUpdated(false);
      });
  }

  async function savePoc() {
    //setIsPocToBeUpdated(true);
    return axios
      .post("/poc/rules", {
        PocRulesState,
      },
      {
        headers: {
            authentication: "Bearer " + 
            (pocketBase.authStore.model?pocketBase.authStore.model.id:null)
            + ":" + 
            randomToken
        }
    })
      .then(async (res) => {
        setPocRulesState(res.data);

        console.log("poc saved.");
      })
      .catch((err) => {
        setAppError(err.response && err.response.data ? err.response.data : err);
      })
      .finally(() => {
        //setIsPocToBeUpdated(false);
      });
  }

  async function loadAppScriptTemplates() {
    await axios.get("/poc/apps/scrtmpl").then((res) => {
      setAppScriptTemplState(res.data);
    });
  }

  const onGetLogURL = useCallback(
    (event) => {
      if (PocInfosState && PocInfosState.ready) {

        //https://logs.setip.io/_logs/RULE%20TARGET/0/0/4/4

        const log_since = startDateTime ? Math.floor(new Date(startDateTime)): 0;
        const log_to = endDateTime ?  Math.floor(new Date(endDateTime)) : 0;

        return (
          `https://logs.setip.io/_logs/${(logSearchKey && logSearchKey!==""  && logSearchKey!==" ") ? logSearchKey : "___" }/${log_since}/${log_to}/4/4/${maxLogLines}?f=line&k=${(PocInfosState.log_key
          ? encodeURI(PocInfosState.log_key)
          : "nokey") }` 
          //PocInfosState.dns_name_cust +
          // "&k=" +
          // (PocInfosState.log_key
          //   ? encodeURI
          //(PocInfosState.log_key)
          //   : "nokey") 
            // +
          // "&search=" +
          // logSearchKey +
          // "&max=" +
          // maxLogLines +
          // (startDateTime
          //   ? "&fd=" + new Date(startDateTime).toISOString()
          //   : "") +
          // (endDateTime ? "&td=" + new Date(endDateTime).toISOString() : "") +
          // "&ts=" +
          // Number(new Date())
        );

        // return (
        //   "/api/log?pwd=" +
        //   PocInfosState.dns_name_cust +
        //   "&k=" +
        //   (PocInfosState.log_key
        //     ? encodeURI(PocInfosState["poc-id"] + PocInfosState.log_key)
        //     : "nokey") +
        //   "&search=" +
        //   logSearchKey +
        //   "&max=" +
        //   maxLogLines +
        //   (startDateTime
        //     ? "&fd=" + new Date(startDateTime).toISOString()
        //     : "") +
        //   (endDateTime ? "&td=" + new Date(endDateTime).toISOString() : "") +
        //   "&ts=" +
        //   Number(new Date())
        // );


      } else {
        return "not ready, please wait...";
      }
    },
    [PocInfosState, logSearchKey, maxLogLines, endDateTime, startDateTime]
  );

  // async function loadPoc() {

  //   //console.log("loadpoc")
  //   await axios.get("/poc/").then((res) => {

  //     setPocRulesState(res.data.rules);

  //     setPocInfoState({
  //       "public_ip": res.poc_ext_ip,
  //       "idmgt_id":res.idmgt_id ? res.idmgt_id : "",
  //       "log_key": res.log_key ? res.log_key : "",
  //       "geo_key": res.geo_key ? res.geo_key : "",
  //       "contact_email": res.contact_email ? res.contact_email : "",
  //       "poc-subnet": res["poc-subnet"],
  //       "dns_name_cust": res["dns_name_cust"],
  //       "ready": res["ready"],
  //       "poc-id": res["poc-id"],
  //       "dns_name": res["dns_name"],
  //       "ext_port_nb": res["ext_port_nb"],
  //       "next_action": res["next_action"],
  //       "nw-id": res["nw-id"],
  //       "poc-subnet-ip": res["poc-subnet-ip"],
  //       "created_date": res["created_date"],
  //       "updated_date": res["updated_date"],
  //       "timestamp_rules_last_updated":
  //         res["timestamp_rules_last_updated"],
  //       "dns_name_cust_mx1": res["dns_name_cust_mx1"],
  //       "dns_name_cust_mx2": res["dns_name_cust_mx2"],
  //       "dns_name_cust_ns1": res["dns_name_cust_ns1"],
  //       "dns_name_cust_ns2": res["dns_name_cust_ns2"],
  //       "dns_name_cust_dkim": res["dns_name_cust_ns2"],
  //       "dns_name_cust_dkim_domain": res["dns_name_cust_dkim_domain"],
  //       "dns_name_cust_spf": res["dns_name_cust_spf"],
  //       "smtp_webhook": res["smtp_webhook"],
  //       "smtp_apple_icloud": res["smtp_apple_icloud"]
  //         ? res["smtp_apple_icloud"]
  //         : false,
  //       "smtp_to_forward_to": res["smtp_to_forward_to"]
  //         ? res["smtp_to_forward_to"]
  //         : "",
  //       "smtp_to_forward_map": res["smtp_to_forward_map"]
  //         ? res["smtp_to_forward_map"]
  //         : [],
  //       "ssl_certs": res["ssl_certs"],
  //       "ssl_key": res["ssl_key"],
  //       "github_repository_url": res["github_repository_url"],
  //       "github_secret": res["github_secret"],
  //       "image": res["image"],
  //       "apps": res["apps"] ? res["apps"] : [],
  //       "target_transparent_ison": res["target_transparent_ison"]
  //         ? res["target_transparent_ison"]
  //         : false,
  //       "target_transparent": res["target_transparent"]
  //         ? res["target_transparent"]
  //         : "",
  //       "wg": res["wg"] ? res["wg"] : "",
  //       "static_deploy_init_root_jwt": res["static_deploy_init_root_jwt"] ? res["static_deploy_init_root_jwt"] : "",
  //       "static_deploy_init_jwt_key": res["static_deploy_init_jwt_key"] ? res["static_deploy_init_jwt_key"] : "",
  //     }
  //     // ,
  //     // //After this state has been set this callback is called.
  //     // not supported for some reason (When called from a use effect called function one cant set a state
  //     // and ask for this callback when the state has been updated, sucks so we set pocloaded later .)
  //     // () => {
  //     //   //setIsPocLoaded(true);
  //     //   console.log("After burner");
  //     // }

  //   );

  // setWGConfState(res["wg"] ? res["wg"] : {});
  // setIsPocLoaded(true);
  // loadAppScriptTemplates();

  //     //console.log(res);
  //   }).catch((err) => {
  //     if (err && err.response && err.response.data) {
  //       setAppError(err.response.data);
  //     } else if (err) {
  //       setAppError(err);
  //     } else {
  //       setAppError("Unknow error 3.");
  //     }
  //   });
  // }

  function loadGeos() {
    console.log("Loading geos");
    var host = window.location.origin;
    axios
      .get("/api/geo", {
        baseURL: host,
        method: "GET",
        //"url":  "/api/geo",
        params: {
          pwd: "homelab.wg0.io", //PocInfoState["poc-id"] ,
          k: "88a76-6918688a76-6918688a76-69186fdvfd3A2LhtwXSakaJzyhh8C",
        }, //PocInfoState["geo_key"]}
      })
      .then((res) => {
        //console.log(res);
        if (res) {
          setPocGeosState({ ready: true, geo: res });
          const nowDate = new Date();
          const lastRefreshTime =
            nowDate.toLocaleDateString() +
            " at " +
            nowDate.toLocaleTimeString();
          console.log("Updating geos: " + lastRefreshTime);
          setLastRefreshTimeGeo(lastRefreshTime);
          return;
        } else {
          //setPocEmailsState({"ready":false, "mailbox":[]});
          setLastRefreshTimeGeo("Error getting geos.");
          console.log("Error fetching geo:  no response");
          return;
        }

        //console.log(PocEmailsState.mailbox);
      })
      .catch((err) => {
        console.log(err);
        //if (err.response && err.response.data) {
        setPocGeosState({ ready: false, geos: [] });
        setAppError(err);
        return err;
        // setAppError(err.response.data);
        //}
      });
  }

  //Until we don't get some valid data for poc (PocInfosState.ready) we keep showing the splash screen
  const fetchMetrics = () => {
    // retrieve and then setData()
  };

  const doLoadPocInfo = useCallback(() => {

    async function getPOCInfoFromWorker() {

      //console.log(JSON.stringify(session));

      if (pocketBase.authStore?.isValid) {

        webworker.postMessage({ payload: { poc: { 
          "user-id": pocketBase.authStore?.model.id,
          "token-val":randomToken
      } } });

      setAuthTuple({ 
        token: pocketBase.authStore?.model.id, 
        userid: randomToken });


      }
      else
      {
        navigate("/");
      }
      return () => {
        if (webworker) webworker.terminate();
      };
    }
    getPOCInfoFromWorker();
  }, [webworker, pocketBase,navigate,randomToken,setAuthTuple]);

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }


  const onLoadEmail = useCallback(async () =>  {


    function loadEmails() {
      const _isEmailLoading = isEmailLoading;
      if (!_isEmailLoading) {
        setIsEmailLoading(true);
        // if (PocInfosState.ready) {
          //authTupleState
        console.log("Loading emails");
        const auth_str=`Bearer ${pocketBase.authStore.model.id}:${randomToken}`
        axios.get("/email?max=200",
          {
            headers: {
                'Authentication': auth_str
            }}).then(  (res) => {
            if (res) {
              setPocEmailsState({ ready: true, mailbox: res });
              const nowDate = new Date();
              const lastRefreshTime =
                nowDate.toLocaleDateString() +
                " at " +
                nowDate.toLocaleTimeString();
              console.log("updated emails: " + lastRefreshTime);
              setLastRefreshTimeEmail(lastRefreshTime);
              setIsEmailLoading(false);
              setIsEmailLoaded(true);

              return;
            } else {
              setPocEmailsState({"ready":false, "mailbox":[]});
              setLastRefreshTimeEmail("Error getting emails.");
              console.log("Error fetching mail:  no response");
              setIsEmailLoading(false);
              setIsEmailLoaded(false);
              return;
            }

            //console.log(PocEmailsState.mailbox);
          })
          .catch((err) => {
            console.log(err);
            //if (err.response && err.response.data) {
            setPocEmailsState({ ready: false, mailbox: [] });
            setIsEmailLoading(false);
            setIsEmailLoaded(false);
            //setAppError(err);
            return err;
            // setAppError(err.response.data);
            //}
          });
      }
    }
    loadEmails();
  }, [ isEmailLoading,pocketBase.authStore,randomToken]);

  useEffect(() => {
    //
    if (webworker !== undefined) {


      webworker.addEventListener("status",
        (values) => {
          // console.log(values);
          //if (values === "loading") {
          console.log(
            "WebWorker received new page load status. POCLoadingStatus: " +
              values
          );
          setPOCLoadingStatus(values);
          console.log("POCLoadingStatus: " + values);

          //}
        },
        [setPOCLoadingStatus, webworker, navigate, state]
      );

      webworker.addEventListener("message", (values) => {
        try {
          //Backend Error
          //We have an error
          if (values.data.error) {
            if (values.data.error.indexOf("delayed") > -1) {
              setPOCLoadingStatus("delayed");
              console.log("delayed: " + values.data.error);
              //return doLoadPocInfo();
            } else if (values.data.error.indexOf(401) > -1) {
              setPOCLoadingStatus("unauthorized");
              // notifications.show({
              //   title: 'Failed authentication',
              //   message: 'Please restart your browser to clear the cookies to start a new session! 🤥',
              // })
            } else if (values.data.error.indexOf(404) > -1) {
              setPOCLoadingStatus("notfound");
              console.log("POCLoadingStatus: notfound");
              //navigate('/');

              // notifications.show({
              //   title: 'Failed to locate Point Of Contact',
              //   message: 'You need to create a new Point Of Contact to use this service!',
              // })
            } else {
              notifications.show({
                title: "Failed authentication",
                message: "Unknown error! 🤥",
              });
              setPOCLoadingStatus("unkownerror");
              console.log("POCLoadingStatus: unkownerror");
              //navigate('/st_auth');
            }
          }

          //No backend error no res received because "loading"
          else if (
            (!values.data.res || values.data.res === undefined) &&
            values.data.status &&
            values.data.status.indexOf("loading") === 0
          ) {
            setPOCLoadingStatus("loading");
            console.log("POCLoadingStatus: loading");

            state.set((p) => {
              //p + 1
              return {
                status: "loading",
                res: null,
                error: null,
              };
            });

            console.log("RECEIVED THE MESSAGE FOR LOADING.");
          }
          // Back end data returned but empty or undefined or defined but not a json string.
          else if (
            (values.data.res && (values.data.res == {})) ||
            values.data.res === undefined ||
            !isJsonString(values.data.res)
          ) {
            console.log("RECEIVED THE MESSAGE TO CREATE.");
            setPOCLoadingStatus("creating");
            console.log("POCLoadingStatus: creating");

            setIsPocToBeCreated(true);

            setDisplayOpenedCreate(true);
          }

          //We have a resource available in JSON format from the back-end.
          else if (
            values.data.res &&
            isJsonString(values.data.res) &&
            !isPocToBeCreated
          ) {
            let res = JSON.parse(values.data.res);

            setPocRulesState(res.rules);
            //setPocInfoState.rules.set(res.rules);

            setPocInfoState({
              public_ip: res.poc_ext_ip,
              public_ip_v6: res.poc_ext_ip_v6 ? res.poc_ext_ip_v6 : "",
              idmgt_id: res.idmgt_id ? res.idmgt_id : "",
              log_key: res.log_key ? res.log_key : "",
              geo_key: res.geo_key ? res.geo_key : "",
              contact_email: res.contact_email ? res.contact_email : "",
              "poc-subnet": res["poc-subnet"],
              dns_name_cust: res["dns_name_cust"],
              ready: res["ready"],
              "poc-id": res["poc-id"],
              dns_name: res["dns_name"],
              ext_port_nb: res["ext_port_nb"],
              next_action: res["next_action"],
              "nw-id": res["nw-id"],
              "poc-subnet-ip": res["poc-subnet-ip"],
              created_date: res["created_date"],
              updated_date: res["updated_date"],
              timestamp_rules_last_updated: res["timestamp_rules_last_updated"],
              dns_name_cust_mx1: res["dns_name_cust_mx1"],
              dns_name_cust_mx2: res["dns_name_cust_mx2"],
              dns_name_cust_ns1: res["dns_name_cust_ns1"],
              dns_name_cust_ns2: res["dns_name_cust_ns2"],
              dns_name_cust_dkim: res["dns_name_cust_ns2"],
              dns_name_cust_dkim_domain: res["dns_name_cust_dkim_domain"],
              dns_name_cust_spf: res["dns_name_cust_spf"],
              smtp_webhook: res["smtp_webhook"],
              smtp_apple_icloud: res["smtp_apple_icloud"]
                ? res["smtp_apple_icloud"]
                : false,
              smtp_to_forward_to: res["smtp_to_forward_to"]
                ? res["smtp_to_forward_to"]
                : "",
              smtp_to_forward_map: res["smtp_to_forward_map"]
                ? res["smtp_to_forward_map"]
                : [],
              ssl_certs: res["ssl_certs"],
              ssl_key: res["ssl_key"],
              github_repository_url: res["github_repository_url"],
              github_secret: res["github_secret"],
              image: res["image"],
              apps: res["apps"] ? res["apps"] : [],
              target_transparent_ison: res["target_transparent_ison"]
                ? res["target_transparent_ison"]
                : false,
              target_transparent: res["target_transparent"]
                ? res["target_transparent"]
                : "",
              wg: res["wg"] ? res["wg"] : "",
              static_deploy_init_root_jwt: res["static_deploy_init_root_jwt"]
                ? res["static_deploy_init_root_jwt"]
                : "",
              static_deploy_init_jwt_key: res["static_deploy_init_jwt_key"]
                ? res["static_deploy_init_jwt_key"]
                : "",
                dns_zone:res['dns_zone']?res['dns_zone']:"",
            });
            setPOCLoadingStatus("loaded");
            //setLogURL(onGetLogURL());
            console.log("POCLoadingStatus: loaded");

            setIsPocToBeCreated(false);
            setIsPocLoaded(true);
            //setLogURL('/api/log?pwd=' + res["dns_name_cust"] + '&k=' + (res["log_key"] ? encodeURI(res["poc-id"] + res["log_key"]) :'nokey') + '&max=100&ts=' + Number(new Date())  )
            //   setIsPocLoading(true);
            //   await loadPoc();
            //   setIsPocLoading(false);
          }
          // ,
          // //After this state has been set this callback is called.
          // not supported for some reason (When called from a use effect called function one cant set a state
          // and ask for this callback when the state has been updated, sucks so we set pocloaded later .)
          // () => {
          //   //setIsPocLoaded(true);
          //   console.log("After burner");
          //
        } catch (error) {
          console.log("ERROR: " + error);
          console.log(
            "Error PoC no longer accessible, maybe session unavailable, leaving home..."
          );

          navigate("/");
        }
      });

      webworker.addEventListener("error", (e) => {
        console.error("Error in event subsystem: " + e.message);
      });
    }
  }, [
    onGetLogURL,
    navigate,
    state,
    webworker,
    isPocToBeCreated,
    POCLoadingStatus,
    logURL,
    logSearchKey,
    maxLogLines,
  ]);



  useEffect(() => {
    //For POC loading
    // if(!true) {
    //
    //console.log(JSON.stringify(session));


//  if ( !isLoading && !pocketBase.authStore?.isValid) {
//       //Not logged in we should not be here
//       console.log("Auth not set, leaving home...");
//       //navigate('/st_auth');      console.log("Checking in. POC loading, not loaded yet...");
//     }

//     if (isLoading) {
//       console.log("Session still loading...");
//     } else 
    if (
      // (
      //   (
      //     auth &&
      //     (auth !== {})
      //   )
      //  ||
      //false &&
      pocketBase.authStore?.isValid
      // )
      //  &&
      //  (!isPocLoaded && !isPocLoading  )
    ) {

      //console.log("here");
      // async function waitTillReady() {
      //   //console.log("Reattempting");
      //   //setPocInfoState({"ready":false})
      //
      //   setIsPocLoading(true);
      //   await loadPoc();
      //   setIsPocLoading(false);
      //   if (isPocLoaded && !isPocLoading) {
      //     console.log("POC loaded.");
      //     //clearInterval(timerPOC);
      //     //POC is loaded we load our emails.
      //   } else if (!isPocLoaded && !isPocLoading) {
      //     //await loadEmails();
      //     setIsPocLoading(true);
      //     loadPoc();
      //     setIsPocLoading(false);
      //     if (isPocLoaded && !isPocLoading) {
      //     }
      //   }
      //   return;
      // }
    }
    if (isPocLoaded) {
      //We stay on this page until we get in.
      console.log("Checking in... Authorized and POC loaded");
    } else if (!isPocLoaded && isPocLoading) {
      // console.log("Checking in. POC loading, not loaded yet...");
    } else if (!isPocLoaded && !isPocLoading) {
      console.log("Error Checking in. POC not loaded should log off.");
      //navigate("/st_auth");
    } else if (!isPocLoading) {
      console.log("POC Not loading.");

      //navigate("/login");
    }
  }, [
    POCLoadingStatus,
    logURL,
    auth,
    isPocLoaded,
    isPocLoading,
    POCLoadingStatus,
    PocInfosState,
    pocketBase.authStore?.isValid,
    navigate,
    logSearchKey,
    maxLogLines,
  ]);
  useEffect(() => {
    //
    if (isPocToBeCreated) {
      return () => {};
    } else if (!isPocToBeUpdated) {
      setIsPocToBeCreated(false);
      return doLoadPocInfo();
    }
  }, [
    PocRulesState,
    pocketBase,
    navigate,
    doLoadPocInfo,
    isPocToBeUpdated,
    isPocToBeCreated,
    logSearchKey,
    maxLogLines,
  ]); 
  /*   useEffect(()=>{
    if (Object.keys(PocRulesState).length > 0)  {
      console.log("saving POC after rule change")
      savePoc();

    }
},[PocRulesState,savePoc]) */
  useEffect( () =>  {


    // let {doesSessionExist, pocketBase.authStore?.model.id, accessTokenPayload} = session;

    let timerMail;

    //For Email  loading
    if   ( (pocketBase.authStore?.isValid) 
      // && !isEmailLoaded && !isEmailLoading  
    ) {

      setIsEmailLoading(true);
      onLoadEmail();
      setIsEmailLoading(false);
      
      async function waitTillReady() {

        // checkSTSession();
 
       
        setIsEmailLoading(true);
        onLoadEmail();
        setIsEmailLoading(false);
        
        if (isEmailLoaded && !isEmailLoading) {
            console.log("Emails loaded.");
          clearInterval(timerMail);
        } else if (!isEmailLoaded && !isEmailLoading) {
          onLoadEmail();
          if (isEmailLoaded && !isEmailLoading) {
            console.log("Emails loaded.");
          } 
        }
        return;
      }
      
      //If we have a timer we clear it and we recreate it.
      //Timer calls function a above every 10000 ms (10s)
      // if (timerMail){
      //   clearInterval(timerMail);
      // }
      //   timerMail = setInterval(waitTillReady(), 80000);
      } 
      
      
    if ((pocketBase.authStore?.isValid) && isEmailLoaded  ) {
      //We stay on this page until we get in.
      console.log("Checking in... Authorized and Email loaded");
    } 
    else if ((pocketBase.authStore?.isValid) && !isEmailLoaded && isEmailLoading){
      console.log("Checking in. Email loading, not loaded yet...");
    } 
    else if ((pocketBase.authStore?.isValid)&& !isEmailLoaded && !isEmailLoading){
      console.log("Error Checking in. Email not loaded should log off.");
      //navigate("/login");
    } 
    else if ((pocketBase.authStore?.isValid)&& !isEmailLoading) {
  
       //navigate("/login");
    }
  }, [auth,isEmailLoaded,isEmailLoading,onLoadEmail,pocketBase.authStore?.isValid]);
   

  // useEffect(() => {
  //   if (refreshInterval && refreshInterval > 0) {
  //     const interval = setInterval(loadEmails(), refreshInterval);
  //     return () => clearInterval(interval);
  //   }
  // }, [refreshInterval,loadEmails]);

  const columns = useMemo(
    () => [
      {
        // Make an expander cell
        Header: ({ name }) => {
          return (
            <div
              style={{
                width: "20px",
                marginRight: "0.5rem",
              }}
            ></div>
          );
        },
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          // <span {...row.getToggleRowExpandedProps()}>
          //   {row.isExpanded ? '👇' : '👉'}
          // </Box>
          <Box></Box>
        ),
      },

      {
        Header: ({ name }) => {
          return <div>Device</div>;
        },
        id: "Address",
        // First group columns
        columns: [
          {
            Header: ({ name }) => {
              return (
                <div
                  style={{
                    width: "120px",
                    marginRight: "0.5rem",
                  }}
                >
                  Name
                </div>
              );
            },

            accessor: "conf.wgInterface.name",
          },
          {
            Header: ({ name }) => {
              return (
                <div
                  style={{
                    width: "120px",
                    marginRight: "0.5rem",
                  }}
                >
                  IP
                </div>
              );
            },
            id: "IP",
            accessor: "conf.wgInterface.address",
          },
        ],
      },
      {
        // Second group - Details
        Header: ({ name }) => {
          return (
            <div
              style={{
                width: "180px",
                marginRight: "10.5rem",
              }}
            ></div>
          );
        },
        id: "Copy",
        columns: [
          {
            id: "WireguardConfig",
            Header: ({ name }) => {
              return (
                <>
                  <div
                    style={{
                      width: "120px",
                      marginRight: "0.5rem",
                    }}
                  >
                    Wireguard Configuration
                  </div>
                </>
              );
            },
            accessor: "conf_a",
            Cell: ({ cell: { value } }) => {

              return (
                <Container size="sm" className={classes.wrapper}>
                  {/*} <div
                            style={{
                              width: "40px",
                              textalign: "center",
                              marginRight: "0.5rem",
                              display: "table",
                            }}
                          >
                          
                <button className="btn btn--DL" onClick={() => dlWGConfigFile(value)}>
                              Save
                          </button>                </div> */}
                  <div
                    className="smart-layers-pointers"
                    style={{ backgroundImage: `url(${downLoad})` }}
                  ></div>
                  <button
                    style={{ backgroundImage: `url(${downLoad})` }}
                  ></button>
                  <div
                    style={{
                      width: "40px",
                      textalign: "center",
                      marginRight: "0.5rem",
                      display: "table",
                    }}
                  >
                    <SaveAction
                      saveVal={value}
                      saveFn={dlWGConfigFile}
                      src={downLoad}
                    />

                    <div
                      className="smart-layers-pointers"
                      style={{ backgroundImage: `url(${downLoad})` }}
                    ></div>
                    <CopyAction tocopy={value} src={copyPaste} />
                  </div>
                </Container>
              );
            },
          },
          {
            id: "ScriptToFile",
            Header: ({ name }) => {
              return (
                <div
                  style={{
                    width: "120px",

                    marginRight: "0.5rem",
                  }}
                >
                  Wireguard Install + Config script
                </div>
              );
            },
            accessor: "conf_a",
            Cell: ({ cell: { value } }) => {
              const pasteScript =
                "echo your public ip is now: `wget -qO- ifconfig.me` \n" +
                "wg-quick down wg0 \n" +
                "rm /etc/resolv.conf \n" +
                "systemctl stop systemd-resolved.service \n" +
                "systemctl disable systemd-resolved.service \n" +
                "echo nameserver 8.8.8.8 >> /etc/resolv.conf \n" +
                "echo nameserver 1.1.1.1 >> /etc/resolv.conf \n" +
                "echo nameserver 8.8.8.8 >> /run/systemd/resolve/stub-resolv.conf \n" +
                "echo nameserver 1.1.1.1 >> /run/systemd/resolve/stub-resolv.conf \n" +
                "apt-get update  -q -y \n" +
                "apt-get install wireguard-tools gpg qrencode grepcidr openresolv  -q -y \n" +
                "cat <<EOF >> /etc/wireguard/wg0.conf \n" +
                value +
                "\n" +
                "EOF\n" +
                "wg-quick up wg0 \n" +
                "echo your public ip is now: `wget -qO- ifconfig.me` \n" +
                "echo Login to https://setip.io to configure access to this host. \n" +
                "\n";

              return (
                <div
                  style={{
                    width: "40px",

                    marginRight: "0.5rem",
                  }}
                >
                  {/*    <button key={pasteScript} className="btn btn--DL" onClick={() => dlWGScriptFile(pasteScript)}>
                    Save
                </button> */}

                  <div
                    style={{
                      width: "40px",

                      marginRight: "0.5rem",
                    }}
                  >
                    <CopyAction tocopy={pasteScript} src={copyPaste} />
                  </div>
                </div>
              );
            },
          },
        ],
      },
    ],
    [classes.wrapper]
  );

  //const forceUpdateLog = useForceUpdateLog();

  //We only display our page if our user is authorized and POC loaded.

  // if   (!isPocLoaded  &&  Oldsession)
  //   {
  //         console.log("We should redirect to st_auth from here.")
  //   }



  //
  if (POCLoadingStatus === "notfound") {
    return (
      <Container size="sm" className={classes.wrapper}>
        {/* 

     </MyHeader> */}

        <Modal
          opened={displayWaitForCreate}
          //size="auto"
          onClose={() => {}}
          title="Creating PoC...."
          withCloseButton={false}
        >
          <h1> Please wait while your Point of contact is being created.</h1>
        </Modal>

        <Modal
          opened={POCLoadingStatus === "notfound"}
          //size="auto"
          onClose={() => {}}
          title=""
          withCloseButton={false}
        >
          <div className="inlineForm">
            {/* <h2>Seeding location</h2> */}

            {/* <div></div>
 <h2>Free for students</h2>
 <h4>Just use your school email</h4>
 <h4>and pick a location on AWS!</h4>
 <div></div> */}

            {/* <div>{values["vendor_id"]}</div>
<div>{values.region}</div> */}

            <div className="inlineForm__notif">
              {/* {error && <Error error="Error" />} */}
            </div>

            <Box sx={{ maxWidth: 380 }} mx="auto">
              {/* <form
 onSubmit={ 
 form.onSubmit(  (values) =>  {
 //
 console.log("Hi");
 console.log(values);
 } ) }
> */}

              {/* <form onSubmit={handleRegister}> */}

              {/*   <label>
   <Box>Start region</Box>
 
   <select
     name="region"
     className=" input"
     label="Primary region"
     value={values.region}
     onChange={handleOnRegionChange}
   >
     <OptionsRegions></OptionsRegions>
   </select>
 </label>*/}

              {/* <Select
label="Select a region"
withAsterisk
icon={<IconWorld size={14} />} 
placeholder="Ex: Americas West"
// searchable
// onSearchChange={onSearchChange}
// searchValue={searchValue}
nothingFound="No options"
data={regions_002}
value={selectedWorldRegion} 
onChange={(value) => {
 const tmpven =    _locations.filter(  (v) => {
   console.log(v.ipio_world_region + " " + value);
   if (v.ipio_world_region === value) { return true } else return false;
 });
 

 setFilteredVendors((tmpven && tmpven[0]) ? tmpven :  []);
 setSelectedVendor((tmpven && tmpven[0]) ? tmpven[0] :  null);
 // setUserEmail(event.currentTarget.value);
 //     console.log(event.currentTarget.value);
 //console.log(value);
 setSelectedWorldRegion(value);

}}
// {...form.getInputProps("region")}
/>


<Select
label="Select a location"
withAsterisk
icon={<IconMap2 size={14} />} 

placeholder="Ex: AWS West Oregon"
//searchable
// onSearchChange={onSearchChange}
//searchValue={searchValue}
// filter={(value, item) =>
//               {

//                 console.log(value.ipio_world_region);
//                 console.log(selectedWorldRegion);
//                       return true;
//                 }
// //   // item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
// //   // item.description.toLowerCase().includes(value.toLowerCase().trim())


// }
nothingFound="No options"
data={filteredVendors}
value={selectedVendor} 
onChange={setSelectedVendor}

/> */}



              <TextInput
                placeholder={
                  "Project name - max 20 characters - no special char."
                }
                label="Project name"
                icon={<IconAppWindow size={14} />}
                value={projectName}
                withAsterisk
                onChange={(event) => {
                  setProjectName(event.currentTarget.value);
                  // console.log(event.currentTarget.value);
                }}
                //name={"username"}
                //autoComplete={"username"}

                //{...form.getInputProps('username')}
              />

<Space h="lg" />

<Select
      label="Pick a location"
      placeholder="Pick one"
      withAsterisk
      value={selectedWorldRegion}
      onChange={(event) => {
        // console.log(event)
        setSelectedWorldRegion(event);
      }}
      data={[
        { value: 'sanfrancsico', label: 'San Francisco' },
        { value: 'newyor', label: 'New York' },
        { value: 'london', label: 'London' },
        { value: 'paris', label: 'Paris' },
        { value: 'bengalore', label: 'Bengalore' },
        { value: 'singapore', label: 'Singapore' },
      ]}
    />

{/* <Select
                label="Optimize access near"
                default=""
                icon={<IconNetwork size={14} />}
                data={op_modes}
                value={setSelectedWorldRegion}
                onChange={handleOnRegionChange}
                placeholder="Paris"
                // searchable
                // onSearchChange={onSearchChange}
                // searchValue={searchValue}
                nothingFound="No options"
              /> */}

<Space h="lg" />
<TextInput
                label="Contact email (use same as for payment)"
                value={userEmail}
                withAsterisk
                placeholder="youremail@domain.com"
                icon={<IconAt size={14} />}
                //onChange={setUserEmail}
                onChange={(event) => {
                  setUserEmail(event.currentTarget.value);
                  //console.log(event.currentTarget.value);
                }}
              />


 <Space h="lg" />
              <Select
                label="Avanced"
                default=""
                icon={<IconNetwork size={14} />}
                data={op_modes}
                value={opMode}
                onChange={setOpMode}
                placeholder="Proxy mode with Auto SSL and Auto DNS"
                // searchable
                // onSearchChange={onSearchChange}
                // searchValue={searchValue}
                nothingFound="No options"
              />

              {/* <FormInput
     type={"text"}
     placeholder={"Project name - max 20 characters - no special char."}
     name={"username"}
     autoComplete={"username"}
     value={values.username}
     handleChange={handleChange}
 /> */}

              {/* <ConfirmPasswordInput
     type={"password"}
     placeholder={"Password"}
     placeholderConfirm={"Confirm password"}
     name={"password"}
     nameConfirm={"passwordConfirm"}
     value={values.password}
     valueConfirm={values.passwordConfirm}
     handleChange={handleChange}
 />
*/}
              {/* <FormInput
     type={"text"}
     placeholder={"Optional code"}
     name={"Invite Code"}
     autoComplete={"SOMECODE"}
     value={values.invite}
     handleChange={handleChange}
   /> */}
 <Space h="lg" />
              <Group position="right" mt="md">
                <Button
                  display={POCLoadingStatus !== "justcreated"}
                  variant="filled"
                  onClick={(event) => {
                    event.preventDefault();
                    console.log(
                      "User ID: " +
                        pocketBase.authStore?.model.id +
                        " User email: " +
                        userEmail +
                        " Project name: " +
                        projectName +
                        " Region: " +
                        selectedWorldRegion +
                        " Op Mode: " +
                        opMode +
                        " Vendor: " +
                        selectedVendor
                    );

                    setDisplayOpenedCreate(false);

                    setDisplayWaitForCreate(true);

                    setPOCLoadingStatus("justcreated");
                    console.log("POCLoadingStatus: justcreated");
                    handleRegister(
                      {
                        username: projectName.replaceAll(" ", "-"),
                        email: userEmail,
                        dns: "",
                        password: "",
                        passwordConfirm: "",
                        region: selectedWorldRegion
                          ? selectedWorldRegion
                          : "newyorkcity",
                        vendor_id: selectedVendor ? selectedVendor : "direct",
                        invite: "",
                        op_mode: opMode,
                        st_userid: pocketBase.authStore?.model.id,
                      },
                      (err, result) => {
                        if (!err) {
                          // if (result === "OK") {

                          setPOCRegistrationError("");
                          setDisplayWaitForCreate(false);
                          setPOCLoadingStatus("loaded");
                          console.log("POCLoadingStatus: loaded");
                          navigate("/onboarding");

                          // }
                        } else {
                          setPOCRegistrationError(err);
                          setDisplayWaitForCreate(false);
                          setPOCLoadingStatus("unkownerror");
                          navigate("/");
                          console.log(
                            "POCLoadingStatus: unkownerror " + err.message
                          );
                        }
                      }
                    );
                  }}
                  //type="submit"
                  // color="#86d242"
                  color="lime"
                  uppercase
                  //gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}
                >
                  Create
                </Button>
              </Group>
              {/* <div className="inlineForm__submit">
     {/* <Link to="/st_auth">
       <Prompt prompt={"Existing account? Log in."} />
     </Link> 
     <CTA name={"create"} type={"submit"} />
   </div> */}

              {/* </form> */}
            </Box>
          </div>
        </Modal>
      </Container>
    );
  } else if (
    !isPocLoaded &&
    POCLoadingStatus &&
    (POCLoadingStatus === "unauthorized" ||
      POCLoadingStatus === "unknownerror") &&
    Oldsession
  ) {
    return (
      <Container size="sm" className={classes.wrapper}>
        {/* 
</MyHeader> */}

        <h3>
          Session error, please try to login again or restart your browser to
          clear the cache.{" "}
        </h3>
      </Container>
    );
    //Session exists but claims not accepted when retrieving PoC, tokens may have expired.
    //At this point goal is to loff the user
  } else if (pocketBase.authStore?.isValid) {
    //setLogURL(onGetLogURL());
    const staticdeploy_script = `
    #! /bin/bash
    export STATICDEPLOY_API_TOKEN="${PocInfosState.static_deploy_init_root_jwt}" \\
    export STATICDEPLOY_API_URL="https://${PocInfosState.dns_name}/api" \\
    export BUILD_DIR=./build \\
    export STATICDEPLOY_APP_NAME=setipio \\
    export STATICDEPLOY_APP_TAG=master \\
    export STATICDEPLOY_APP_ENTRY_POINT="setip.io/" \\
    export STATICDEPLOY_APP_DESC="WEB site" \\
    export BUILD_DIR=./build \\
    \\
    yarn build \\
    \\

    staticdeploy bundle \\
  --apiUrl $STATICDEPLOY_API_URL \\
  --apiToken $STATICDEPLOY_API_TOKEN \\
  --from ./build \\
  --name 'setipioweb' \\
  --tag master \\
  --description "SETIP.IO WEB" \\
  --fallbackAssetPath /index.html\

    staticdeploy bundle \\
--appName $STATICDEPLOY_APP_NAME \\
--description "Script generated bundle" \\
--tag $STATICDEPLOY_APP_TAG \\
--apiUrl $STATICDEPLOY_API_URL \\
--apiToken $STATICDEPLOY_API_TOKEN \\
--appEntryPoint $STATICDEPLOY_APP_ENTRY_POINT \\
--from $BUILD_DIR \\
--name $STATICDEPLOY_APP_NAME \\
--tag $STATICDEPLOY_APP_TAG \\
--appDesc $STATICDEPLOY_APP_DESC \\
--fallbackAssetPath /index.html \\
  \\
  \\
    staticdeploy deploy \\
  --apiUrl $STATICDEPLOY_API_URL \\
  --apiToken $STATICDEPLOY_API_TOKEN \\
  --app $STATICDEPLOY_APP_NAME \\
  --entrypoint $STATICDEPLOY_APP_ENTRY_POINT \\
  --bundle $STATICDEPLOY_APP_NAME:$STATICDEPLOY_APP_TAG\
    `
    return (
      <Container
      padding="xs"
      margin="2px"
      margin-left="5px"
      
      >
        {/* 
        </MyHeader> */}

        {isPocLoaded && (
          <div style={{ justifyContent: "center" }}>
              {/* <Tabs poc_state={PocInfosState}> */}
              <Box display={leftPaneLinkIndex === "url" ? "" : "none"}>
                  <Resizable
                    defaultSize={{
                      width: "100%",
                      height: "80%",
                    }}
                  >
                    <PocTable
                      className="page__body"
                      dns_name={PocInfosState.dns_name}
                      dns_name_cust={PocInfosState.dns_name_cust}
                      deploy_key={PocInfosState.static_deploy_init_jwt_key}
                      //addRule={addToRuleBuffer}
                      setRules={setPocRulesState}
                      rules={PocRulesState}
                      //loadPoc= {loadPoc}
                      savePoc={savePoc}
                      saveRulesAndPoc={saveRulesAndPOC}
                      setPocDNSState={saveDNSAndPOC}
                    ></PocTable>
                  </Resizable>
                
              </Box>
              <Box
                display={
                  leftPaneLinkIndex && leftPaneLinkIndex === "dnsoverhttp" ? "" : "none"
                }
              >
                <div>
                  {/* <h1>{POCLoadingStatus}</h1>
              <h1>{POCRegistrationError}</h1> */}
                </div>
                <div className="divTabTextGroup">
                  <div className="divTabTextEditLocked">
                    <div className="subHeading">DNS server:</div>
                  </div>
                </div>
                <div className="appManageBlockFrame">
                  <div className="divTabTextLabel">Default name:</div>

                  <div className="divTabTextLabel">
                    {PocInfosState["dns_name"]}
                  </div>
                </div>

                <div className="appManageBlockFrame">
                  <div className="divTabTextLabel">Customer's name:</div>

                  <div className="divTabTextLabel">
                    {PocInfosState["dns_name_cust"]}
                  </div>
                </div>

                <div className="appManageBlockFrame">
                  <div className="divTabTextLabel">Public IP v4:</div>

                  <div className="divTabTextLabel">
                    {PocInfosState["public_ip"]}
                  </div>
                </div>

                <div className="appManageBlockFrame">
                  <div className="divTabTextLabel">Public IP v6:</div>

                  <div className="divTabTextLabel">
                    {PocInfosState["public_ip_v6"]}
                  </div>
                </div>

                <div className="appManageBlockFrame">
                  <div className="divTabTextLabel">Private IP v4:</div>

                  <div className="divTabTextLabel">
                    {PocInfosState["poc-subnet-ip"]}
                  </div>
                </div>

                {/* <div className="divTabTextLabel">
                    Static IP (
                    {PocInfosState["target_transparent_ison"] === false ? (
                      <Box>All URLs to</Box>
                    ) : (
                      <Box>(Forwards to peer2)</Box>
                    )}
                    )
                  </div> */}

                <div className="divTabTextGroup">
                  <div className="divTabTextEditLocked">
                    <div className="subHeading">
                      Private DNS clients (secured):
                    </div>
                  </div>
                </div>

                <div className="appManageBlockFrame">
                  <div className="divTabTextEditLocked">
                    https://doh.{PocInfosState["dns_name_cust"]}
                  </div>
                </div>

                <div className="appManageBlockFrame">
                  <div className="divTabTextEditLocked">
                    iOS custom DNS Configuration file
                  </div>
                  <div className="divTabTextEditLocked">
                    <button className="btn" onClick={getDOHMobileConfig}>
                      install
                    </button>
                    {/*<a href="https://{PocInfosState['dns_name_cust']}/dohmobileconfig" download="test.mobileconfig"> Download Here </a>*/}
                    {/*<a href="https://{PocInfosState['dns_name_cust']}/dohmobileconfig" target="_blank" rel="noopener noreferrer" download></a>*/}
                  </div>
                </div>
              </Box>

             
        


      <Box  
                 display={leftPaneLinkIndex === "appstore" ? "" : "none"}
                 style={{ paddingLeft: "20px" }} 
                >

                  <Resizable
                    defaultSize={{
                      width: "calc(100% - 20px)",
                      height: "95%",
                    }}
                  >
                    <Stack>
                                                  {/* <Button
                                className=""
                                onClick={() => {
                                  // setLogURL ('');
                                  // setLogURL ('/api/log?pwd=' + PocInfosState.dns_name_cust + '&k=' + (PocInfosState.log_key ? encodeURI(PocInfosState["poc-id"] + PocInfosState.log_key) :'nokey'));
                                  // const theLurl = onGetLogURL();
                                  // setLogURL(theLurl);
                                  // saveDNSAndPOC(PocDNSState);
                                }}
                                
                              >
                                <RiSaveLine
                                  style={{
                                    color: "#7b7b7b",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                />
                              </Button> */}

                              {/* <ReactJson 
                               name = {PocInfosState.dns_name}
                               sortKeys={true}
                               displayDataTypes={false}
                               defaultValue={[]}
                               onEdit = {(edit)=>{
                                console.log(edit);
                                setPocDNSState(edit.updated_src)
                                // console.log(edit.updated_src)
                               }}
                               theme={"bright-inverted"}
                               onAdd = {(edit)=>{
                                if (edit.new_value === "error") {
                                  return false
                                }
                                setPocDNSState(edit.updated_src)
                                // console.log(edit.updated_src)

                               }}
                               onDelete = {(edit)=>{
                                setPocDNSState(edit.updated_src)
                                // console.log(edit.updated_src)

                               }}
                               collapsed={2}
                               collapseStringsAfterLength={40}
                              src={ PocInfosState.dns_zone 
                              } /> */}
                    </Stack>

                                  {/* <JsonEditor
      data={ PocInfosState.dns_zone }
      onChange={data => {
        // setEditObject(data)
        debugger;
        setPocDNSState(data);
        // setPocDNSState(JSON.parse(value));
      }}
      optionsMap={{
        color: [
          { value: 'red', label: 'Red' },
          { value: 'blue', label: 'Blue' },
        ],
        city: [
          { value: 'beijing', label: 'Beijing' },
          { value: 'shanghai', label: 'Shanghai' },
        ],
      }}
    /> */}

                              {/* <JsonEditor
  data={ PocInfosState.dns_zone }
  // onChange={() => {
  //   // debugger;
  //   // console.log("Changed");
  //   // setPocDNSState(JSON.parse(value));
  // }} />*/}

          

              {/* <JSONInput
        id          = 'a_unique_id'
        placeholder = { PocInfosState.dns_zone }
       // colors      = {  }
        locale      = { locale }
        height      = '100%'
        mode = "code"
        width="100vh"
        onChangeText = { (value) => {
          setPocDNSState(JSON.parse(value));
        } }
    /> */}
    </Resizable>

              </Box>


              <Box  
                 display={leftPaneLinkIndex === "dns" ? "" : "none"}
                 style={{ paddingLeft: "20px" }} 
                >

                  <Resizable
                    defaultSize={{
                      width: "calc(100% - 20px)",
                      height: "95%",
                    }}
                  >
                    <Stack>
                                                  <Button
                                className=""
                                onClick={() => {
                                  // setLogURL ('');
                                  // setLogURL ('/api/log?pwd=' + PocInfosState.dns_name_cust + '&k=' + (PocInfosState.log_key ? encodeURI(PocInfosState["poc-id"] + PocInfosState.log_key) :'nokey'));
                                  // const theLurl = onGetLogURL();
                                  // setLogURL(theLurl);
                                  saveDNSAndPOC(PocDNSState);
                                }}
                                
                              >
                                <RiSaveLine
                                  style={{
                                    color: "#7b7b7b",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                />
                              </Button>

                              <ReactJson 
                               name = {PocInfosState.dns_name}
                               sortKeys={true}
                               displayDataTypes={false}
                               defaultValue={[]}
                               onEdit = {(edit)=>{
                                console.log(edit);
                                setPocDNSState(edit.updated_src)
                                // console.log(edit.updated_src)
                               }}
                               theme={"bright-inverted"}
                               onAdd = {(edit)=>{
                                if (edit.new_value === "error") {
                                  return false
                                }
                                setPocDNSState(edit.updated_src)
                                // console.log(edit.updated_src)

                               }}
                               onDelete = {(edit)=>{
                                setPocDNSState(edit.updated_src)
                                // console.log(edit.updated_src)

                               }}
                               collapsed={2}
                               collapseStringsAfterLength={40}
                              src={ PocInfosState.dns_zone 
                              } />
                    </Stack>

                                  {/* <JsonEditor
      data={ PocInfosState.dns_zone }
      onChange={data => {
        // setEditObject(data)
        debugger;
        setPocDNSState(data);
        // setPocDNSState(JSON.parse(value));
      }}
      optionsMap={{
        color: [
          { value: 'red', label: 'Red' },
          { value: 'blue', label: 'Blue' },
        ],
        city: [
          { value: 'beijing', label: 'Beijing' },
          { value: 'shanghai', label: 'Shanghai' },
        ],
      }}
    /> */}

                              {/* <JsonEditor
  data={ PocInfosState.dns_zone }
  // onChange={() => {
  //   // debugger;
  //   // console.log("Changed");
  //   // setPocDNSState(JSON.parse(value));
  // }} />*/}

          

              {/* <JSONInput
        id          = 'a_unique_id'
        placeholder = { PocInfosState.dns_zone }
       // colors      = {  }
        locale      = { locale }
        height      = '100%'
        mode = "code"
        width="100vh"
        onChangeText = { (value) => {
          setPocDNSState(JSON.parse(value));
        } }
    /> */}
    </Resizable>

              </Box>

              <Box  display={
                  leftPaneLinkIndex && leftPaneLinkIndex === "whiteboard"
                    ? ""
                    : "none"
                }>

                <Resizable  defaultSize={{
            
                    }}>

      <div style={{ height: "90vh", width: "100%" }}>
        <Excali />
      </div>
                </Resizable>
              </Box>

              <Box
                display={
                  leftPaneLinkIndex && leftPaneLinkIndex === "network"
                    ? ""
                    : "none"
                }
              >
                  <Resizable
                    defaultSize={{
                      width: 280,
                      height: 1000,
                    }}
                  >
                    {/* <div className="Logs"> */}

                    <ConfTable
                      columns={columns}
                      dns={
                        PocInfosState.dns_name_cust_owned
                          ? PocInfosState.dns_name_cust_owned
                          : PocInfosState.dns_name_cust
                          ? PocInfosState.dns_name_cust
                          : PocInfosState.dns_name
                      }
                      email={PocInfosState.contact_email}
                      data={
                        PocInfosState["wg"] && PocInfosState["wg"]["peers"]
                          ? PocInfosState["wg"]["peers"]
                          : []
                      }
                      app_data={AppScriptTemplState ? AppScriptTemplState : []}
                    />

                    {/* </div> */}
                  </Resizable>

                  <div className="appManageBlockFrame">
                    <div className="divTabTextLabel">Mode</div>

                    {PocInfosState["target_transparent_ison"] === false ? (
                      <Box>Proxy (URLs to Devices)</Box>
                    ) : (
                      <Box>Direct to device 2</Box>
                    )}
                  </div>

                  <div className="divTabTextGroup">
                    <div className="divTabTextLabel"></div>
                    <div className="divTabTextEditLocked"></div>
                  </div>

                  {/* <div className="divTabTextGroup"> */}

                  {/* <div className="subHeading">Connecting apps and devices</div>

                <div className="divTabTextGroup">
                  <div className="divTabTextEditLocked">
                    Wireguard is the open source app that connects securely
                    devices to your private encrypted network.

                    Wireguard is installed out of the box on Linux.

                    Follow the link above for Mac and Windows installers.

                    A device is any computer, mobile or IOT hardware to be
                    connected to your private network.

                    Devices can be securely accessed from the Internet by first
                    installing Wireguard on the device, second by loading
                    Wireguard on the device with its associated configuration
                    file.

                    An additional network interface, provided by Wireguard, is
                    created on the device, providing it with its own IP address
                    pulled from the values in its own configuration file. Some
                    OpenWRT router will let you paste the Wireguard Client
                    configuration so we provide for each device a way to copy
                    its configuration.

                    You can attach as many devices as listed below. Your devices
                    Wireguard configuration can be copied by clicking on the
                    pasteboard icon next to the device number below.

                    (The Wireguard App will prompt for a configuration file if
                    your device operating system or the Wireguard app offers a
                    user interface for wireguard, each device its own
                    configuration, click on the Copy icon near each device to
                    copy its configuration in your clipboard, you can then paste
                    and send the device configuration by email to a user or save
                    it to a file to load it into a server.)
                  </div>
                </div> */}

                {/* </div> */}
              </Box>
              {/* <Box> DNS</Box>
                  <Box>
                    <div className="tabContainer">
                      <div className="divTabTextContainer">

                              <div className="divTabTextGroup">
                                <div className="divTabTextLabel">Name server 1</div>
                                <div className="divTabTextEditLocked">{PocInfosState["dns_name_cust_ns1"]}</div>
                              </div>

                              <div className="divTabTextGroup">
                                <div className="divTabTextLabel">Name server 2</div>
                                <div className="divTabTextEditLocked">{PocInfosState["dns_name_cust_ns2"]}</div>
                              </div>

                              <div className="divTabTextGroup">
                              <div className="divTabTextLabel">Public IPv4 DNS</div>
                              <div className="divTabTextEditLocked">{PocInfosState["dns_name"]}</div>
                          </div>

                        </div>
                    </div>
                  </Box> */}


<Box
                display={
                  leftPaneLinkIndex && leftPaneLinkIndex === "appstore"
                    ? ""
                    : "none"
                }
              >
                <AppStore
                data={
                  PocInfosState["wg"] && PocInfosState["wg"]["peers"]
                    ? PocInfosState["wg"]["peers"]
                    : []
                }
                dns={PocInfosState["dns_name_cust"] ? PocInfosState["dns_name_cust"] : PocInfosState["dns_name"] }
                email={PocInfosState["contact_email"] ? PocInfosState["contact_email"] : "missing" }
                  // poc_rules_state={PocRulesState}
                  // poc_state={PocInfosState}
                ></AppStore>
              </Box>

              <Box
                display={
                  leftPaneLinkIndex && leftPaneLinkIndex === "apps"
                    ? ""
                    : "none"
                }
              >
                <AppRunners
                  poc_rules_state={PocRulesState}
                  poc_state={PocInfosState}
                ></AppRunners>
              </Box>

     
              <Box
                display={
                  leftPaneLinkIndex && leftPaneLinkIndex === "deploy"
                    ? ""
                    : "none"
                }
              >
                <Container


      padding="xs"
      margin="2px"
      margin-left="10px" 
      margin-right="10px"
      padding-left="10px"
      padding-right="10px"
      
      >
                  

                  <Divider orientation></Divider>
                  <Space h="sm"></Space>
                   <Text  h="xs">Deploy script</Text>
                   <Space h="sm"></Space>
<Card>
<Resizable  defaultSize={{
            
          }}>

<div style={{ height: "90vh", width: "100%" }}>


      <Paper> 
      <Code language="bash">{}</Code>
      <CopyBlock
      text={codeSampleCodeDeployWithCode}
      language={"bash"}
      showLineNumbers={true}
      wrapLines
      codeSampleCodeDeploy
    />
    </Paper>  
    </div>
      </Resizable>
    </Card>
     </Container>
              </Box>


              <Box
                display={
                  leftPaneLinkIndex && leftPaneLinkIndex === "xtrnet"
                    ? ""
                    : "none"
                }
              >
                <Container>
                  

                  <Divider orientation></Divider>
                  <Space h="sm"></Space>
                   <Space h="sm"></Space>
<Card>

      <Paper> 
      <Text  h="xs">XtrNet deployment here soon...</Text>

    </Paper>  
    </Card>
     </Container>
              </Box>


              <Box
                display={
                  leftPaneLinkIndex && leftPaneLinkIndex === "keys"
                    ? ""
                    : "none"
                }
              >


                <Container>
                  

                  <Divider orientation></Divider>
                  <Space h="sm"></Space>
                  <PasswordInput
                    placeholder=""
                    size="sm"
                    //withAsterisk
                    label="Logs API key"
                    description="Use this API Key for accessing logs"
                    value={PocInfosState.log_key}
                    onChange={(event) => console.log(event.currentTarget.value)}
                  />
 <Space h="sm"></Space>
                  <PasswordInput
                    placeholder=""
                    size="sm"
                    //withAsterisk
                    label="OpenAI API key"
                    description="Use this API Key for OpenAI API access"
                    value={PocInfosState.openai_api_key}
                    onChange={(event) => console.log(event.currentTarget.value)}
                  />
 <Space h="sm"></Space>
                  <PasswordInput
                    placeholder=""
                    size="sm"
                    //withAsterisk
                    label="Geo API key"
                    description="Use this API Key for Geo Location features"
                    value={PocInfosState.geo_key}
                    onChange={(event) => console.log(event.currentTarget.value)}
                  />
 <Space h="sm"></Space>
                   <Text  h="xs">Deploy key</Text>
                   <Space h="sm"></Space>
<Card>


<PasswordInput
                    placeholder=""
                    size="sm"
                    width={400}
                    // withAsterisk
                    label="Authentication token (JWT)"
                    description="Login information as JWT"
                    value={PocInfosState.static_deploy_init_jwt_key}
                    onChange={(event) => console.log(event.currentTarget.value)}
                  />



      {/* <Clipboard value={codeSampleCodeDeploy}>
        {({ copied, copy }) => (
          <Button onClick={copy}>{copied ? 'Copied!' : 'Copy to clipboard'}</Button>
        )}
      </Clipboard> */}


  {/*  <PasswordInput
                    placeholder=""
                    size="sm"
                    //withAsterisk
                    label="Private key"
                    description="Private key to generate JWT for login"
                    value={PocInfosState.static_deploy_init_jwt_key}
                    onChange={(event) => console.log(event.currentTarget.value)}
                  />*/}

  {/* <Text>Build script for any static web app </Text>
 <Code block>{staticdeploy_script}    
                </Code> */}
</Card>

                </Container>
              </Box>

              <Box
                display={
                  leftPaneLinkIndex && leftPaneLinkIndex === "logs"
                    ? ""
                    : "none"
                }
              >
                <Box>
                  <FullScreen handle={logScreen} onChange={reportChange}>
                    <div>
                      <Group>
                        <div
                          className="full-screenable-node"
                          style={{
                            width: "100%",
                          }}
                        >
                          <Group position="left">
                            {/* Text: */}
                            <>
                              {/* <Space h="sm"></Space> */}

                              <TextInput
                                description="Type your query here"
                                style={{ width: "85%", height: "30px" }}
                                label="Query"
                                value={logSearchKey}
                                onChange={(event) =>
                                  setLogSearchKey(event.currentTarget.value)
                                }
                              />
                            </>
                          </Group>

                          <Space h="xl" />

                          <Group left ta="left">
                            {/* Time:
<> */}
                            {/* <Space h="sm" ></Space> */}

                            <DateTimePicker
                              ml={0}
                              label="Start"
                              ta="left"
                              left
                              withSeconds
                              clearable
                              onChange={setStartDateTime}
                              valueFormat={"DD MMM YYYY hh:mm:ss A"}
                              inputformat={"YYYY-MM-DD HH:MI:SS"}
                              placeholder="Logs start date and time"
                              value={startDateTime}
                              maw={400}
                              mx="auto"
                            />

                            {/* <Space h="sm" /> */}

                            <DateTimePicker
                              ml={0}
                              ta="left"
                              left
                              withSeconds
                              label="End"
                              placeholder="Logs end date and time"
                              onChange={setEndDateTime}
                              value={endDateTime}
                              clearable
                              valueFormat={"DD MMM YYYY hh:mm:ss A"}
                              inputFormat={"YYYY-MM-DD HH:MI:SS"}
                              maw={400}
                              mx="auto"
                            />

                            {/* </> */}
                          </Group>

                          <Space h="sm"></Space>
                          <Group position="left">
                            <>
                              <Select
                                description="Lines"
                                style={{
                                  width: "80px",
                                  height: "30px",
                                  paddingBottom: "60px",
                                }}
                                label=""
                                value={maxLogLines ? maxLogLines : 100}
                                onChange={(value) => {
                                  setMaxLogLines(value);
                                }}
                                data={[
                                  { value: 100, label: "100" },
                                  { value: 200, label: "200" },
                                  { value: 400, label: "400" },
                                  { value: 600, label: "600" },
                                  { value: 800, label: "800" },
                                  { value: 1000, label: "1000" },
                                  { value: 2000, label: "2000" },
                                  { value: 3000, label: "3000" },
                                  { value: 5000, label: "5000" },
                                  { value: 10000, label: "10000" },
                                  { value: 15000, label: "15000" },
                                  { value: 20000, label: "20000" },
                                  { value: 100000, label: "100000" },
                                ]}
                              />

                              {/* </>
</Group>
<Group position="right">
<> */}
                              <Button
                                className=""
                                onClick={ async (event) => {
                                  // setLogURL ('');
                                  // setLogURL ('/api/log?pwd=' + PocInfosState.dns_name_cust + '&k=' + (PocInfosState.log_key ? encodeURI(PocInfosState["poc-id"] + PocInfosState.log_key) :'nokey'));
                                  event.preventDefault();
                                  const theLurl = await onGetLogURL();
                                  setLogURL(theLurl);
                                }}
                              >
                                  <Button
                                className=""
                                onClick={ async () => {
                                  // setLogURL ('');
                                  // setLogURL ('/api/log?pwd=' + PocInfosState.dns_name_cust + '&k=' + (PocInfosState.log_key ? encodeURI(PocInfosState["poc-id"] + PocInfosState.log_key) :'nokey'));
                                  // const theLurl = await onGetLogURL();
                                  // setLogURL(theLurl);
                                }}
                              ></Button>

                                <RiRefreshLine
                                  style={{
                                    color: "#7b7b7b",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                />
                              </Button>

                              

                              <Button className="" onClick={logScreen.enter}>
                                <RiFullscreenLine
                                  style={{
                                    color: "#7b7b7b",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                />
                              </Button>
                            </>
                          </Group>
                          <Resizable
                            defaultSize={{
                              width: "85%",
                              height: "1000",
                            }}
                          >
                            <LazyLog
                              height="auto"
                              width="auto"
                              enableSearch
                              url={
                                logURL ? logURL : "https://logs.setip.io/_logs/PROXY/0/0/4/4/1?k=nokey&f=line&s=y"
                                //logURL ? logURL : "https://setip.io/api/logs"
                              }
                            />
                          </Resizable>
                        </div>
                      </Group>
                    </div>
                  </FullScreen>
                </Box>
              </Box>
              {/* <Box>EMAIL</Box> */}
              <Box
                display={
                  leftPaneLinkIndex && leftPaneLinkIndex === "email"
                    ? ""
                    : "none"
                }
              >


                {showPopUp ? (
                  <PopUp
                    subject={JSON.stringify(selected ? selected.subject : {})}
                    date={JSON.stringify(selected ? selected.date : {})}
                    fr_email={JSON.stringify(selected ? selected.fr_email : {})}
                    to_email={JSON.stringify(selected ? selected.to_email : {})}
                    message_txt={JSON.stringify(selected ? selected.text : {})}
                    message_html={JSON.stringify(
                      selected ? selected.textAsHtml : {}
                    )}
                    attachments_count={JSON.stringify(
                      selected && selected.attachments
                        ? selected.attachments.length
                        : {}
                    )}
                    closePopup={togglePopup}
                  >
                    {" "}
                  </PopUp>
                ) : null}
                  <EmailTable
                    className="email_list"
                    loadEmails={onLoadEmail}
                    emails={PocEmailsState}
                    lastRefreshTimeEmail={LastRefreshTimeEmail}
                    updateCurrent={setCurrentMsg}
                    current={currentMsg ? currentMsg : {}}
                  ></EmailTable>
              </Box>
              {/*} <Box>Geo</Box>
          <Box>
            <div className="tabContainer">
            
              <GeoTable
                loadGeos= {loadGeos}
                toggleTrip = {toggleTrip}
                isTripToggled = {geoOpOn}
                geos={PocGeosState}
                poc_state={PocInfosState}
                LastRefreshTimeGeo={LastRefreshTimeGeo}
              >
              </GeoTable>
            </div>
          </Box>*/}
              {/* <Box> Pret-a-Porter</Box>
                  <Box>
                    <div className="tabContainer">
                    <div className="LandingFutureFeatureFrame">

          <LandingCenterBlock message='Pret-a-Porter are "ready to run" stacks, dressed up by the top world digital designers only!'/>
        <InlineButton name={'Show me!'} handleClick={handleShowWithDesign} ></InlineButton>
        </div>
                      </div>
                    </Box>    */}
              {/* Fine tune
                  <Box>
                  <div>
                    Network
                    <Tabs>
                      DNS
                      <Box>
                        <Box>Avanced DNS Management will be here.</Box>
                        <div></div>
                        Proxy
                        <Box>Avanced Proxy Management will be here.</Box>
                        <div></div>
                        SSL
                        <Box>Avanced SSL Management will be here.</Box>
                        <div></div>
                      </Box>

                    </Tabs>
                    </div>
                    Containers
                    <Tabs>
                    <Box>Manager</Box>
                      <Box>
                        <Box>Avanced DNS Management will be here.</Box>
                        Monitor
                        <Box>Avanced Proxy Management will be here.</Box>
                        Logs
                        <Box>Avanced SSL Management will be here.</Box>
                      </Box>
                    </Tabs>
                  </Box>
                  Devices
                  <Box>Device management will be here.</Box>
                  Account
                  <Box></Box> */}
          </div>
        )}

        {/*POC CREATION FORM*/}
        {(POCLoadingStatus === "creating" ||
          POCLoadingStatus === "notfound") && (
          <div>
             <Space h="lg" />
            <Modal
              opened={POCLoadingStatus === "notfound"}
              //size="auto"
              onClose={() => {}}
              title=""
              withCloseButton={false}
            >
              <div className="inlineForm">
                {/* <h2>Seeding location</h2> */}

                {/* <div></div>
        <h2>Free for students</h2>
        <h4>Just use your school email</h4>
        <h4>and pick a location on AWS!</h4>
        <div></div> */}

                {/* <div>{values["vendor_id"]}</div>
<div>{values.region}</div> */}

                <div className="inlineForm__notif">
                  {/* {error && <Error error="Error" />} */}
                </div>

                <Box sx={{ maxWidth: 380 }} mx="auto">
                  {/* <form
        onSubmit={ 
        form.onSubmit(  (values) =>  {
        //
        console.log("Hi");
        console.log(values);
        } ) }
      > */}

                  {/* <form onSubmit={handleRegister}> */}

                  {/*   <label>
          <Box>Start region</Box>
        
          <select
            name="region"
            className=" input"
            label="Primary region"
            value={values.region}
            onChange={handleOnRegionChange}
          >
            <OptionsRegions></OptionsRegions>
          </select>
        </label>*/}

                  {/* <Select
      label="Select a region"
      withAsterisk
      icon={<IconWorld size={14} />} 
      placeholder="Ex: Americas West"
      // searchable
      // onSearchChange={onSearchChange}
      // searchValue={searchValue}
      nothingFound="No options"
      data={regions_002}
      value={selectedWorldRegion} 
      onChange={(value) => {
        const tmpven =    _locations.filter(  (v) => {
          console.log(v.ipio_world_region + " " + value);
          if (v.ipio_world_region === value) { return true } else return false;
        });
        

        setFilteredVendors((tmpven && tmpven[0]) ? tmpven :  []);
        setSelectedVendor((tmpven && tmpven[0]) ? tmpven[0] :  null);
        // setUserEmail(event.currentTarget.value);
        //     console.log(event.currentTarget.value);
        //console.log(value);
        setSelectedWorldRegion(value);

      }}
      // {...form.getInputProps("region")}
    /> */}

                  {/* 
<Select
      label="Select a location"
      withAsterisk
      icon={<IconMap2 size={14} />} 

      placeholder="Ex: AWS West Oregon"
      //searchable
      // onSearchChange={onSearchChange}
      //searchValue={searchValue}
      // filter={(value, item) =>
      //               {

      //                 console.log(value.ipio_world_region);
      //                 console.log(selectedWorldRegion);
      //                       return true;
      //                 }
      // //   // item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
      // //   // item.description.toLowerCase().includes(value.toLowerCase().trim())


      // }
      nothingFound="No options"
      data={filteredVendors}
      value={selectedVendor} 
      onChange={setSelectedVendor}
      
    /> */}

                  {/* <h4>{POCLoadingStatus?POCLoadingStatus:"Status unknown"}</h4> */}

                  <Accordion defaultValue="customization">
                    <Accordion.Item value="customization"></Accordion.Item>
                  </Accordion>
                  
                  <Select
                    label="Choose operating mode"
                    default=""
                    withAsterisk
                    icon={<IconNetwork size={14} />}
                    data={op_modes}
                    value={opMode}
                    onChange={setOpMode}
                    placeholder="Proxy mode with Auto SSL and Auto DNS"
                    // searchable
                    // onSearchChange={onSearchChange}
                    // searchValue={searchValue}
                    nothingFound="No options"
                  />

                  <TextInput
                    label="Contact email (use same as for payment)"
                    value={userEmail}
                    withAsterisk
                    placeholder="youremail@domain.com"
                    icon={<IconAt size={14} />}
                    //onChange={setUserEmail}
                    onChange={(event) => {
                      setUserEmail(event.currentTarget.value);
                      //console.log(event.currentTarget.value);
                    }}
                  />

                  <TextInput
                    placeholder={
                      "Project name - max 20 characters - no special char."
                    }
                    label="Your project name"
                    icon={<IconAppWindow size={14} />}
                    value={projectName}
                    withAsterisk
                    onChange={(event) => {
                      setProjectName(event.currentTarget.value);
                      console.log(event.currentTarget.value);
                    }}
                    //name={"username"}
                    //autoComplete={"username"}

                    //{...form.getInputProps('username')}
                  />

                  {/* <FormInput
            type={"text"}
            placeholder={"Project name - max 20 characters - no special char."}
            name={"username"}
            autoComplete={"username"}
            value={values.username}
            handleChange={handleChange}
        /> */}

                  {/* <ConfirmPasswordInput
            type={"password"}
            placeholder={"Password"}
            placeholderConfirm={"Confirm password"}
            name={"password"}
            nameConfirm={"passwordConfirm"}
            value={values.password}
            valueConfirm={values.passwordConfirm}
            handleChange={handleChange}
        />
   */}
                  {/* <FormInput
            type={"text"}
            placeholder={"Optional code"}
            name={"Invite Code"}
            autoComplete={"SOMECODE"}
            value={values.invite}
            handleChange={handleChange}
          /> */}

                  <Group position="right" mt="md">
                    <h3>{POCRegistrationError}</h3>

                    <Button
                      style={{ display: POCLoadingStatus === "creating" }}
                      variant="filled"
                      onClick={(event) => {
                        event.preventDefault();
                        console.log(
                          "User ID: " +
                            pocketBase.authStore?.model.id +
                            " User email: " +
                            userEmail +
                            " Project name: " +
                            projectName +
                            " Region: " +
                            selectedWorldRegion +
                            " Op Mode: " +
                            opMode +
                            " Vendor: " +
                            selectedVendor
                        );

                        handleRegister({
                          username: projectName,
                          email: userEmail,
                          dns: "",
                          password: "",
                          passwordConfirm: "",
                          region: selectedWorldRegion,
                          vendor_id: selectedVendor,
                          invite: "",
                          op_mode: opMode,
                          st_userid: pocketBase.authStore?.model.id,
                        });
                      }}
                      //type="submit"
                      // color="#86d242"
                      color="lime"
                      uppercase
                      //gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}
                    >
                      Create
                    </Button>
                  </Group>
                  {/* <div className="inlineForm__submit">
            {/* <Link to="/st_auth">
              <Prompt prompt={"Existing account? Log in."} />
            </Link> 
            <CTA name={"create"} type={"submit"} />
          </div> */}

                  {/* </form> */}
                </Box>
              </div>
            </Modal>
          </div>
        )}
        {!isPocLoaded &&
          POCLoadingStatus &&
          POCLoadingStatus === "loading" &&
          Oldsession && (
            <div>
              {/*     
  <MyHeader /> */}
              <Modal
                opened={true}
                //onClose={() => {}}
                title="Loading PoC... Please be patient."
                withCloseButton={false}
                //size="auto"
              ></Modal>
            </div>
          )}

        {!isPocLoaded &&
          !(POCLoadingStatus === "loaded") &&
          !(POCLoadingStatus === "creating") &&
          !(POCLoadingStatus === "notfound") && (
            <div>
              {/* <h1>{POCLoadingStatus}</h1>
<h1>{POCRegistrationError}</h1> */}
              <Flex
                mih={50}
                // bg="rgba(0, 0, 0, .3)"
                gap="md"
                justify="center"
                align="center"
                // direction="row"
                // wrap="wrap-reverse"
              >
                {" "}
                <RiseLoader color="#6DD400" />
              </Flex>
            </div>
          )}
        {!isPocLoaded && POCLoadingStatus === "poccreating" && (
          <div style={{ justifyContent: "center" }}>
            <Text fz="md">
              Click "Deploy" to activate after receiving your welcome email...
            </Text>
            <Flex
              mih={50}
              // bg="rgba(0, 0, 0, .3)"
              gap="md"
              justify="center"
              align="center"
              // direction="row"
              // wrap="wrap-reverse"
            >
              {" "}
              <RiseLoader color="#6DD400" />
            </Flex>{" "}
          </div>
        )}

      
      </Container>
    );
  }
}
