
import { Box, NavLink,Navbar,ScrollArea,Text, Anchor } from "@mantine/core";
import InlineButton from "./InlineButton";
import { Link,useNavigate } from "react-router-dom";
import { FaCloud ,FaPencilAlt} from "react-icons/fa";
import { SiCloudflare } from "react-icons/si";
import Markdown from 'react-markdown'

import { FaHome, FaAppStore } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";

import {
  SiCurl,
  SiWireguard,
  SiServerfault,
  SiMailDotRu,
  SiJenkins,
  SiMicrosoftedge,
  SiDocker
} from "react-icons/si";

import { GiHouseKeys } from "react-icons/gi";




import { GoTerminal } from "react-icons/go";

import React, {
  useCallback,
  useContext,
  // FormInput,
  // Link,
  // Prompt,
  useEffect,
  useMemo,
  useState,
  CSSProperties,
} from "react";

import {
  useRecoilState,
} from "recoil";

import {
  leftPaneLinkIndexState,
  showLeftSideState,
  burgerPaneOpenedState,
  showManageSubState,
  navBarHiddenState,
  pocketBaseState,
  randomTokenState
} from "../recoil_state";


// const triggerSubscribe  = async (userId,pb) => {
//   debugger;
//     await pb.collection('users').subscribe(userId, function (e) {
//     console.log(e.action);
//     console.log(e.record);
// });
// }



export function LeftMenu() {
  const navigate = useNavigate();
  const [leftPaneLinkIndex, setLeftPaneLinkIndex] = useRecoilState(
    leftPaneLinkIndexState
  );
  const [randomToken,setRandomToken] = useRecoilState(randomTokenState);
  const [showManageSub, setShowManageSub] = useRecoilState(showManageSubState);
  const [pocketBase,setPocketBase] = useRecoilState(pocketBaseState);

  const [burgerPaneOpened, setBurgerPaneOpened] = useRecoilState(burgerPaneOpenedState);
  const [navBarHidden, setNavBarHidden] = useRecoilState(navBarHiddenState);
  

  // const { isLoading, error } = useAuth0();

  // const [activePage, setActivePage] = useState("urls");







  const result = (
    <>



    
      <Box >

      <Navbar
                p="md"
                hiddenBreakpoint="sm"
                hidden={!burgerPaneOpened }
                width={{ sm: 200, lg: 200 }}
              >

<Navbar.Section mt="xs">{/* Header with logo */}</Navbar.Section>

<Navbar.Section mt="xs">
  <Box 
  display={pocketBase.authStore?.isValid ? "" : "none"}
  >

<NavLink   

label={'Logout'}
sx={(theme) => ({
  backgroundColor:
    theme.colorScheme === "dark"
      ? theme.colors.dark[6]
      : theme.colors.gray[0],
  textAlign: "left",
  padding: theme.spacing.sm,
  borderRadius: theme.radius.md,
  cursor: "pointer",

  "&:hover": {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.gray[1],
  },
})}
        
onClick={async () =>   {
  try{
   
    setShowManageSub(false);
    pocketBase.authStore.clear(); 
    
  } catch (e){
    console.log("Error logging off: " + e.message)
  } finally {
   try {
    // window.location.href = "/";
    navigate("/login");
   } catch (e) {
     console.log("Error navigating to /: " + e.message);
    //  ("/");
  }
}
   
  
   }}   
   
   />


</Box>

<Box 
display={pocketBase.authStore?.isValid ? "none" : ""}
>


<NavLink   
label={'Sign up'}
sx={(theme) => ({
  backgroundColor:
    theme.colorScheme === "dark"
      ? theme.colors.dark[6]
      : theme.colors.gray[0],
  textAlign: "left",
  padding: theme.spacing.sm,
  borderRadius: theme.radius.md,
  cursor: "pointer",

  "&:hover": {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.gray[1],
  },
})}

onClick={async () =>   {

  const getRandomIntInclusive = async (min, max) => {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled); // The maximum is inclusive and the minimum is inclusive
  }

  try {
  //   const newRandomToken = await getRandomIntInclusive(1000000000000000, 9999999999999999);
  //   setRandomToken(newRandomToken);

  // let pb = new PocketBase('https://pb1.setip.io');

  // let authData = await pb.collection('users').authWithOAuth2({ provider: 'github' });

  //   const record = pb.collection('tokens').create({
  //     userid:pb.authStore.model?pb.authStore.model.id:null,
  //     access: newRandomToken
  //   } ).then((res) => {
  //    console.log(randomToken);
  //     console.log(res);
  //   });

  //   setPocketBase(pb);

  //   console.log(pocketBase);
  //   setShowManageSub(pocketBase.authStore?.isValid);
    navigate("SignUp");
    setBurgerPaneOpened(false);
    // setBurgerPaneOpened(false);
  // console.log(pb.authStore?.isValid?pb.authStore?.isValid:"Not valid pocketbase");
  // console.log(pb.authStore.token?pb.authStore.token:"Not valid pocketbase token");
  // console.log(pb.authStore.model?pb.authStore.model.id:"Not valid pocketbase model.id"); 

} catch (e) { console.log(e); } 

  }}  
/>

<NavLink   
label={'Login'}
sx={(theme) => ({
  backgroundColor:
    theme.colorScheme === "dark"
      ? theme.colors.dark[6]
      : theme.colors.gray[0],
  textAlign: "left",
  padding: theme.spacing.sm,
  borderRadius: theme.radius.md,
  cursor: "pointer",

  "&:hover": {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.gray[1],
  },
})}

onClick={async () =>   {

    navigate("login");

    setBurgerPaneOpened(false);



  // console.log(pb.authStore?.isValid?pb.authStore?.isValid:"Not valid pocketbase");
  // console.log(pb.authStore.token?pb.authStore.token:"Not valid pocketbase token");
  // console.log(pb.authStore.model?pb.authStore.model.id:"Not valid pocketbase model.id"); 


  }}  
/>

   </Box>

  </Navbar.Section>
<Navbar.Section mt="xs">
  

<NavLink
          display={pocketBase.authStore?.isValid ? "" : "none"}
          label="Manage"
          // description="Deployment URLs"
          onClick={() => {
            navigate("/home");
            setLeftPaneLinkIndex("url");
            setBurgerPaneOpened(false);
            // setOpened(false);
          }}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "left",
            padding: theme.spacing.sm,
            borderRadius: theme.radius.md,
            cursor: "pointer",
          
            "&:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[5]
                  : theme.colors.white,
            },
          })}
          // icon={<SiCurl />}
        />
      
</Navbar.Section>
<Navbar.Section grow component={ScrollArea}  
display={((pocketBase.authStore?.isValid)) ? "" : "none"} >

      
<Box >

        <NavLink
          label="URLs"
          description="Apps addresses"
          onClick={() => {
            
            setLeftPaneLinkIndex("url");
            setBurgerPaneOpened(false);
            // setOpened(false);
          }}
          icon={<SiCurl />}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "left",
            padding: theme.spacing.sm,
            borderRadius: theme.radius.md,
            cursor: "pointer",
          
            "&:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[5]
                  : theme.colors.white,
            },
          })}
        />

<NavLink
          label="App Store"
          description="Self hosted apps"
          onClick={() => {
            // setActivePage("url");
            setLeftPaneLinkIndex("appstore");
            setBurgerPaneOpened(false);

            // setOpened(false);
          }}
          icon={<FaAppStore />}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "left",
            padding: theme.spacing.sm,
            borderRadius: theme.radius.md,
            cursor: "pointer",
          
            "&:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[5]
                  : theme.colors.white,
            },
          })}
        />
<NavLink
          label="Offline deploy"
          description="Origin less"
          onClick={() => {
            setLeftPaneLinkIndex("deploy");
            setBurgerPaneOpened(false);
            // setOpened(false);
          }}
          icon={<FaCloud/>}
        />

<NavLink
          label="Origins deploy"
          description="Connect origins"
          onClick={() => {
            // setActivePage("url");
            setLeftPaneLinkIndex("network");
            setBurgerPaneOpened(false);

            // setOpened(false);
          }}
          icon={<FaHome />}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "left",
            padding: theme.spacing.sm,
            borderRadius: theme.radius.md,
            cursor: "pointer",
          
            "&:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[5]
                  : theme.colors.white,
            },
          })}
        />

<NavLink
          label="XtrNet"
          description="Crowd sourced deployments"
          onClick={() => {
            // setActivePage("url");
            setLeftPaneLinkIndex("xtrnet");
            setBurgerPaneOpened(false);

            // setOpened(false);
          }}
          icon={<FaUsers />}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "left",
            padding: theme.spacing.sm,
            borderRadius: theme.radius.md,
            cursor: "pointer",
          
            "&:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[5]
                  : theme.colors.white,
            },
          })}
        />
       
<NavLink
          label="Logs"
          description="Logs search"
          onClick={() => {
            // setActivePage("url");
            setLeftPaneLinkIndex("logs");
            setBurgerPaneOpened(false);

            // setOpened(false);
          }}
          icon={<GoTerminal />}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "left",
            padding: theme.spacing.sm,
            borderRadius: theme.radius.md,
            cursor: "pointer",
          
            "&:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[5]
                  : theme.colors.white,
            },
          })}
        />

        {/* <NavLink
          label="Apps"
          description="Manage Hosted apps"
          onClick={() => {
            // setActivePage("url");
            setLeftPaneLinkIndex("apps");
            setBurgerPaneOpened(false);

            // setOpened(false);
          }}
          icon={<GrApps />}
        /> */}
        
<NavLink
          label="DNS (Client) over HTTPS"
          description="Settings"
          onClick={() => {
            // setActivePage("url");
            setLeftPaneLinkIndex("dnsoverhttp");
            setBurgerPaneOpened(false);
        
            // setOpened(false);
          }}
          icon={<SiServerfault />}
        />

 <NavLink
          label="Keys"
          description="Access keys"
          onClick={() => {
            // setActivePage("url");
            setLeftPaneLinkIndex("keys");
            setBurgerPaneOpened(false);

            // setOpened(false);
          }}
          icon={<GiHouseKeys />}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "left",
            padding: theme.spacing.sm,
            borderRadius: theme.radius.md,
            cursor: "pointer",
          
            "&:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[5]
                  : theme.colors.white,
            },
          })}
        />

<NavLink
          label="DNS (Server)"
          description="More DNS power"
          onClick={() => {
            // setActivePage("url");
            setLeftPaneLinkIndex("dns");
            setBurgerPaneOpened(false);

            // setOpened(false);
          }}
          icon={<SiCloudflare />}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "left",
            padding: theme.spacing.sm,
            borderRadius: theme.radius.md,
            cursor: "pointer",
          
            "&:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[5]
                  : theme.colors.white,
            },
          })}
        />


<NavLink
          label="Email"
          description="Test mailboxes"
          onClick={() => {
            // setActivePage("url");
            setLeftPaneLinkIndex("email");
            setBurgerPaneOpened(false);

            // setOpened(false);
          }}
          icon={<SiMailDotRu />}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "left",
            padding: theme.spacing.sm,
            borderRadius: theme.radius.md,
            cursor: "pointer",
          
            "&:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[5]
                  : theme.colors.white,
            },
          })}
        />

<NavLink
          label="Whiteboard"
          description="Draw ideas"
          onClick={() => {
            // setActivePage("url");
            setLeftPaneLinkIndex("whiteboard");
            setBurgerPaneOpened(false);

            // setOpened(false);
          }}
          icon={<FaPencilAlt />}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "left",
            padding: theme.spacing.sm,
            borderRadius: theme.radius.md,
            cursor: "pointer",
          
            "&:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[5]
                  : theme.colors.white,
            },
          })}
        />
</Box>
</Navbar.Section>


<Navbar.Section grow component={ScrollArea}>


      <NavLink
          label="support"
          // description="Deployment URLs"
          onClick={() => {
            navigate("/support");
            // setLeftPaneLinkIndex("url");
            setBurgerPaneOpened(false);
            // setOpened(false);
          }}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "left",
            padding: theme.spacing.sm,
            borderRadius: theme.radius.md,
            cursor: "pointer",
          
            "&:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[5]
                  : theme.colors.white,
            },
          })}
          // icon={<SiCurl />}
        />


      {/* <NavLink
          label="Free"
          // description="Deployment URLs"
          onClick={() => {
            navigate("/urlyup");
            // setLeftPaneLinkIndex("url");
            setBurgerPaneOpened(false);
            // setOpened(false);
          }}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "left",
            padding: theme.spacing.sm,
            borderRadius: theme.radius.md,
            cursor: "pointer",
          
            "&:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[5]
                  : theme.colors.white,
            },
          })}
          // icon={<SiCurl />}
        /> */}

      <NavLink
          label="Price"
          // description="Deployment URLs"
          onClick={() => {
            navigate("/price");
            // setLeftPaneLinkIndex("url");
            setBurgerPaneOpened(false);
            // setOpened(false);
          }}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "left",
            padding: theme.spacing.sm,
            borderRadius: theme.radius.md,
            cursor: "pointer",
          
            "&:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[5]
                  : theme.colors.white,
            },
          })}
          // icon={<SiCurl />}
        />

<NavLink
          label="Podcasts"
          // description="Deployment URLs"
          onClick={() => {
            navigate("/podcasts");
            // setLeftPaneLinkIndex("url");
            setBurgerPaneOpened(false);
            // setOpened(false);
          }}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "left",
            padding: theme.spacing.sm,
            borderRadius: theme.radius.md,
            cursor: "pointer",
          
            "&:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[5]
                  : theme.colors.white,
            },
          })}
          // icon={<SiCurl />}
        />

<NavLink
          label="Mission"
          // description="Deployment URLs"
          onClick={() => {
            navigate("/mission");
            // setLeftPaneLinkIndex("url");
            setBurgerPaneOpened(false);
            // setOpened(false);
          }}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "left",
            padding: theme.spacing.sm,
            borderRadius: theme.radius.md,
            cursor: "pointer",
          
            "&:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[5]
                  : theme.colors.white,
            },
          })}
          // icon={<SiCurl />}
        />




{/* <NavLink
          label="Crowd funding"
          // description="Deployment URLs"
          onClick={() => {
            navigate("/crowdfundingphase1");
            // setLeftPaneLinkIndex("url");
            setBurgerPaneOpened(false);
            // setOpened(false);
          }}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "left",
            padding: theme.spacing.sm,
            borderRadius: theme.radius.md,
            cursor: "pointer",
          
            "&:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[5]
                  : theme.colors.white,
            },
          })}
          // icon={<SiCurl />}
        /> */}



<Anchor 

href="https://docs.setip.io/quickstart/quickstart/" target="_blank"
sx={(theme) => ({
  backgroundColor:
    theme.colorScheme === "dark"
      ? theme.colors.dark[6]
      : theme.colors.gray[0],
  textAlign: "left",
  padding: theme.spacing.sm,
  borderRadius: theme.radius.md,
  cursor: "pointer",

  "&:hover": {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.white,
  },
})}

>
      Quick Start
    </Anchor>

{/* 
<Anchor 

sx={(theme) => ({
  backgroundColor:
    theme.colorScheme === "dark"
      ? theme.colors.dark[6]
      : theme.colors.gray[0],
  textAlign: "left",
  padding: theme.spacing.sm,
  borderRadius: theme.radius.md,
  cursor: "pointer",

  "&:hover": {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.white,
  },
})}

href="https://stats.uptimerobot.com/6lOj7fV1KP" target="_blank">
      Uptime
</Anchor> */}



<Box>


</Box>


</Navbar.Section>
 </Navbar>
        {/* </Resizable> */}
  </Box>

    </>
  );

  

  return result;
}
