import React, { Text, useState,useContext,useEffect,useCallback } from 'react'
import {v4} from 'uuid';
import { keys } from '@mantine/utils';
import ReactTooltip from 'react-tooltip';
import { Accordion } from '@mantine/core';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { 

  authTupleState,
} from "../recoil_state";
import {
  IconSquareCheck,
  IconMailOpened,
  IconPackage,
  IconUsers,
  IconCalendar,
  IconChevronDown,
  IconDots,
  IconPlus,
  IconPrinter
} from '@tabler/icons';
import { Menu, Table, Button,useMantineTheme,createStyles,ActionIcon } from '@mantine/core';

// import BoolEditor from '@inovua/reactdatagrid-community/BoolEditor';
// import DateEditor from '@inovua/reactdatagrid-community/DateEditor';
// import NumericEditor from '@inovua/reactdatagrid-community/NumericEditor';
// import { PocContext } from './../hooks/PocContext';
import moveXBeforeY from './moveXBeforeY'
import sslON from '../assets/sslON.png'
import sslOFF from '../assets/sslOFF.png'
import onCircle from '../assets/OnCircle.png'
import offCircle from '../assets/OffCircle.png'
import followLnk from '../assets/icons8-chain.png'
import { Link } from 'react-router-dom';
import PopUp from "./../components/PopUp";
import "../App.css";

import {
  RiAddBoxFill,
  RiAddCircleFill,
  RiAddCircleLine,
  RiPlayListAddLine,
  RiMailOpenLine,
  RiRefreshLine,
  RiSaveLine,
} from "react-icons/ri";
{/*import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css';*/}

const useStyles = createStyles((theme) => ({
  button: {
    color: theme.white,
    backgroundColor: theme.colors.blue[6],
    border: 0,
    borderRadius: theme.radius.md,
    padding: `${theme.spacing.sm}px ${theme.spacing.lg}px`,
    cursor: 'pointer',
    margin: theme.spacing.md,

    // Use pseudo-classes just like you would in Sass
    '&:hover': {
      backgroundColor: theme.colors.blue[9],
    },

    '&:not(:first-of-type)': {
      backgroundColor: theme.colors.violet[6],

      // pseudo-classes can be nested
      '&:hover': {
        backgroundColor: theme.colors.violet[9],
      },
    },
  },
}));

function CustomToolbar() {
  return (
    <span></span>
    // <GridToolbarContainer>
    //   <GridToolbarExport />
    // </GridToolbarContainer>
  );
}

const gridStyle = { 
  
  //minWidth: 100, 
  height: "40vh", 
  width: "100%",
  marginTop: 20 ,
  
  backgroundColor: "#fcf7f3"
};



export default function EmailTable(props) {
     const [dataSource,setDataSource] = React.useState([]);

    // const [emailSource,setEmailSource] = React.useState(null);
    const [lastEdit, setLastEdit] = useState(null);
    const {lastRefreshTimeEmail, current, updateCurrent, emails, loadEmails} = props;
    const [gridRef, setGridRef] = useState(null);
    const [showPopUp, setShowPopUp] = useState(false);
   const [selected, setSelected] = useState({});
   const [multiSelect, setMultiSelect] = useState(true);

    const [currentMsgIndex, setcurrentMsgIndex] = useState({});

  const theme = useMantineTheme();
    const getColor = (color) => theme.colors[color][theme.colorScheme === 'dark' ? 5 : 7];

    const onMsgIndexChange = useCallback(({ index }) => {
      setcurrentMsgIndex(index)
    }, [])
  
    const onSelectionChange = useCallback(({ selected }) => {
      //setcurrentMsgIndex(selected)
      
      //setSelected(selected);
      //console.log(selected);
       updateCurrent(selected);
    }, [])
 
 const [autoRefresh, setAutoRefresh] = React.useState(false);
    
    const handleAutoRefreshChange = (wantIt) => {
      console.log("refresh change request: "+ wantIt);
      setAutoRefresh(wantIt);
    };

    const onEditComplete = useCallback(
      ( { value, columnId, rowIndex } ) => {
        console.log("on edit complete");
          // const data = [...dataSource];
          // data[rowIndex][columnId] = value;
          // setDataSource(data)
      }, 
      [
        //dataSource
      ]
      )

    // const onRowReorder = useCallback(({ data, dragRowIndex, insertRowIndex }) => {
    //   let newData = moveXBeforeY([...dataSource], dragRowIndex, insertRowIndex);
  
    //   setDataSource(newData);
    // }, [dataSource])

   useEffect( () =>  { 
    //console.log("Use effects");
   //console.log("emails: " + JSON.stringify(emails.mailbox.data));
    if (! emails || !emails.ready) {
        console.log("Mailbox not ready.");
    } else {
      console.log("Mailbox ready, showing last " + emails.mailbox.data.length + " received.");
      if (emails.mailbox.data.length >0){
            setDataSource(emails.mailbox.data);
            console.log("email datasource set.");
            //console.log("Mailbox ready status: "  + emails.ready);
      }
      
       
      //  console.log(emails.ready ? emails.mailbox.data : "Mailbox not ready. Nothing to display.");
      //  setEmailSource(emails.mailbox.data);
      //   if ((gridRef) && (rules.length>0) && (rules[rules.length-1].target === "")) {
      //       gridRef.current.startEdit({ rowIndex: 2, columnId: 'name' })
      //   }
    }
  },[emails]);

   async function togglePopup() {
    setShowPopUp(!showPopUp);
  }

    const [activateRowOnFocus, setActivateRowOnFocus] = useState(true);

//     const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
//       menuProps.autoDismiss = false;
// 
//       menuProps.items = [
//    
//         {
//           key: "1",
//           label: 'Open email...' //+ (cellProps.id === 'city'? rowProps.data.city : rowProps.data.country) + '?'
//         }
//       ];
//       //updateCurrent(rowProps.data);
//       //togglePopup();
// 
//     }
    async function addToRuleBuffer() {

      // const localBuffer = props.rules;
      // localBuffer.unshift({
      //   ssl: "",
      //   host: "",
      //   target: "",
      //   filter: "",
      // });
      
      // props.setRules(localBuffer);
      // const index = 0;
      // //gridRef.refresh();
      
      // const selected = gridRef.current.isRowSelected(index)
      // gridRef.current.focus()
      // gridRef.current.setRowSelected(index, !selected)
      
    }

    // const mailsource = emails &&  
    //           emails.mailbox &&  
    //           emails.mailbox.data &&  
    //           (emails.mailbox.data.length > 0) && 
    //           Array.isArray(emails.mailbox.data)  ?
    //           emails.mailbox.data  :
    //           []

 if (dataSource && Array.isArray(dataSource) ){
   const rows = dataSource.map((element) => (
     
     <tr key={v4()}>
     

      
      
     <td> 
     <span >
     
     <Accordion
         chevron={<IconPlus size={16} />}
         styles={{
           chevron: {
             '&[data-rotate]': {
               transform: 'rotate(45deg)',
             },
           },
         }}
       >
         <Accordion.Item value="print">
         {element.fromName}
      <Accordion.Control icon={<IconMailOpened size={1} color={getColor('red')} />}>
      </Accordion.Control>
        <Accordion.Panel>
        
{element.text?element.text:element.textAsHtml}
              
        </Accordion.Panel>
      </Accordion.Item>
       </Accordion>  
    
     
     </span>
     </td>
     
      <td> <span>{element.date}</span> </td>
          <td ><span>{element.subject}</span> </td>   
              <td> {element.toName} {element.toEmail} </td>
   
   

     </tr>

   ));
  //if (true){
    return (
        <div>
        <ReactTooltip effect="solid" /> 
        
        <span><span>{autoRefresh?"Auto refresh off":"Auto refresh on"}</span></span>
<span><span> - Last refresh: {props.lastRefreshTimeEmail}</span></span>
        <div>
          {/*<ToggleSwitch Name='AutoRefreshEmail' onChange={checked => handleAutoRefreshChange(checked)}/>*/}
          
        </div>
        
        <div className="buttonBarForRules">
       {/* <button className="" onClick={addToRuleBuffer}>
          <RiAddCircleFill
            style={{ color: "#7b7b7b", width: "30px", height: "30px" }}
          />
        </button> */}
        
                {/* <button className="" onClick={props.savePoc}>
          <RiSaveLine
            style={{ color: "#7b7b7b", width: "30px", height: "30px" }}
          />
        </button> */}
        
        <button className="" onClick={async (event)=>{
          event.preventDefault();
          await props.loadEmails();
        }
          
          }>
          <RiRefreshLine
            style={{ color: "#7b7b7b", width: "30px", height: "30px" }}
          />
        </button>
        

       {/* <button className="" onClick={togglePopup}>
          <RiMailOpenLine
            style={{ color: "#7b7b7b", width: "30px", height: "30px" }}
          />
        </button>*/}
        
        <div> 
  
</div>

        </div>

        {showPopUp ?
        <PopUp 
        subject={JSON.stringify(selected?selected.subject:{})} 
        date={JSON.stringify(selected?selected.date:{})} 

        fr_email={JSON.stringify(selected?selected.fr_email:{})} 
        to_email={JSON.stringify(selected?selected.to_email:{})} 
        message_txt={JSON.stringify(selected?selected.text:{})} 
        message_html={JSON.stringify(selected?selected.html:{})}
        attachments_count={JSON.stringify(selected && selected.attachments ?selected.attachments.length:{})}
        closePopup={togglePopup} > </PopUp> :
        null
      }
  <p></p>
  
   <div className="TableCenterBlockUnFramed">

  <Table>
    <thead>
        <tr>
 
        <th><h3>From</h3></th>
        <th><h3>Date</h3></th>
        <th><h3>Subject</h3></th>
        <th><h3>To</h3></th>
       </tr>
    </thead>
    <tbody>
    {rows}
    </tbody>
  </Table>
  
 {/*
  <DataGrid  hideFooter={true}
  components={{ Toolbar: CustomToolbar }} 
   onRowClick={
     (params: GridRowParams) => {
       setcurrentMsgIndex(params.id);
       updateCurrent(dataSource[params.id]);
       //console.log("ll clicked: " + params.id);
      // onSelectionChange(4)
     }
     }
     editable={false}
   //checkboxSelection={true} 
   experimentalFeatures={{ newEditingApi: true }}
   rows={dataSource?dataSource:[]} 
   
   columns={columnsX} 
   />
   */}
</div>
  
  {/*
        <ReactDataGrid
            idProperty="idEmails"
            style={gridStyle}
            // onReady={setGridRef}
            // activateRowOnFocus={setActivateRowOnFocus}
            columns={columns}
            dataSource={ dataSource?dataSource:[] }
            // onActiveIndexChange={onMsgIndexChange}
            onEditComplete={onEditComplete}
            onEditStart={setLastEdit}
            enableKeyboardNavigation={true}
           enableSelection={true}
           onSelectionChange={onSelectionChange}
            //defaultActiveIndex={1}
            selected={selected}
            //onRowReorder={onRowReorder}
            editable={true}
           renderRowContextMenu={renderRowContextMenu}
            // rowReorderColumn={true}
            //rowsCount={0}
        />
        */}
        
     
        {/* const edit = lastEdit ?
    <p>
      Last started edit: column - {lastEdit.columnId}; row = {lastEdit.rowIndex}
    </p> : null */}
        </div>
    );
  }  else {
    
    return (
      <>
              <div className="buttonBarForRules">
       {/* <button className="" onClick={addToRuleBuffer}>
          <RiAddCircleFill
            style={{ color: "#7b7b7b", width: "30px", height: "30px" }}
          />
        </button> */}
        
                {/* <button className="" onClick={props.savePoc}>
          <RiSaveLine
            style={{ color: "#7b7b7b", width: "30px", height: "30px" }}
          />
        </button> */}
        
        <button className="" onClick={ async () => {
          await props.loadEmails()
        }}>
          <RiRefreshLine
            style={{ color: "#7b7b7b", width: "30px", height: "30px" }}
          />
        </button>
        </div>
        <div className="page" style={{ justifyContent: "center" }}>
          <div id="orbiting"></div>
          <h3>No emails waiting for this domain.</h3>

        </div>
      </>
    );
  }
  }

