import React from "react";
// import { useAuth0 } from "@auth0/auth0-react";

const Loading = () => {

  return(
    <div className="loading">
        <span className="pulse"></span>
    </div>
)};

export default Loading;

