import React, { useEffect, useState,useRef } from 'react';
import axios from 'axios';
import { Excalidraw,serializeAsJSON } from '@excalidraw/excalidraw';
import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from "recoil";
  import { 
    leftPaneLinkIndexState, 
    showLeftSideState,
    showManageSubState,
    pocketBaseState ,
    randomTokenState,
    authTupleState,
  } from "../recoil_state";
const Excali = () => {

    const [initData, setInitData] = useState({ elements: [], appState: {}, files: {} });
    const [pocketBase,setPocketBase] = useRecoilState(pocketBaseState);
    const [randomToken,setRandomToken] = useRecoilState(randomTokenState);
    const excalidrawRef = useRef(null);
    const [excalidrawAPI, setExcalidrawAPI] = useState(null);

    useEffect(() => {
      
        let excalidraw_saved_session="";
        console.log("Getting excalidraw_saved_session");

      axios
        .get("/poc/getter/excalidraw_saved_session",
        {
            headers: {
                authentication: "Bearer " + 
                (pocketBase.authStore.model?pocketBase.authStore.model.id:null)
                + ":" + 
                randomToken
            }
        }
    )
        .then(async (data) => {
          //await setUserContext();
          //setGeoOpOn(!geoOpOn);
        //   debugger;
          console.log("Got excalidraw_saved_session ");
          if (data) {
            console.log('Loading drawing');
            setInitData(data.data["excalidraw_saved_session"]);
          } else {
              //setInitData(JSON.stringify({ elements: [], appState: {}, files: {} }));
          }
        })
        .catch((err) => {
            // debugger;

          console.log("Error getting excalidraw_saved_session"  + " to: " + err.message);
        });


        // const savedData = localStorage.getItem('excalidrawData');
        // if (savedData) {
        //   console.log('Loading drawing:', savedData);
        //   setInitData(JSON.stringify(savedData));
        // } else {
        //     setInitData(JSON.stringify({ elements: [], appState: {}, files: {} }));
        // }
      }, [
        
      ]);

  const handleChange = (elements, appState, files) => {
    if (excalidrawRef.current){
    const json_elements = JSON.stringify(excalidrawRef.current.getSceneElements());
    const json_appState = JSON.stringify(excalidrawRef.current.getAppState());
    const json_files=JSON.stringify(excalidrawRef.current.getFiles())

    console.log('Elements:', json_elements);
    console.log('AppState:', json_appState);
    console.log('Files:', json_files);
    const data = { json_elements, json_appState, json_files };


    axios
    .post("/poc/setter", {
      key: "excalidraw_saved_session",
      value: data,
    },
    {
        headers: {
            authentication: "Bearer " + 
            (pocketBase.authStore.model?pocketBase.authStore.model.id:null)
            + ":" + 
            randomToken
        }
    }
)
    .then(async () => {
      //await setUserContext();
      //setGeoOpOn(!geoOpOn);
      console.log("Setting excalidraw_saved_session " + "  to: " + JSON.stringify(data));
    })
    .catch((err) => {
      console.log("Error setting excalidraw_saved_session"  + " to: " + data);
    });
  };
}

  return (
    <Excalidraw
      ref={excalidrawRef}
      excalidrawAPI={(api) => setExcalidrawAPI(api)}
      initialData={initData} 
      onChange={handleChange}
      viewModeEnabled={false}
      zenModeEnabled={false}
      gridModeEnabled={true}
      theme="light"
      name="My Drawing"
    />
  );
};

export default Excali;
