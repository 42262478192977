import React from 'react';
import LandingCenterBlock from '../components/LandingCenterBlock';
import Prompt from './../components/Prompt';
import ReactPlayer from 'react-player/youtube';
import { MantineProvider, Text } from '@mantine/core';
import { AppShell, Navbar, Header, Aside, Footer } from '@mantine/core';

import '../App.css';
export default function AskGPT() {

    return(

 
<Text>Hello</Text>
 
 

     
        );
        
    }