import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";

import "../App.css";
// import { Auth0Provider,useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from "./Loading";

import * as Sentry from "@sentry/react";
import { MantineProvider, Global,LoadingOverlay } from "@mantine/core";



import {

  burgerPaneOpenedState,
  pocketBaseState
} from "../recoil_state";


import {
  AppShell,
  Navbar,
  Box,
  Header,
  Aside,
  Footer,
  NavLink,
} from "@mantine/core";

import { MediaQuery, Burger, useMantineTheme } from "@mantine/core";

import { Notifications } from "@mantine/notifications";


import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import InlineButton from "./InlineButton";

import {
  useRecoilState,
} from "recoil";

import NewFooter from "./NewFooter";
import { LeftMenu } from "./LeftMenu";
import setipIoLogoFull from "../assets/setipIoLogoFull@3x.png";
import Product from "../pages/Product";
import Mission from "../pages/Mission";
import Landing from "../pages/Landing";
import Price from "../pages/Price";
import UrlyUp from "../pages/UrlyUp";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import Support from "../pages/Support";
import OnBoarding from "../pages/OnBoarding";
import ServiceAgreement from "../pages/ServiceAgreement";
import PrivacyPolicy from "../pages/privacypolicy";
import Podcast from "../pages/Podcast";
import Learn from "../pages/Learn";

import AskGPT from "../pages/AskGPT";
import Selfhosted from "../pages/SelfHosted";
import UseCases from "../pages/UseCases";
import CrowdFundingPhase1 from "../pages/CrowdFundingPhase1";

import { useState } from "react";

import DemoRequest from "../pages/DemoRequest";


import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
// import useFindUser from "./hooks/useFindUser";
import CookieConsent from "react-cookie-consent";


import PocketBase from 'pocketbase';



export function SetIPIORoot() {




  // const [showLeftSide, setShowLeftSide] = useRecoilState(showLeftSideState);

  const theme = useMantineTheme();


  const [burgerPaneOpened, setBurgerPaneOpened] = useRecoilState(burgerPaneOpenedState);
 
  

  return (
    <>
    <Router>
      <AppShell
        styles={{
          main: {
            background:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.white,
          },
        }}

        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        navbar={
          //window.location.pathname.indexOf("/home") >= -1 &&
         
            <Box>


                 <LeftMenu />

                
             
            </Box>
        
        }
        // aside={
        //   sessionContext.doesSessionExist && (
        //     <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        //       <Aside p="md" hiddenBreakpoint="sm" width={{ sm: 200, lg: 300 }}>
        //         {/* <Text>Application sidebar</Text> */}
        //       </Aside>
        //     </MediaQuery>
        //   )
        // }
        footer={
          <Footer height={60} p="md">
            <NewFooter />
          </Footer>
        }

        header={
          <Header height={{ base: 50, md: 70 }} p="md">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Burger
                  opened={burgerPaneOpened}
                  onClick={() => {
                    setBurgerPaneOpened((o) => !o);
                  }}
                  // onClick={toggle}
                  size="sm"
                  color={theme.colors.gray[6]}
                  mr="xl"
                />
              </MediaQuery>

              <img
                className="setipIoLogoFull"
                alt="logo"
                width="200"
                height="50"
                src={setipIoLogoFull ? setipIoLogoFull : ""}
              />

            </div>

           
          </Header>

       
        }

        
      >
        <MantineProvider
          theme={{
            
            breakpoints: {
              xs: "30em",
              sm: "48em",
              md: "64em",
              lg: "74em",
              xl: "90em",
            },
          
            colors: {
            
              "setip-green": [
                "#E9EDFC",
                "#C1CCF6",
                "#99ABF0" /* 7 other shades */,
              ],
              blue: ["#E9EDFC", "#C1CCF6", "#99ABF0" /* 7 other shades */],
            },
            Button: (theme) => ({
              light: { color: theme.colors.gray[8] }
            }),
            fontFamily: "Verdana, sans-serif",
            fontFamilyMonospace: "Monaco, Courier, monospace",
            headings: { fontFamily: "Greycliff CF, sans-serif" },
            spacing: { xs: 15, sm: 20, md: 25, lg: 30, xl: 40 },
            fontSizes: {
              xs: "0.6rem",
              sm: "0.75rem",
              md: "0.9rem",
              lg: "1rem",
              xl: "1.2rem",
            },
          }}
        >
          <Notifications />
 
         
            <CookieConsent
           
              location="none"
              buttonText="Accept"
              cookieName="setipo"
              style={{ color: "#f8f9fa", background: "#3b225c" }}
              buttonStyle={{ color: "#000000", fontSize: "13px" }}
              expires={150}
            >
              This site uses cookies to enhance the user experience.{" "}
              <span style={{ fontSize: "10px" }}>
                That's just in case you don't like cookies
              </span>
            </CookieConsent>

            {/* <UserContext.Provider
             value={{ user, setUser, isLoading, onToggleSidebar }}
            */}

            <Routes>
              {/* <Route path="/users" element={<ProtectedHome />} /> */}
              <Route exact path="/" element={<Landing />} />
           
              {/* <Route path='/login/callback' Component={LoginCallback} /> */}


              <Route path="/mission" element={<Mission />} />
              <Route path="/product" element={<Product />} />
              <Route path="/serviceagreement" element={<ServiceAgreement />} />
              <Route path="/podcasts" element={<Podcast />} />
              <Route path="/onboarding" element={<OnBoarding />} />
              <Route path="/urlyup" element={<UrlyUp />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />

              {/* <Route path="/afternewauth" element={<AfterNewAuth />} /> */}

              <Route path="/selfhosted" element={<Selfhosted />} />
              {/* <Route path="/regcomplete" element={<RegComplete/>} /> */}
              <Route path="/price" element={<Price />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/demorequest" element={<DemoRequest />} />
              <Route path="/usecase" element={<UseCases />} />
              <Route
                path="/crowdfundingphase1"
                element={<CrowdFundingPhase1 />}
              />
              <Route path="/support" element={<Support />} />
              <Route path="/learn" element={<Learn />} />
              <Route path="/askgpt" element={<AskGPT />} />

              <Route
                index
                path="/home"
                element={
                 
                    <Home />
                }
              />

              <Route component={NotFound} />
            </Routes>

            {/* <Outlet /> */}

        
         
        </MantineProvider>
       
      </AppShell>

      </Router>
    </>
  );

}
