import React, { useEffect, useState } from 'react';
import { Container, createStyles, Grid, Card, Text, Button, TextInput, Flex, ActionIcon } from '@mantine/core';
import { CopyButton, Space } from '@mantine/core';
import { FiInfo } from 'react-icons/fi';

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    minHeight: 650,
  },
  card: {
    padding: theme.spacing.md,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.sm,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    margin: theme.spacing.sm,
    marginTop: theme.spacing.lg,
  },
  emoji: {
    fontSize: 24,
  },
  appTitle: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 700,
    color: theme.colors.dark[9],
    marginBottom: theme.spacing.sm,
  },
  appMore: {
    fontSize: theme.fontSizes.md,
    fontWeight: 500,
    color: theme.colors.dark[9],
    marginBottom: theme.spacing.sm,
  },
  input: {
    marginTop: theme.spacing.sm,
  },
  actionIcon: {
    backgroundColor: theme.white,
    borderRadius: theme.radius.sm,
    boxShadow: theme.shadows.xs,
  },
}));

export default function AppStore({ data, dns, email }) {
  const { classes } = useStyles();
  const [apps, setApps] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchApps = async () => {
      try {
        const response = await fetch("https://setip.io/api/poc/apps/scrtmpl");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const jsonData = await response.json();
        const appsWithPeers = jsonData.map((app) => ({ ...app, current_peer: null }));
        setApps(appsWithPeers);
      } catch (error) {
        console.error("Error fetching apps:", error);
        setError("Failed to load apps. Please try again later.");
      }
    };

    fetchApps();
  }, []);

  const handlePeerNumberChange = (appId, value) => {
    const numValue = parseInt(value, 10);
    setApps((prevApps) =>
      prevApps.map((app) =>
        app.id === appId
          ? { ...app, current_peer: isNaN(numValue) ? null : numValue }
          : app
      )
    );
  };

  const getScriptForPeer = (app,dns,email) => {

    if (!app.current_peer || app.current_peer <= 2) {
      return "Please enter a valid peer number greater than 2.";
    }

    const peerIndex = app.current_peer - 2;
    if (!data?.[peerIndex]?.conf?.wgInterface?.address) {
      return "Invalid peer configuration. Please check the peer number.";
    }

    const mergedDesc=app.description.replaceAll(
      "[ipAddress]",
      data[peerIndex].conf.wgInterface.address
    ).replaceAll(
      "[dns]",
      dns
    ).replaceAll(
      "[email]",
      email
    );

    return mergedDesc
  };

  if (error) {
    return (
      <Container size="lg">
        <Text color="red">{error}</Text>
      </Container>
    );
  }

  return (
    <Container size="lg" className={classes.wrapper}>
      <Grid gutter="xl">
        {apps.map((app) => (
          <Grid.Col key={app.id} xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <Flex align="center" justify="space-between">
                <Text className={classes.emoji}>{app.emoji}</Text>
                {app.info_url && (
                  <ActionIcon
                    component="a"
                    href={app.info_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="light"
                    color="blue"
                    className={classes.actionIcon}
                  >
                    <FiInfo size={20} />
                  </ActionIcon>
                )}
              </Flex>
              <Text className={classes.appTitle}>{app.value}</Text>
              <Space h="sm" />
              <Text className={classes.appMore}>{app.more}</Text>
              <Flex align="center" className={classes.input}>
                <Text>To Peer #</Text>

                <TextInput
                  type="number"
                  min="3"
                  placeholder="Enter peer #"
                  value={app.current_peer || ''}
                  onChange={(event) =>
                    handlePeerNumberChange(app.id, event.target.value)
                  }
                  style={{ marginLeft: "8px", flex: 1 }}
                />
              </Flex>
              <CopyButton value={getScriptForPeer(app,dns,email)}>
                {({ copied, copy }) => (
                  <Button
                    variant="light"
                    color={copied ? "teal" : "blue"}
                    onClick={copy}
                    style={{ marginTop: "1rem" }}
                  >
                    {copied ? "Copied script" : "Copy script"}
                  </Button>
                )}
              </CopyButton>
            </Card>
          </Grid.Col>
        ))}
      </Grid>
    </Container>
  );
}
