import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../hooks/UserContext";
import LandingCenterBlock from "../components/LandingCenterBlock";
import Prompt from "./../components/Prompt";
import { showLeftSideState } from "../recoil_state";
import { ActionIcon } from "@mantine/core";
import { IconDog, IconCode, IconJewishStar, IconArrowRight, IconArrowLeft } from '@tabler/icons-react';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import {
  Container,
  Title,
  Box,
  createStyles,
  Paper,
  rem,
  Text,
} from "@mantine/core";
import { useRef } from "react";
import Autoplay from "embla-carousel-autoplay";
import { Carousel } from "@mantine/carousel";

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    minHeight: 650,
  },
  title: {
    marginBottom: theme.spacing.xl * 1.5,
  },
  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

export default function Landing() {
  const { classes } = useStyles();
  const [showLeftSide, setShowLeftSide] = useRecoilState(showLeftSideState);

  useEffect(() => {
    setShowLeftSide(true);
  }, []); // Add empty dependency array

  const autoplay = useRef(Autoplay({ delay: 7000 }));

  return (
    <Container size="sm" className={classes.wrapper}>
      <Box>
        <Box>
          <Carousel
            maw={620}
            mx="auto"
            loop
            withIndicators
            height={450}
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
            nextControlIcon={<IconArrowRight style={{ width: rem(25), height: rem(25) }} />}
            previousControlIcon={<IconArrowLeft style={{ width: rem(25), height: rem(25) }} />}
            slideSize="100%"
            slideGap="md"
          >

<Carousel.Slide>
              <Paper ml="auto" mr="auto" mb="md">
                <Title ta="center">We are building the People's Internet</Title>
                <Text ta="center">
                  by letting billions of people take the Internet back from the few.
                </Text>
              </Paper>
            </Carousel.Slide>
            
            <Carousel.Slide>
              <Paper ml="auto" mr="auto" mb="md">
                <Title ta="center">Self host your Saas</Title>
                <Text ta="center">
                  Deploy your apps and APIs from in-house servers or private clouds. 
                  Start saving on cloud costs. Just like the cloud but with cost control.
                </Text>
              </Paper>
            </Carousel.Slide>
            <Carousel.Slide>
              <Paper ml="auto" mr="auto" mb="md">
                <Title ta="center">Run AI on-premises</Title>
                <Text ta="center">
                  Connect a local host to your account, install Mistral AI.
                  Let anyone in your organization access Mistral AI, securely, from the web.
                </Text>
              </Paper>
            </Carousel.Slide>
            <Carousel.Slide>
              <Paper ml="auto" mr="auto" mb="md">
                <Title ta="center">Up your game</Title>
                <Text ta="center">
                  Start your gaming company from your gaming PC. Help your peers hyperscale.
                </Text>
              </Paper>
            </Carousel.Slide>
            <Carousel.Slide>
              <Paper ml="auto" mr="auto" mb="md">
                <Title ta="center">Mighty Secured URLs for developers</Title>
                <Text ta="center">
                  Get permanent URLs to publish Apps and APIs that run on/off
                  your dev laptop, in a container, and staging or production.
                </Text>
              </Paper>
            </Carousel.Slide>
            <Carousel.Slide>
              <Paper>
                <Title ta="center">Run code on top of your URLS</Title>
                <Text ta="center">
                  Upload code directly to your URL for 24/24 availability.
                </Text>
                <Text ta="center">
                  Run in house first, on the cloud second and save.
                </Text>
              </Paper>
            </Carousel.Slide>
            <Carousel.Slide>
              <Paper>
                <Title ta="center">Automatic TLS Certificates</Title>
                <Text ta="center">
                  URLs are TLS secured with your own Let's Encrypt certificate.
                  You only need to expose http ports, https takes over instantly.
                </Text>
              </Paper>
            </Carousel.Slide>
            <Carousel.Slide>
              <Paper>
                <Title ta="center">Automatic DNS server</Title>
                <Text ta="center">
                  DNS entries are immediately updated for you to reflect
                  your URLs names, no need to mess around with DNS.
                </Text>
              </Paper>
            </Carousel.Slide>
            <Carousel.Slide>
              <Paper>
                <Title ta="center">Non-stop vendor swap</Title>
                <Text ta="center">
                  Move your containers and workloads, without downtime, anytime,
                  to never suffer from cloud vendor-lock again.
                </Text>
              </Paper>
            </Carousel.Slide>
            <Carousel.Slide>
              <Paper>
                <Title ta="center">Public IP to localhost</Title>
                <Text ta="center">
                  Use Transparent mode to assign a fixed and static IP address when
                  none available. Enable direct TCP/IP and UDP access to any device.
                </Text>
              </Paper>
            </Carousel.Slide>
            <Carousel.Slide>
              <Paper>
                <Title ta="center">Crowd powered DNS</Title>
                <Text ta="center">
                  Crowd powered DNS infrastructure makes it harder for targeted dos
                  attacks on a swarm of IP addresses.
                </Text>
              </Paper>
            </Carousel.Slide>
            <Carousel.Slide>
              <Paper>
                <Title ta="center">Secure access to URLs and paths</Title>
                <Text ta="center">
                  Limit access to your URLs from selected source IP addresses only.
                </Text>
              </Paper>
            </Carousel.Slide>
            <Carousel.Slide>
              <Paper>
                <Title ta="center">Access databases securely over the Internet</Title>
                <Text ta="center">
                  Use open source databases off your own machines. Simply restrict access to your app only
                  to databases available directly through the Internet.
                </Text>
              </Paper>
            </Carousel.Slide>
            <Carousel.Slide>
              <Paper>
                <Title ta="center">Fully tracing logs</Title>
                <Text ta="center">
                  Full tracing logs. Trace all connections from DNS resolution to your code.
                </Text>
              </Paper>
            </Carousel.Slide>
            <Carousel.Slide>
              <Paper>
                <Title ta="center">Run FOSS applications</Title>
                <Text ta="center">
                  Use copy/paste setup scripts to instantly install and configure popular
                  open source applications such as Nextcloud, Gitlab.
                  Run AI models and training on your local machine to leverage GPUs.
                </Text>
              </Paper>
            </Carousel.Slide>
          </Carousel>
        </Box>
      </Box>
    </Container>
  );
}