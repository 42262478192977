import React, { useState,useContext,useEffect,useCallback } from 'react'

import { useTable } from "react-table";
import downLoad from "../assets/download.png";
import { Stack, Anchor, CopyButton,Text,Paper,Accordion, Button, Tooltip,Table, createStyles, ActionIcon, Menu,ScrollArea } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { IconCopy, IconCheck, IconDownload, IconDots, IconPackage} from '@tabler/icons';
// import BoolEditor from '@inovua/reactdatagrid-community/BoolEditor';
// import DateEditor from '@inovua/reactdatagrid-community/DateEditor';
// import NumericEditor from '@inovua/reactdatagrid-community/NumericEditor';
// import { PocContext } from './../hooks/PocContext';
import moveXBeforeY from './moveXBeforeY'
import sslON from '../assets/sslON.png'
import sslOFF from '../assets/sslOFF.png'
import onCircle from '../assets/OnCircle.png'
import offCircle from '../assets/OffCircle.png'


var FileSaver = require('file-saver');


//[ipAddress] -> [ipAddress]

const app_list = [
  
  {
    info_url: 'https://docs.k3s.io',
    emoji: '🚢',
    value: 'Kubernetes (k3s)',
    description: `
curl -sfL https://get.k3s.io | sh -    
`
  },

  {
    info_url: 'https://docs.ton.org/participate/run-nodes/full-node#recommended-providers',
    emoji: '📡',
    value: 'MyTonCtrl-TON node (Transparent mode)',
    description: `
sudo adduser tonuser
sudo usermod -aG sudo  tonuser
su - tonuser
wget https://raw.githubusercontent.com/ton-blockchain/mytonctrl/master/scripts/install.sh
sudo bash install.sh -m full -d
`
  },
  {
      info_url: 'https://docs.inrupt.com',
      emoji: '🏗️',
      value: 'Inrupt Enterprise Solid Server (Alpha)',
      description: `
docker login  --username <userid> docker.software.inrupt.com
docker pull docker.software.inrupt.com/inrupt-kustomizer:2.1
docker run -it -v $HOME/ess:/kustomize docker.software.inrupt.com/inrupt-kustomizer:2.1
cd $HOME/ess
kubectl apply -f kustomized.yaml
kubectl -n ess get all
K8_IP=$(kubectl get nodes -o jsonpath="{.items[*].status.addresses[?(@.type=='InternalIP')].address}")
ESS_DOMAINS=$(grep "host: " kustomized.yaml | awk '{print $3}' | sort -u | tr -d '\r' | tr '\n' ' ')
sudo cp /etc/hosts /etc/hosts.bak
sudo sed -n -e '/# ESS-DOMAINS-BEGIN/,/# ESS-DOMAINS-END/!p' -e '$a# ESS-DOMAINS-BEGIN\n'"$K8_IP $ESS_DOMAINS"'\n# ESS-DOMAINS-END' -i /etc/hosts
curl https://start.{ESS DOMAIN}/
`
    },

  {
    info_url: 'https://ghost.org',
    emoji: '👻',
    value: 'Ghost - Independent technology for modern publishing.',
    description: `
version: '3.1'

services:

  ghost:
    image: ghost:5-alpine
    restart: always
    ports:
      - '[ipAddress]:8080:2368'
    environment:
      # see https://ghost.org/docs/config/#configuration-options
      database__client: mysql
      database__connection__host: db
      database__connection__user: root
      database__connection__password: example
      database__connection__database: ghost
      # this url value is just an example, and is likely wrong for your environment!
      url: http://localhost:8080
      # contrary to the default mentioned in the linked documentation, this image defaults to NODE_ENV=production (so development mode needs to be explicitly specified if desired)
      #NODE_ENV: development
    volumes:
      - ghost:/var/lib/ghost/content

  db:
    image: mysql:8.0
    restart: always
    environment:
      MYSQL_ROOT_PASSWORD: example
    volumes:
      - db:/var/lib/mysql

volumes:
  ghost:
  db:
`,
},
  {
    info_url: 'https://docs.novu.co',
    emoji: '💬',
    value: 'Novu (Universal messaging)',
    description:
    "apt-get -yq install docker-compose \n" +
    "mkdir /opt/novu \n" +
    "cd /opt/novu \n" +
    "cat <<EOF >> /opt/novu/.env \n" +`
    # Secrets
    # YOU MUST CHANGE THESE BEFORE GOING INTO PRODUCTION
    # used as a secret to verify the JWT token signature
    JWT_SECRET=your-secret
    # used to encrypt/decrypt the provider credentials
    STORE_ENCRYPTION_KEY=<ENCRYPTION_KEY_MUST_BE_32_LONG>
    
    # Host
    HOST_NAME=http://[dnsName]
    
    # General
    # available values 'dev', 'test', 'production', 'ci', 'local'
    NODE_ENV=local
    MONGO_MAX_POOL_SIZE=500
    MONGO_MIN_POOL_SIZE=100
    # MONGO USER
    MONGO_INITDB_ROOT_USERNAME=root
    # MONGO PASSWORD
    MONGO_INITDB_ROOT_PASSWORD=secret
    MONGO_URL=mongodb://root:secret@mongodb:27017/novu-db?authSource=admin
    REDIS_HOST=redis
    REDIS_PASSWORD=
    REDIS_CACHE_SERVICE_HOST=
    
    # AWS
    S3_LOCAL_STACK=$HOST_NAME:4566
    S3_BUCKET_NAME=novu-local
    S3_REGION=us-east-1
    AWS_ACCESS_KEY_ID=test
    AWS_SECRET_ACCESS_KEY=test
    
    # Ports
    API_PORT=3000
    REDIS_PORT=6379
    REDIS_CACHE_SERVICE_PORT=6379
    WS_PORT=3002
    
    # Root URL
    REACT_APP_WS_URL=$HOST_NAME:3002
    # Uncomment this one when deploying Novu in the local environment
    # as Web app local Dockerfile will have to load this to be used.
    # Deployment version doesn't need as we inject it with API_ROOT_URL value.
    # REACT_APP_API_URL=http://localhost:3000
    API_ROOT_URL=$HOST_NAME:3000
    DISABLE_USER_REGISTRATION=false
    FRONT_BASE_URL=$HOST_NAME:4200
    WIDGET_EMBED_PATH=$HOST_NAME:4701/embed.umd.min.js
    WIDGET_URL=$HOST_NAME:4500
    
    # Context Paths
    # Only needed for setups with reverse-proxies
    GLOBAL_CONTEXT_PATH=
    WEB_CONTEXT_PATH=
    API_CONTEXT_PATH=
    WS_CONTEXT_PATH=
    WIDGET_CONTEXT_PATH=
    
    # Analytics
    SENTRY_DSN=
    # change these values
    NEW_RELIC_APP_NAME=
    NEW_RELIC_LICENSE_KEY=
    
` +
    "cat <<EOF >> /opt/novu/docker-compose.yml \n" +`
version: '3.9'
name: novu
services:
  redis:
    image: 'redis:alpine'
    container_name: redis
    restart: unless-stopped
    logging:
      driver: 'none'
  mongodb:
    image: mongo
    container_name: mongodb
    restart: unless-stopped
    logging:
      driver: 'json-file'
      options:
        max-size: '50m'
        max-file: '5'
    environment:
      - PUID=1000
      - PGID=1000
      - MONGO_INITDB_ROOT_USERNAME='\${MONGO_INITDB_ROOT_USERNAME}'
      - MONGO_INITDB_ROOT_PASSWORD='\${MONGO_INITDB_ROOT_PASSWORD}'
    volumes:
      - mongodb:/data/db
    ports:
      - 27017:27017
  api:
    image: 'ghcr.io/novuhq/novu/api:0.22.0'
    depends_on:
      - mongodb
      - redis
    container_name: api
    restart: unless-stopped
    logging:
      driver: 'json-file'
      options:
        max-size: '50m'
        max-file: '5'
    environment:
      NODE_ENV: '\${NODE_ENV}'
      API_ROOT_URL: '\${API_ROOT_URL}'
      DISABLE_USER_REGISTRATION: '\${DISABLE_USER_REGISTRATION}'
      PORT: '\${API_PORT}'
      FRONT_BASE_URL: '\${FRONT_BASE_URL}'
      MONGO_URL: '\${MONGO_URL}'
      MONGO_MIN_POOL_SIZE: '\${MONGO_MIN_POOL_SIZE}'
      MONGO_MAX_POOL_SIZE: '\${MONGO_MAX_POOL_SIZE}'
      REDIS_HOST: '\${REDIS_HOST}'
      REDIS_PORT: '\${REDIS_PORT}'
      REDIS_PASSWORD: '\${REDIS_PASSWORD}'
      REDIS_DB_INDEX: 2
      REDIS_CACHE_SERVICE_HOST: '\${REDIS_CACHE_SERVICE_HOST}'
      REDIS_CACHE_SERVICE_PORT: '\${REDIS_CACHE_SERVICE_PORT}'
      S3_LOCAL_STACK: '\${S3_LOCAL_STACK}'
      S3_BUCKET_NAME: '\${S3_BUCKET_NAME}'
      S3_REGION: '\${S3_REGION}'
      AWS_ACCESS_KEY_ID: '\${AWS_ACCESS_KEY_ID}'
      AWS_SECRET_ACCESS_KEY: '\${AWS_SECRET_ACCESS_KEY}'
      JWT_SECRET: '\${JWT_SECRET}'
      STORE_ENCRYPTION_KEY: '\${STORE_ENCRYPTION_KEY}'
      SENTRY_DSN: '\${SENTRY_DSN}'
      NEW_RELIC_APP_NAME: '\${NEW_RELIC_APP_NAME}'
      NEW_RELIC_LICENSE_KEY: '\${NEW_RELIC_LICENSE_KEY}'
      API_CONTEXT_PATH: '\${API_CONTEXT_PATH}'
    ports:
      - '3000:3000'
  worker:
    image: 'ghcr.io/novuhq/novu/worker:0.22.0'
    depends_on:
      - mongodb
      - redis
    container_name: worker
    restart: unless-stopped
    logging:
      driver: 'json-file'
      options:
        max-size: '50m'
        max-file: '5'
    environment:
      NODE_ENV: '\'\${NODE_ENV}'
      MONGO_URL: '\${MONGO_URL}
      MONGO_MAX_POOL_SIZE: '\${MONGO_MAX_POOL_SIZE}'
      REDIS_HOST: '\${REDIS_HOST}'
      REDIS_PORT: '\${REDIS_PORT}'
      REDIS_PASSWORD: '\${REDIS_PASSWORD}'
      REDIS_DB_INDEX: 2
      REDIS_CACHE_SERVICE_HOST: '\${REDIS_CACHE_SERVICE_HOST}'
      REDIS_CACHE_SERVICE_PORT: '\${REDIS_CACHE_SERVICE_PORT}'
      S3_LOCAL_STACK: '\${S3_LOCAL_STACK}'
      S3_BUCKET_NAME: '\${S3_BUCKET_NAME}'
      S3_REGION: '\${S3_REGION}'
      AWS_ACCESS_KEY_ID: '\${AWS_ACCESS_KEY_ID}'
      AWS_SECRET_ACCESS_KEY: '\${AWS_SECRET_ACCESS_KEY}'
      STORE_ENCRYPTION_KEY: '\${STORE_ENCRYPTION_KEY}'
      SENTRY_DSN: '\${SENTRY_DSN}'
      NEW_RELIC_APP_NAME: '\${NEW_RELIC_APP_NAME}'
      NEW_RELIC_LICENSE_KEY: '\${NEW_RELIC_LICENSE_KEY}'
  ws:
    image: 'ghcr.io/novuhq/novu/ws:0.22.0'
    depends_on:
      - mongodb
      - redis
    container_name: ws
    restart: unless-stopped
    logging:
      driver: 'json-file'
      options:
        max-size: '50m'
        max-file: '5'
    environment:
      PORT: '\${WS_PORT}'
      NODE_ENV: '\${NODE_ENV}'
      MONGO_URL: '\${MONGO_URL}'
      MONGO_MAX_POOL_SIZE: '\${MONGO_MAX_POOL_SIZE}'
      REDIS_HOST: '\${REDIS_HOST}'
      REDIS_PORT: '\${REDIS_PORT}'
      REDIS_PASSWORD: '\${REDIS_PASSWORD}'
      JWT_SECRET: '\${JWT_SECRET}'
      WS_CONTEXT_PATH: '\${WS_CONTEXT_PATH}'
      NEW_RELIC_APP_NAME: '\${NEW_RELIC_APP_NAME}'
      NEW_RELIC_LICENSE_KEY: '\${NEW_RELIC_LICENSE_KEY}'
    ports:
      - '3002:3002'
  web:
    image: 'ghcr.io/novuhq/novu/web:0.22.0'
    depends_on:
      - api
      - worker
    container_name: web
    restart: unless-stopped
    logging:
      driver: 'json-file'
      options:
        max-size: '50m'
        max-file: '5'
    environment:
      REACT_APP_API_URL: '\${API_ROOT_URL}'
      REACT_APP_ENVIRONMENT: '\${NODE_ENV}'
      REACT_APP_WIDGET_EMBED_PATH: '\${WIDGET_EMBED_PATH}'
      REACT_APP_DOCKER_HOSTED_ENV: 'true'
      REACT_APP_WS_URL: '\${REACT_APP_WS_URL}'
    ports:
      - "[ipAddress]:4200:4200"
  widget:
    image: 'ghcr.io/novuhq/novu/widget:0.22.0'
    depends_on:
      - api
      - worker
      - web
    container_name: widget
    restart: unless-stopped
    logging:
      driver: 'json-file'
      options:
        max-size: '50m'
        max-file: '5'
    environment:
      REACT_APP_API_URL: '\${API_ROOT_URL}'
      REACT_APP_WS_URL: '\${REACT_APP_WS_URL}'
      REACT_APP_ENVIRONMENT: '\${NODE_ENV}'
      WIDGET_CONTEXT_PATH: '\${WIDGET_CONTEXT_PATH}'
    ports:
      - "[ipAddress]:4500:4500"
  embed:
    depends_on:
      - widget
    image: 'ghcr.io/novuhq/novu/embed:0.22.0'
    container_name: embed
    restart: unless-stopped
    logging:
      driver: 'json-file'
      options:
        max-size: '50m'
        max-file: '5'
    environment:
      WIDGET_URL: '\${WIDGET_URL}'
      NEW_RELIC_APP_NAME: '\${NEW_RELIC_APP_NAME}'
      NEW_RELIC_LICENSE_KEY: '\${NEW_RELIC_LICENSE_KEY}'
    ports:
      - 4701:4701
volumes:
  mongodb: ~    
    `
     + "\n" +
    "EOF\n" +
    "docker-compose up -d \n" +
    "echo your public ip is now: `wget -qO- ifconfig.me` \n" +
    "echo Admin dashboard is available on http port 4200 \n" +
    "\n"  
  },
  {
    info_url: 'https://nginxproxymanager.com',
    emoji: '🌐',
    value: 'Proxy Manager',
    description:
    "apt-get -yq install docker-compose \n" +
    "mkdir /opt/proxymanager \n" +
    "cd /opt/proxymanager \n" +
    "cat <<EOF >> /opt/proxymanager/docker-compose.yml \n" +`
    version: "3"
    services:
    
      app:
        image: 'jc21/nginx-proxy-manager:latest'
        restart: unless-stopped
        ports:
          # These ports are in format <host-port>:<container-port>
          - '[ipAddress]:8080:80' # Public HTTP Port
          - '[ipAddress]:443:443' # Public HTTPS Port
          - '[ipAddress]:8081:81' # Admin Web Port
          # Add any other Stream port you want to expose
          # - '[ipAddress]:21:21' # FTP
    
        # Uncomment the next line if you uncomment anything in the section
        # environment:
          # Uncomment this if you want to change the location of 
          # the SQLite DB file within the container
          # DB_SQLITE_FILE: "/data/database.sqlite"
    
          # Uncomment this if IPv6 is not enabled on your host
          # DISABLE_IPV6: 'true'
    
        volumes:
          - ./data:/data
          - ./letsencrypt:/etc/letsencrypt
    `
    
     + "\n" +
    "EOF\n" +
    "docker-compose up -d \n" +
    "echo your public ip is now: `wget -qO- ifconfig.me` \n" +
    "echo Admin dashboard is available on http port 8081 \n" +
    "\n"  },
  {
    info_url: 'https://docs.ipfs.tech',
    emoji: '🔗',
    value: 'IPFS Cluster (Transparent mode)',
    description:
    "apt-get -yq install docker-compose \n" +
    "mkdir /opt/ipfs \n" +
    "mkdir /opt/ipfs/compose/ipfs0 \n" +
    "mkdir /opt/ipfs/compose/ipfs1 \n" +
    "mkdir /opt/ipfs/compose/ipfs2 \n" +
    "mkdir /opt/ipfs/compose/cluster1 \n" +
    "mkdir /opt/ipfs/compose/cluster2 \n" +
    "cd /opt/ipfs \n" +
    "cat <<EOF >> /opt/ipfs/docker-compose.yml \n" +`
version: '3.4'

# This is an example docker-compose file to quickly test an IPFS Cluster
# with multiple peers on a contained environment.

# It runs 3 cluster peers (cluster0, cluster1...) attached to go-ipfs daemons
# (ipfs0, ipfs1...) using the CRDT consensus component. Cluster peers
# autodiscover themselves using mDNS on the docker internal network.
#
# To interact with the cluster use "ipfs-cluster-ctl" (the cluster0 API port is
# exposed to the locahost. You can also "docker exec -ti cluster0 sh" and run
# it from the container. "ipfs-cluster-ctl peers ls" should show all 3 peers a few
# seconds after start.
#
# For persistence, a "compose" folder is created and used to store configurations
# and states. This can be used to edit configurations in subsequent runs. It looks
# as follows:
#
# compose/
# |-- cluster0
# |-- cluster1
# |-- ...
# |-- ipfs0
# |-- ipfs1
# |-- ...
#
# During the first start, default configurations are created for all peers.

services:

##################################################################################
## Cluster PEER 0 ################################################################
##################################################################################

ipfs0:
container_name: ipfs0
image: ipfs/go-ipfs:latest
ports:
 - '[ipAddress]:4001:4001' # ipfs swarm - expose if needed/wanted
 - '[ipAddress]:5001:5001' # ipfs api - expose if needed/wanted
 #     - "8080:8080" # ipfs gateway - expose if needed/wanted
volumes:
 - ./compose/ipfs0:/data/ipfs

cluster0:
container_name: cluster0
image: ipfs/ipfs-cluster:latest
depends_on:
 - ipfs0
environment:
 CLUSTER_PEERNAME: cluster0
 CLUSTER_SECRET: CHANGEME_NOW_OR_ELSE # From shell variable if set
 CLUSTER_IPFSHTTP_NODEMULTIADDRESS: /dns4/ipfs0/tcp/5001
 CLUSTER_CRDT_TRUSTEDPEERS: '*' # Trust all peers in Cluster
 CLUSTER_RESTAPI_HTTPLISTENMULTIADDRESS: /ip4/0.0.0.0/tcp/9094 # Expose API
 CLUSTER_MONITORPINGINTERVAL: 2s # Speed up peer discovery
ports:
 # Open API port (allows ipfs-cluster-ctl usage on host)
 - "127.0.0.1:9094:9094"
 # The cluster swarm port would need  to be exposed if this container
 # was to connect to cluster peers on other hosts.
 # But this is just a testing cluster.
 - '[ipAddress]:9095:9095' # Cluster IPFS Proxy endpoint
 - '[ipAddress]:9096:9096' # Cluster swarm endpoint
volumes:
 - ./compose/cluster0:/data/ipfs-cluster

##################################################################################
## Cluster PEER 1 ################################################################
##################################################################################

# See Cluster PEER 0 for comments (all removed here and below)
ipfs1:
container_name: ipfs1
image: ipfs/go-ipfs:latest
volumes:
 - ./compose/ipfs1:/data/ipfs

cluster1:
container_name: cluster1
image: ipfs/ipfs-cluster:latest
depends_on:
 - ipfs1
environment:
 CLUSTER_PEERNAME: cluster1
 CLUSTER_SECRET: CHANGEME_NOW_OR_ELSE
 CLUSTER_IPFSHTTP_NODEMULTIADDRESS: /dns4/ipfs1/tcp/5001
 CLUSTER_CRDT_TRUSTEDPEERS: '*'
 CLUSTER_MONITORPINGINTERVAL: 2s # Speed up peer discovery
volumes:
 - ./compose/cluster1:/data/ipfs-cluster

##################################################################################
## Cluster PEER 2 ################################################################
##################################################################################

# See Cluster PEER 0 for comments (all removed here and below)
ipfs2:
container_name: ipfs2
image: ipfs/go-ipfs:latest
volumes:
 - ./compose/ipfs2:/data/ipfs

cluster2:
container_name: cluster2
image: ipfs/ipfs-cluster:latest
depends_on:
 - ipfs2
environment:
 CLUSTER_PEERNAME: cluster2
 CLUSTER_SECRET: CHANGEME_NOW_OR_ELSE
 CLUSTER_IPFSHTTP_NODEMULTIADDRESS: /dns4/ipfs2/tcp/5001
 CLUSTER_CRDT_TRUSTEDPEERS: '*'
 CLUSTER_MONITORPINGINTERVAL: 2s # Speed up peer discovery
volumes:
 - ./compose/cluster2:/data/ipfs-cluster

# For adding more peers, copy PEER 1 and rename things to ipfs2, cluster2.
# Keep bootstrapping to cluster0.
    `
    
     + "\n" +
    "EOF\n" +
    "docker-compose up -d \n" +
    "echo your public ip is now: `wget -qO- ifconfig.me` \n" +
    "echo Admin dashboard is available on http port 8081 \n" +
    "\n"  },
  {
    info_url: 'https://jitsi.github.io/handbook/docs/devops-guide/devops-guide-docker/',
emoji: '📹',
    value: 'Jitsi Video Conference Server (Transparent mode)',
    description:
    "apt-get -yq install docker-compose \n" +
    "mkdir /opt/jitsi \n" +
    "cd /opt/jitsi \n" +
    "cat <<EOF >> /opt/jitsi/.env \n" +

     "################################################################################ \n" +
     "################################################################################ \n" +
     "# Welcome to the Jitsi Meet Docker setup! \n" +
     "# \n" +
     "# This sample .env file contains some basic options to get you started. \n" +
     "# The full options reference can be found here: \n" +
     "# https://jitsi.github.io/handbook/docs/devops-guide/devops-guide-docker \n" +
     "################################################################################ \n" +
     "################################################################################ \n" +


     "# \n" +
     "# Basic configuration options \n" +
     "# \n" +

     "# Directory where all configuration will be stored \n" +
     "CONFIG=~/.jitsi-meet-cfg \n" +

     "# Exposed HTTP port \n" +
     "HTTP_PORT=80 \n" +

     "# Exposed HTTPS port \n" +
     "HTTPS_PORT=443 \n" +

     "# System time zone \n" +
     "TZ=UTC \n" +

     "# Public URL for the web service (required) \n" +
     "PUBLIC_URL=[dnsName]\n" +

     "# Media IP addresses to advertise by the JVB \n" +
     "# This setting deprecates DOCKER_HOST_ADDRESS, and supports a comma separated list of IPs \n" +
     "# See the \"Running behind NAT or on a LAN environment\" section in the Handbook: \n" +
     "# https://jitsi.github.io/handbook/docs/devops-guide/devops-guide-docker#running-behind-nat-or-on-a-lan-environment \n" +
     "#JVB_ADVERTISE_IPS=192.168.1.1,1.2.3.4 \n" +


     "# \n" +
     "# JaaS Components (beta) \n" +
     "# https://jaas.8x8.vc \n" +
     "# \n" +

     "# Enable JaaS Components (hosted Jigasi) \n" +
     "#ENABLE_JAAS_COMPONENTS=0 \n" +

     "# \n" +
     "# Let's Encrypt configuration \n" +
     "# \n" +

     "# Enable Let's Encrypt certificate generation \n" +
     "ENABLE_LETSENCRYPT=1 \n" +

     "# Domain for which to generate the certificate \n" +
     "LETSENCRYPT_DOMAIN=[dnsName]\n" +

     "# E-Mail for receiving important account notifications (mandatory) \n" +
     "LETSENCRYPT_EMAIL=[contactEmail]\n" +

     "# Use the staging server (for avoiding rate limits while testing) \n" +
     "#LETSENCRYPT_USE_STAGING=1 \n" +


     "# \n" +
     "# Etherpad integration (for document sharing) \n" +
     "# \n" +

     "# Set etherpad-lite URL in docker local network (uncomment to enable) \n" +
     "#ETHERPAD_URL_BASE=http://etherpad.meet.jitsi:9001 \n" +

     "# Set etherpad-lite public URL, including /p/ pad path fragment (uncomment to enable) \n" +
     "#ETHERPAD_PUBLIC_URL=https://etherpad.my.domain/p/ \n" +

     "# Name your etherpad instance! \n" +
     "ETHERPAD_TITLE=Video Chat \n" +

     "# The default text of a pad \n" +
     "ETHERPAD_DEFAULT_PAD_TEXT=\"Welcome to Web Chat!\n\n\" \n" +

     "# Name of the skin for etherpad \n" +
     "ETHERPAD_SKIN_NAME=colibris \n" +

     "# Skin variants for etherpad \n" +
     "ETHERPAD_SKIN_VARIANTS=\"super-light-toolbar super-light-editor light-background full-width-editor\" \n" +


     "# \n" +
     "# Basic Jigasi configuration options (needed for SIP gateway support) \n" +
     "# \n" +

     "# SIP URI for incoming / outgoing calls \n" +
     "#JIGASI_SIP_URI=test@sip2sip.info \n" +

     "# Password for the specified SIP account as a clear text \n" +
     "#JIGASI_SIP_PASSWORD=passw0rd \n" +

     "# SIP server (use the SIP account domain if in doubt) \n" +
     "#JIGASI_SIP_SERVER=sip2sip.info \n" +

     "# SIP server port \n" +
     "#JIGASI_SIP_PORT=5060 \n" +

     "# SIP server transport \n" +
     "#JIGASI_SIP_TRANSPORT=UDP \n" +


     "# \n" +
     "# Authentication configuration (see handbook for details) \n" +
     "# \n" +

     "# Enable authentication \n" +
     "#ENABLE_AUTH=1 \n" +

     "# Enable guest access \n" +
     "#ENABLE_GUESTS=1 \n" +

     "# Select authentication type: internal, jwt, ldap or matrix \n" +
     "#AUTH_TYPE=internal \n" +

     "# JWT authentication \n" +
     "# \n" +

     "# Application identifier \n" +
     "#JWT_APP_ID=my_jitsi_app_id \n" +

     "# Application secret known only to your token generator \n" +
     "#JWT_APP_SECRET=my_jitsi_app_secret \n" +

     "# (Optional) Set asap_accepted_issuers as a comma separated list \n" +
     "#JWT_ACCEPTED_ISSUERS=my_web_client,my_app_client \n" +

     "# (Optional) Set asap_accepted_audiences as a comma separated list \n" +
     "#JWT_ACCEPTED_AUDIENCES=my_server1,my_server2 \n" +

     "# LDAP authentication (for more information see the Cyrus SASL saslauthd.conf man page) \n" +
     "# \n" +

     "# LDAP url for connection \n" +
     "#LDAP_URL=ldaps://ldap.domain.com/ \n" +

     "# LDAP base DN. Can be empty \n" +
     "#LDAP_BASE=DC=example,DC=domain,DC=com \n" +

     "# LDAP user DN. Do not specify this parameter for the anonymous bind \n" +
     "#LDAP_BINDDN=CN=binduser,OU=users,DC=example,DC=domain,DC=com \n" +

     "# LDAP user password. Do not specify this parameter for the anonymous bind \n" +
     "#LDAP_BINDPW=LdapUserPassw0rd \n" +

     "# LDAP filter. Tokens example: \n" +
     "# %1-9 - if the input key is user@mail.domain.com, then %1 is com, %2 is domain and %3 is mail \n" +
     "# %s - %s is replaced by the complete service string \n" +
     "# %r - %r is replaced by the complete realm string \n" +
     "#LDAP_FILTER=(sAMAccountName=%u) \n" +

     "# LDAP authentication method \n" +
     "#LDAP_AUTH_METHOD=bind \n" +

     "# LDAP version \n" +
     "#LDAP_VERSION=3 \n" +

     "# LDAP TLS using \n" +
     "#LDAP_USE_TLS=1 \n" +

     "# List of SSL/TLS ciphers to allow \n" +
     "#LDAP_TLS_CIPHERS=SECURE256:SECURE128:!AES-128-CBC:!ARCFOUR-128:!CAMELLIA-128-CBC:!3DES-CBC:!CAMELLIA-128-CBC \n" +

     "# Require and verify server certificate \n" +
     "#LDAP_TLS_CHECK_PEER=1 \n" +

     "# Path to CA cert file. Used when server certificate verify is enabled \n" +
     "#LDAP_TLS_CACERT_FILE=/etc/ssl/certs/ca-certificates.crt \n" +

     "# Path to CA certs directory. Used when server certificate verify is enabled \n" +
     "#LDAP_TLS_CACERT_DIR=/etc/ssl/certs \n" +

     "# Wether to use starttls, implies LDAPv3 and requires ldap:// instead of ldaps:// \n" +
     "# LDAP_START_TLS=1 \n" +


     "# \n" +
     "# Security \n" +
     "# \n" +
     "# Set these to strong passwords to avoid intruders from impersonating a service account \n" +
     "# The service(s) won't start unless these are specified \n" +
     "# Running ./gen-passwords.sh will update .env with strong passwords \n" +
     "# You may skip the Jigasi and Jibri passwords if you are not using those \n" +
     "# DO NOT reuse passwords \n" +
     "# \n" +

     "# XMPP password for Jicofo client connections \n" +
     "JICOFO_AUTH_PASSWORD=61307cfae8646f0ff9dda1ffaf608436 \n" +

     "# XMPP password for JVB client connections \n" +
     "JVB_AUTH_PASSWORD=30fec26822ed6c3f27dffb78429768ae \n" +

     "# XMPP password for Jigasi MUC client connections \n" +
     "JIGASI_XMPP_PASSWORD=a76bded424d258af9b3f04a0818e6866 \n" +

     "# XMPP recorder password for Jibri client connections \n" +
     "JIBRI_RECORDER_PASSWORD=d98c255b455429c67458c3a30412bb17 \n" +

     "# XMPP password for Jibri client connections \n" +
     "JIBRI_XMPP_PASSWORD=bd5fc978dfbb50aecde6e8dc728b043d \n" +

     "# \n" +
     "# Docker Compose options \n" +
     "# \n" +

     "# Container restart policy \n" +
     "#RESTART_POLICY=unless-stopped \n" +

     "# Jitsi image version (useful for local development) \n" +
     "#JITSI_IMAGE_VERSION=latest \n" +

    "EOF\n" +

    "cat <<EOF >> /opt/jitsi/docker-compose.yml \n" +
    "version: '3.5'\n" +

"services:\n" +
"    # Frontend\n" +
"    web:\n" +
"        image: jitsi/web:'${JITSI_IMAGE_VERSION:-stable-7882}'\n" +
"        restart: '${RESTART_POLICY:-unless-stopped}'\n" +
"        ports:\n" +
"            - '" + `[ipAddress]` + ":80:80'\n" +
"            - '" + `[ipAddress]` + ":443:443'\n" +
"        volumes:\n" +
"            - ${CONFIG}/web:/config:Z\n" +
"            - ${CONFIG}/web/crontabs:/var/spool/cron/crontabs:Z\n" +
"            - ${CONFIG}/transcripts:/usr/share/jitsi-meet/transcripts:Z\n" +
"        environment:\n" +
"            - AMPLITUDE_ID\n" +
"            - ANALYTICS_SCRIPT_URLS\n" +
"            - ANALYTICS_WHITELISTED_EVENTS\n" +
"            - AUDIO_QUALITY_OPUS_BITRATE\n" +
"            - BRANDING_DATA_URL\n" +
"            - CALLSTATS_CUSTOM_SCRIPT_URL\n" +
"            - CALLSTATS_ID\n" +
"            - CALLSTATS_SECRET\n" +
"            - CHROME_EXTENSION_BANNER_JSON\n" +
"            - CONFCODE_URL\n" +
"            - CONFIG_EXTERNAL_CONNECT\n" +
"            - DEFAULT_LANGUAGE\n" +
"            - DEPLOYMENTINFO_ENVIRONMENT\n" +
"            - DEPLOYMENTINFO_ENVIRONMENT_TYPE\n" +
"            - DEPLOYMENTINFO_REGION\n" +
"            - DEPLOYMENTINFO_SHARD\n" +
"            - DEPLOYMENTINFO_USERREGION\n" +
"            - DESKTOP_SHARING_FRAMERATE_MIN\n" +
"            - DESKTOP_SHARING_FRAMERATE_MAX\n" +
"            - DIALIN_NUMBERS_URL\n" +
"            - DIALOUT_AUTH_URL\n" +
"            - DIALOUT_CODES_URL\n" +
"            - DISABLE_AUDIO_LEVELS\n" +
"            - DISABLE_DEEP_LINKING\n" +
"            - DISABLE_GRANT_MODERATOR\n" +
"            - DISABLE_HTTPS\n" +
"            - DISABLE_KICKOUT\n" +
"            - DISABLE_LOCAL_RECORDING\n" +
"            - DISABLE_POLLS\n" +
"            - DISABLE_PRIVATE_CHAT\n" +
"            - DISABLE_PROFILE\n" +
"            - DISABLE_REACTIONS\n" +
"            - DISABLE_REMOTE_VIDEO_MENU\n" +
"            - DROPBOX_APPKEY\n" +
"            - DROPBOX_REDIRECT_URI\n" +
"            - DYNAMIC_BRANDING_URL\n" +
"            - ENABLE_AUDIO_PROCESSING\n" +
"            - ENABLE_AUTH\n" +
"            - ENABLE_BREAKOUT_ROOMS\n" +
"            - ENABLE_CALENDAR\n" +
"            - ENABLE_COLIBRI_WEBSOCKET\n" +
"            - ENABLE_E2EPING\n" +
"            - ENABLE_FILE_RECORDING_SHARING\n" +
"            - ENABLE_GUESTS\n" +
"            - ENABLE_HSTS\n" +
"            - ENABLE_HTTP_REDIRECT\n" +
"            - ENABLE_IPV6\n" +
"            - ENABLE_LETSENCRYPT\n" +
"            - ENABLE_LIPSYNC\n" +
"            - ENABLE_NO_AUDIO_DETECTION\n" +
"            - ENABLE_NOISY_MIC_DETECTION\n" +
"            - ENABLE_OCTO\n" +
"            - ENABLE_OPUS_RED\n" +
"            - ENABLE_PREJOIN_PAGE\n" +
"            - ENABLE_P2P\n" +
"            - ENABLE_WELCOME_PAGE\n" +
"            - ENABLE_CLOSE_PAGE\n" +
"            - ENABLE_LIVESTREAMING\n" +
"            - ENABLE_LOCAL_RECORDING_NOTIFY_ALL_PARTICIPANT\n" +
"            - ENABLE_LOCAL_RECORDING_SELF_START\n" +
"            - ENABLE_RECORDING\n" +
"            - ENABLE_REMB\n" +
"            - ENABLE_REQUIRE_DISPLAY_NAME\n" +
"            - ENABLE_SERVICE_RECORDING\n" +
"            - ENABLE_SIMULCAST\n" +
"            - ENABLE_STATS_ID\n" +
"            - ENABLE_STEREO\n" +
"            - ENABLE_SUBDOMAINS\n" +
"            - ENABLE_TALK_WHILE_MUTED\n" +
"            - ENABLE_TCC\n" +
"            - ENABLE_TRANSCRIPTIONS\n" +
"            - ENABLE_XMPP_WEBSOCKET\n" +
"            - ENABLE_JAAS_COMPONENTS\n" +
"            - ENABLE_MULTI_STREAM\n" +
"            - ETHERPAD_PUBLIC_URL\n" +
"            - ETHERPAD_URL_BASE\n" +
"            - E2EPING_NUM_REQUESTS\n" +
"            - E2EPING_MAX_CONFERENCE_SIZE\n" +
"            - E2EPING_MAX_MESSAGE_PER_SECOND\n" +
"            - GOOGLE_ANALYTICS_ID\n" +
"            - GOOGLE_API_APP_CLIENT_ID\n" +
"            - HIDE_PREMEETING_BUTTONS\n" +
"            - HIDE_PREJOIN_DISPLAY_NAME\n" +
"            - HIDE_PREJOIN_EXTRA_BUTTONS\n" +
"            - INVITE_SERVICE_URL\n" +
"            - JICOFO_AUTH_USER\n" +
"            - LETSENCRYPT_DOMAIN\n" +
"            - LETSENCRYPT_EMAIL\n" +
"            - LETSENCRYPT_USE_STAGING\n" +
"            - MATOMO_ENDPOINT\n" +
"            - MATOMO_SITE_ID\n" +
"            - MICROSOFT_API_APP_CLIENT_ID\n" +
"            - NGINX_RESOLVER\n" +
"            - NGINX_WORKER_PROCESSES\n" +
"            - NGINX_WORKER_CONNECTIONS\n" +
"            - PEOPLE_SEARCH_URL\n" +
"            - PUBLIC_URL\n" +
"            - P2P_PREFERRED_CODEC\n" +
"            - RESOLUTION\n" +
"            - RESOLUTION_MIN\n" +
"            - RESOLUTION_WIDTH\n" +
"            - RESOLUTION_WIDTH_MIN\n" +
"            - START_AUDIO_MUTED\n" +
"            - START_AUDIO_ONLY\n" +
"            - START_BITRATE\n" +
"            - START_SILENT\n" +
"            - START_WITH_AUDIO_MUTED\n" +
"            - START_VIDEO_MUTED\n" +
"            - START_WITH_VIDEO_MUTED\n" +
"            - TESTING_CAP_SCREENSHARE_BITRATE\n" +
"            - TESTING_OCTO_PROBABILITY\n" +
"            - TOKEN_AUTH_URL\n" +
"            - TOOLBAR_BUTTONS\n" +
"            - TZ\n" +
"            - VIDEOQUALITY_BITRATE_H264_LOW\n" +
"            - VIDEOQUALITY_BITRATE_H264_STANDARD\n" +
"            - VIDEOQUALITY_BITRATE_H264_HIGH\n" +
"            - VIDEOQUALITY_BITRATE_VP8_LOW\n" +
"            - VIDEOQUALITY_BITRATE_VP8_STANDARD\n" +
"            - VIDEOQUALITY_BITRATE_VP8_HIGH\n" +
"            - VIDEOQUALITY_BITRATE_VP9_LOW\n" +
"            - VIDEOQUALITY_BITRATE_VP9_STANDARD\n" +
"            - VIDEOQUALITY_BITRATE_VP9_HIGH\n" +
"            - VIDEOQUALITY_ENFORCE_PREFERRED_CODEC\n" +
"            - VIDEOQUALITY_PREFERRED_CODEC\n" +
"            - XMPP_AUTH_DOMAIN\n" +
"            - XMPP_BOSH_URL_BASE\n" +
"            - XMPP_DOMAIN\n" +
"            - XMPP_GUEST_DOMAIN\n" +
"            - XMPP_MUC_DOMAIN\n" +
"            - XMPP_RECORDER_DOMAIN\n" +
"            - XMPP_PORT\n" +
"        networks:\n" +
"            meet.jitsi:\n" +

"    # XMPP server\n" +
"    prosody:\n" +
"        image: jitsi/prosody:${JITSI_IMAGE_VERSION:-stable-7882}\n" +
"        restart: ${RESTART_POLICY:-unless-stopped}\n" +
"        expose:\n" +
"            - '${XMPP_PORT:-5222}'\n" +
"            - '5347'\n" +
"            - '5280'\n" +
"        volumes:\n" +
"            - ${CONFIG}/prosody/config:/config:Z\n" +
"            - ${CONFIG}/prosody/prosody-plugins-custom:/prosody-plugins-custom:Z\n" +
"        environment:\n" +
"            - AUTH_TYPE\n" +
"            - DISABLE_POLLS\n" +
"            - ENABLE_AUTH\n" +
"            - ENABLE_AV_MODERATION\n" +
"            - ENABLE_BREAKOUT_ROOMS\n" +
"            - ENABLE_END_CONFERENCE\n" +
"            - ENABLE_GUESTS\n" +
"            - ENABLE_IPV6\n" +
"            - ENABLE_LOBBY\n" +
"            - ENABLE_RECORDING\n" +
"            - ENABLE_XMPP_WEBSOCKET\n" +
"            - ENABLE_JAAS_COMPONENTS\n" +
"            - GC_TYPE\n" +
"            - GC_INC_TH\n" +
"            - GC_INC_SPEED\n" +
"            - GC_INC_STEP_SIZE\n" +
"            - GC_GEN_MIN_TH\n" +
"            - GC_GEN_MAX_TH\n" +
"            - GLOBAL_CONFIG\n" +
"            - GLOBAL_MODULES\n" +
"            - JIBRI_RECORDER_USER\n" +
"            - JIBRI_RECORDER_PASSWORD\n" +
"            - JIBRI_XMPP_USER\n" +
"            - JIBRI_XMPP_PASSWORD\n" +
"            - JICOFO_AUTH_USER\n" +
"            - JICOFO_AUTH_PASSWORD\n" +
"            - JICOFO_COMPONENT_SECRET\n" +
"            - JIGASI_XMPP_USER\n" +
"            - JIGASI_XMPP_PASSWORD\n" +
"            - JVB_AUTH_USER\n" +
"            - JVB_AUTH_PASSWORD\n" +
"            - JWT_APP_ID\n" +
"            - JWT_APP_SECRET\n" +
"            - JWT_ACCEPTED_ISSUERS\n" +
"            - JWT_ACCEPTED_AUDIENCES\n" +
"            - JWT_ASAP_KEYSERVER\n" +
"            - JWT_ALLOW_EMPTY\n" +
"            - JWT_AUTH_TYPE\n" +
"            - JWT_ENABLE_DOMAIN_VERIFICATION\n" +
"            - JWT_TOKEN_AUTH_MODULE\n" +
"            - MATRIX_UVS_URL\n" +
"            - MATRIX_UVS_ISSUER\n" +
"            - MATRIX_UVS_AUTH_TOKEN\n" +
"            - MATRIX_UVS_SYNC_POWER_LEVELS\n" +
"            - LOG_LEVEL\n" +
"            - LDAP_AUTH_METHOD\n" +
"            - LDAP_BASE\n" +
"            - LDAP_BINDDN\n" +
"            - LDAP_BINDPW\n" +
"            - LDAP_FILTER\n" +
"            - LDAP_VERSION\n" +
"            - LDAP_TLS_CIPHERS\n" +
"            - LDAP_TLS_CHECK_PEER\n" +
"            - LDAP_TLS_CACERT_FILE\n" +
"            - LDAP_TLS_CACERT_DIR\n" +
"            - LDAP_START_TLS\n" +
"            - LDAP_URL\n" +
"            - LDAP_USE_TLS\n" +
"            - MAX_PARTICIPANTS\n" +
"            - PROSODY_RESERVATION_ENABLED\n" +
"            - PROSODY_RESERVATION_REST_BASE_URL\n" +
"            - PUBLIC_URL\n" +
"            - TURN_CREDENTIALS\n" +
"            - TURN_HOST\n" +
"            - TURNS_HOST\n" +
"            - TURN_PORT\n" +
"            - TURNS_PORT\n" +
"            - TZ\n" +
"            - XMPP_DOMAIN\n" +
"            - XMPP_AUTH_DOMAIN\n" +
"            - XMPP_GUEST_DOMAIN\n" +
"            - XMPP_MUC_DOMAIN\n" +
"            - XMPP_INTERNAL_MUC_DOMAIN\n" +
"            - XMPP_MODULES\n" +
"            - XMPP_MUC_MODULES\n" +
"            - XMPP_MUC_CONFIGURATION\n" +
"            - XMPP_INTERNAL_MUC_MODULES\n" +
"            - XMPP_RECORDER_DOMAIN\n" +
"            - XMPP_PORT\n" +
"        networks:\n" +
"            meet.jitsi:\n" +
"                aliases:\n" +
"                    - ${XMPP_SERVER:-xmpp.meet.jitsi}\n" +

"    # Focus component\n" +
"    jicofo:\n" +
"        image: jitsi/jicofo:${JITSI_IMAGE_VERSION:-stable-7882}\n" +
"        restart: ${RESTART_POLICY:-unless-stopped}\n" +
"        volumes:\n" +
"            - ${CONFIG}/jicofo:/config:Z\n" +
"        environment:\n" +
"            - AUTH_TYPE\n" +
"            - BRIDGE_AVG_PARTICIPANT_STRESS\n" +
"            - BRIDGE_STRESS_THRESHOLD\n" +
"            - ENABLE_AUTH\n" +
"            - ENABLE_AUTO_OWNER\n" +
"            - ENABLE_CODEC_VP8\n" +
"            - ENABLE_CODEC_VP9\n" +
"            - ENABLE_CODEC_H264\n" +
"            - ENABLE_OCTO\n" +
"            - ENABLE_RECORDING\n" +
"            - ENABLE_SCTP\n" +
"            - ENABLE_AUTO_LOGIN\n" +
"            - JICOFO_AUTH_USER\n" +
"            - JICOFO_AUTH_PASSWORD\n" +
"            - JICOFO_ENABLE_BRIDGE_HEALTH_CHECKS\n" +
"            - JICOFO_CONF_INITIAL_PARTICIPANT_WAIT_TIMEOUT\n" +
"            - JICOFO_CONF_SINGLE_PARTICIPANT_TIMEOUT\n" +
"            - JICOFO_ENABLE_HEALTH_CHECKS\n" +
"            - JICOFO_SHORT_ID\n" +
"            - JIBRI_BREWERY_MUC\n" +
"            - JIBRI_REQUEST_RETRIES\n" +
"            - JIBRI_PENDING_TIMEOUT\n" +
"            - JIGASI_BREWERY_MUC\n" +
"            - JIGASI_SIP_URI\n" +
"            - JVB_BREWERY_MUC\n" +
"            - MAX_BRIDGE_PARTICIPANTS\n" +
"            - OCTO_BRIDGE_SELECTION_STRATEGY\n" +
"            - SENTRY_DSN=${JICOFO_SENTRY_DSN:-0}\n" +
"            - SENTRY_ENVIRONMENT\n" +
"            - SENTRY_RELEASE\n" +
"            - TZ\n" +
"            - XMPP_DOMAIN\n" +
"            - XMPP_AUTH_DOMAIN\n" +
"            - XMPP_INTERNAL_MUC_DOMAIN\n" +
"            - XMPP_MUC_DOMAIN\n" +
"            - XMPP_RECORDER_DOMAIN\n" +
"            - XMPP_SERVER\n" +
"            - XMPP_PORT\n" +
"        depends_on:\n" +
"            - prosody\n" +
"        networks:\n" +
"            meet.jitsi:\n" +

"    # Video bridge\n" +
"    jvb:\n" +
"        image: jitsi/jvb:${JITSI_IMAGE_VERSION:-stable-7882}\n" +
"        restart: ${RESTART_POLICY:-unless-stopped}\n" +
"        ports:\n" +
"            - '" + `[ipAddress]` + ":${JVB_PORT:-10000}:${JVB_PORT:-10000}/udp'\n" +
"            - '127.0.0.1:${JVB_COLIBRI_PORT:-8080}:8080'\n" +
"        volumes:\n" +
"            - ${CONFIG}/jvb:/config:Z\n" +
"        environment:\n" +
"            - DOCKER_HOST_ADDRESS\n" +
"            - ENABLE_COLIBRI_WEBSOCKET\n" +
"            - ENABLE_OCTO\n" +
"            - ENABLE_MULTI_STREAM\n" +
"            - JVB_ADVERTISE_IPS\n" +
"            - JVB_ADVERTISE_PRIVATE_CANDIDATES\n" +
"            - JVB_AUTH_USER\n" +
"            - JVB_AUTH_PASSWORD\n" +
"            - JVB_BREWERY_MUC\n" +
"            - JVB_DISABLE_STUN\n" +
"            - JVB_PORT\n" +
"            - JVB_MUC_NICKNAME\n" +
"            - JVB_STUN_SERVERS\n" +
"            - JVB_OCTO_BIND_ADDRESS\n" +
"            - JVB_OCTO_REGION\n" +
"            - JVB_OCTO_RELAY_ID\n" +
"            - JVB_WS_DOMAIN\n" +
"            - JVB_WS_SERVER_ID\n" +
"            - PUBLIC_URL\n" +
"            - SENTRY_DSN=${JVB_SENTRY_DSN:-0}\n" +
"            - SENTRY_ENVIRONMENT\n" +
"            - SENTRY_RELEASE\n" +
"            - COLIBRI_REST_ENABLED\n" +
"            - SHUTDOWN_REST_ENABLED\n" +
"            - TZ\n" +
"            - XMPP_AUTH_DOMAIN\n" +
"            - XMPP_INTERNAL_MUC_DOMAIN\n" +
"            - XMPP_SERVER\n" +
"            - XMPP_PORT\n" +
"        depends_on:\n" +
"            - prosody\n" +
"        networks:\n" +
"            meet.jitsi:\n" +

"# Custom network so all services can communicate using a FQDN\n" +
"networks:\n" +
"    meet.jitsi:\n" +

"EOF\n" +
    "docker-compose up -d \n" +
    "echo your public ip is now: `wget -qO- ifconfig.me` \n" +
    "\n"
  },
  {
    info_url: 'https://docs.mistral.ai/self-deployment/vllm',
emoji: '🥦',
    value: 'Mistral 7B',
    description:
    `
    docker run --gpus all \
        -e HF_TOKEN=$HF_TOKEN -p 8000:8000 \
        ghcr.io/mistralai/mistral-src/vllm:latest \
        --host [ipAddress] \
        --model mistralai/Mistral-7B-Instruct-v0.2
    `
  },
  {
    info_url: 'https://docs.mistral.ai/self-deployment/vllm',
emoji: '🥦',
    value: 'Mixtral-8X7B',
    description:
    `
    docker run --gpus all \
-e HF_TOKEN=$HF_TOKEN -p 8000:8000 \
ghcr.io/mistralai/mistral-src/vllm:latest \
--host [ipAddress] \
--model mistralai/Mixtral-8x7B-Instruct-v0.1 \
--tensor-parallel-size 2 # adapt to your GPUs \
--load-format pt # needed since both "pt" and "safetensors" are available
`
  },

  {
    info_url: 'https://github.com/nfodor/cloudflareLB.git',
  emoji: '☁️',
    value: 'Cloudflare Load Balancer (Transparent mode)',
    description:
    
    "curl --proto '=https' --tlsv1.2 -sSf https://sh.rustup.rs | sh \n" +
    "apt install cmake \n" +
    "git clone  https://github.com/nfodor/cloudflareLB.git\n" +
    "cd cloudflareLB\n" +
    "cargo build\n" +
    "cargo run\n" +
    "curl 127.0.0.1:6188 -svo /dev/null \n" 

  },
  {
    info_url: 'https://redpanda.com',
    emoji: '🐼',
    value: 'RedPanda',
    description:
    "apt-get -yq install docker-compose \n" +
    "mkdir /opt/redpanda \n" +
    "cd /opt/redpanda \n" +
    "cat <<EOF >> /opt/redpanda/config.yml \n" + 
`kafka:
brokers: ["redpanda:29092"]
schemaRegistry:
enabled: true
urls: ["http://redpanda:8081"]
redpanda:
adminApi:
enabled: true
urls: ["http://redpanda:9644"]
connect:
enabled: true
clusters:
- name: local-connect-cluster
 url: http://connect:8083
`
+ "\n" +
     "EOF\n" +         
    "cat <<EOF >> /opt/redpanda/docker-compose.yml \n" +`        
version: '3.7'
services:
redpanda:
image: docker.redpanda.com/vectorized/redpanda:v22.2.2
command:
 - redpanda start
 - --smp 1
 - --overprovisioned
 - --node-id 0
 - --kafka-addr PLAINTEXT://0.0.0.0:29092,OUTSIDE://0.0.0.0:9092
 - --advertise-kafka-addr PLAINTEXT://redpanda:29092,OUTSIDE://localhost:9092
 - --pandaproxy-addr 0.0.0.0:8082
 - --advertise-pandaproxy-addr localhost:8082
ports:
 - '[ipAddress]:8081:8081'
 - '[ipAddress]:8082:8082'
 - 9092:9092
 - 9644:9644
 - 29092:29092

console:
image: docker.redpanda.com/vectorized/console:latest
entrypoint: /bin/sh
command: -c "/app/console"
volumes:
 - ./config.yml:/tmp/config.yml
environment:
 CONFIG_FILEPATH: /tmp/config.yml
 CONSOLE_CONFIG_FILE: |
   kafka:
     brokers: ["redpanda:29092"]
     schemaRegistry:
       enabled: true
       urls: ["http://redpanda:8081"]
   redpanda:
     adminApi:
       enabled: true
       urls: ["http://redpanda:9644"]
   connect:
     enabled: true
     clusters:
       - name: local-connect-cluster
         url: http://connect:8083
ports:
 - '[ipAddress]:8080:8080'
depends_on:
 - redpanda
` + "\n" +
          "EOF\n" +
          "sudo wg-quick up wg0 \n" + 
          "docker-compose up -d \n" +
          "\n"
  },
  {
    info_url: '',
emoji: '📫',
    value: 'Mail In a box (Transparent mode)',
    description:
    "apt-get -yq install docker-compose \n" +
    "git clone https://github.com/djmaze/mailinabox-docker.git /opt/mailinabox" +
    "rm /opt/mailinabox/docker-compose.yml" +
    "cd /opt/mailinabox/base" +
    "docker build  -t mailinabox-docker-base ." +
    "cd /opt/mailinabox" +
    "set -e" +
    // "mkdir /opt/mailinabox \n" +
    // "mkdir /opt/mailinabox/postfix \n" +
    // "mkdir /opt/mailinabox/postgrey \n" +
    // "mkdir /opt/mailinabox/dovecot \n" +
    // "mkdir /opt/mailinabox/spamassassin \n" +
    // "mkdir /opt/mailinabox/opendkim \n" +
    // "mkdir /opt/mailinabox/opendmarc \n" +
    // "mkdir /opt/mailinabox/management \n" +
    // "mkdir /opt/mailinabox/syslog \n" +
    // "mkdir /opt/mailinabox/setup \n" +
    "set e" +
    "cd /opt/mailinabox \n" +
    "cat <<EOF >> /opt/mailinabox/.env \n" +`
PRIMARY_HOSTNAME=[dnsName]
PUBLIC_IP=[ipAddress]
SYSLOG_SERVER=
CSR_COUNTRY=US
`+ 
"\n" +
     "EOF\n" +
    "cat <<EOF >> /opt/mailinabox/docker-compose.yml \n" +`
postfix:
build: postfix
env_file: .env
volumes:
- ./data/ssl:/home/data/ssl
- ./data/mail:/home/data/mail
- /tmp/syslogdev/log:/dev/log
links:
- syslog
- postgrey
- dovecot
- spamassassin
- opendkim
- opendmarc
ports:
- '[ipAddress]:25:25'
- '[ipAddress]:587:587'

postgrey:
build: postgrey
volumes:
- /tmp/syslogdev/log:/dev/log
links:
- syslog

dovecot:
build: dovecot
environment:
# FIXME Find a way to get this working with links instead
SPAMASSASSIN_1_PORT_10025_TCP_ADDR: [ipAddress]
volumes:
- ./data/ssl:/home/data/ssl
- ./data/mail:/home/data/mail
- ./data/doveadm:/home/data/doveadm
- /tmp/syslogdev/log:/dev/log
## FIXME Don't do this
#- ./data/dovecot-run:/var/run/dovecot
links:
- syslog
# FIXME Find a way to get this working with links instead
#- spamassassin
ports:
- '[ipAddress]:993:993'

spamassassin:
build: spamassassin
volumes:
- ./data/mail/spamassassin:/home/data/mail/spamassassin
- /tmp/syslogdev/log:/dev/log
links:
- syslog
- dovecot
# Make it accessible for spamc from dovecot
# FIXME Find a way to get this working with links instead
ports:
- '[ipAddress]:10025:10025'

opendkim:
build: opendkim
volumes:
- ./data/etc/opendkim:/etc/opendkim
- ./data/mail/dkim:/home/data/mail/dkim
- /tmp/syslogdev/log:/dev/log
links:
- syslog

opendmarc:
build: opendmarc
volumes:
- /tmp/syslogdev/log:/dev/log
links:
- syslog

management:
build: management
env_file: .env
environment:
DEBUG: 1
volumes:
- ./data/etc/nginx/conf.d:/etc/nginx/conf.d
- ./data/etc/opendkim:/etc/opendkim
- ./data/dns:/home/data/dns
- ./data/mail:/home/data/mail
- ./data/doveadm:/home/data/doveadm
- ./data/mailinabox:/var/lib/mailinabox
- ./data/ssl:/home/data/ssl
- /tmp/syslogdev/log:/dev/log
## FIXME Don't do this
#- ./data/dovecot-run:/var/run/dovecot
links:
- syslog
- dovecot
- opendkim
ports:
# FIXME Remove when reverse proxy is there
- '[ipAddress]:10222:10222'

syslog:
build: syslog
env_file: .env
volumes:
- /tmp/syslogdev:/dev
- log:/var/log

setup:
build: setup
env_file: .env
volumes:
- ./data/ssl:/home/data/ssl
- ./data/doveadm:/home/data/doveadm
- ./data/dns:/home/data/dns
- /dev/urandom:/dev/random
`
+ "\n" +
    "EOF\n" +
    "cd /opt/mailinabox \n" +
    "docker-compose up -d \n" +
    "echo your public ip is now: `wget -qO- ifconfig.me` \n" +
    "echo Admin dashboard is available on http port 8081 \n" +
    "\n" 
  },
  {
    info_url: '',
emoji: '💰',
    value: 'Goteo (Alpha)',
    description:
    "sudo wg-quick down wg0 \n" + 
    "sudo apt-get -yq install docker-compose \n" +
    "mkdir ~/goteo \n" +
    "mkdir ~/goteo/goteo_data \n" +
    "mkdir ~/goteo/goteo_db_data \n" +
    "cd ~/goteo \n" +
    "cat <<EOF >> ~/goteo/docker-compose.yml \n" +`          
###############################################################################
#                          Generated on docker.io                          #
###############################################################################
version: "3.1"
services:
mailhog:
image: mailhog/mailhog:latest
container_name: goteo-mailhog
logging:
 driver: none
ports:
 - '[ipAddress]:8082:8025'
 - '[ipAddress]:1025:1025'

mariadb:
image: mariadb:10.2
container_name: goteo-mariadb
working_dir: /application
volumes:
 - .:/application
 - goteo-mariadb:/var/lib/mysql
environment:
 - MYSQL_ROOT_PASSWORD=crowdfunding
 - MYSQL_DATABASE=goteo
 - MYSQL_USER=goteo
 - MYSQL_PASSWORD=goteo
ports:
 - "3307:3306"

webserver:
image: nginx:alpine
container_name: goteo-webserver
working_dir: /application
volumes:
 - .:/application
 - ./docker/nginx/nginx.conf:/etc/nginx/conf.d/default.conf
ports:
 - '[ipAddress]:8081:80'
 - "8443:443"
 - "35729:35729"

geoip:
image: igortimoshenko/docker-maxmind-geoip
container_name: goteo-geoip
volumes:
 - goteo-geoip:/usr/share/GeoIP

php:
build: docker/php
container_name: goteo-php
working_dir: /application
volumes:
 - .:/application
 - ./docker/php/php-ini-overrides.ini:/etc/php/7.4/fpm/conf.d/99-overrides.ini
 - goteo-geoip:/usr/share/GeoIP
environment:
 - GOTEO_CONFIG_FILE
 - DEBUG
 - UID
command: ./docker/php/init.sh

volumes:
goteo-mariadb:
goteo-geoip:
` + "\n" +
"EOF\n" +
"cd /opt/goteo \n" +
"docker-compose up -d \n" +
"echo your public ip is now: `wget -qO- ifconfig.me` \n" +
"echo Admin dashboard is available on http port 8081 \n" +
"\n"
  },
  {
    info_url: '',
emoji: '📁',
    value: 'NextCloud (Transparent only) (Alpha)',
    description:
    "sudo wg-quick down wg0 \n" + 
         "sudo apt-get -yq install docker-compose \n" +
         "mkdir ~/nextcloud \n" +
         "mkdir ~/nextcloud/nc_data \n" +
         "mkdir ~/nextcloud/db_data \n" +
         "mkdir ~/nextcloud/nc_web \n" +
         "cd ~/nextcloud \n" +
         "cat <<EOF >> ~/nextcloud/docker-compose.yml \n" +`
version: '3.3'
services:
     app:
       image: nextcloud:fpm-alpine
       container_name: nextcloud-app
       restart: unless-stopped
       volumes:
         - ./nc_web:/var/www/html
         - ./nc_data:/var/www/html/data
         - /etc/localtime:/etc/localtime:ro
         - /etc/timezone:/etc/timezone:ro
       networks:
         - nextcloud
       environment:
         - NEXTCLOUD_TRUSTED_DOMAINS=[dnsName]
         - NEXTCLOUD_ADMIN_USER=admin
         - NEXTCLOUD_ADMIN_PASSWORD=changeme
         - POSTGRES_HOST=nextcloud-postgres
         - REDIS_HOST=nextcloud-redis
       depends_on:
         - postgres_db
         - redis

     web:
       image: nextcloud:latest
       container_name: nextcloud-web
       restart: unless-stopped
       ports:
         - '[ipAddress]:8088:80'
       volumes:
         - ./nc_web:/var/www/html
         - /etc/localtime:/etc/localtime:ro
         - /etc/timezone:/etc/timezone:ro
       networks:
         - nextcloud
       depends_on:
         - app
         - cron
         - postgres_db

     cron:
       image: nextcloud:fpm-alpine
       container_name: nextcloud-cron
       restart: unless-stopped
       volumes:
         - ./nc_web:/var/www/html
         - /etc/localtime:/etc/localtime:ro
         - /etc/timezone:/etc/timezone:ro
       networks:
         - nextcloud
       entrypoint: /cron.sh
       depends_on:
         - postgres_db
         - redis

     postgres_db:
       image: postgres:alpine
       container_name: nextcloud-postgres
       restart: unless-stopped
       volumes:
         - ./nc_web:/var/www/html
         - ./db_data:/var/lib/postgresql/data
         - /etc/localtime:/etc/localtime:ro
         - /etc/timezone:/etc/timezone:ro
       networks:
         - nextcloud
       environment:
         - MYSQL_PASSWORD=trustedChangeIfNot
         - MYSQL_DATABASE=nextcloud
         - MYSQL_USER=nextcloud
         - POSTGRES_HOST_AUTH_METHOD=trust
     redis:
       image: redis:alpine
       container_name: nextcloud-redis
       networks:
         - nextcloud
       restart: unless-stopped

     collabora:
       image: collabora/code
       container_name: nextcloud-collabora
       restart: unless-stopped
       networks:
         - nextcloud
       ports:
         - 9980:9980
       environment:
         - domain=[dnsName]
         - VIRTUAL_HOST=[dnsName]
         - VIRTUAL_PORT=9980
         - VIRTUAL_PROTO=https
         - dictionaries='de_DE en_GB en_US nl_NL'
         - extra_params='--o:ssl.enable=false'
       cap_add:
         - MKNOD
       depends_on:
         - app
       tty: true

     coturn:
       image: instrumentisto/coturn
       container_name: nextcloud-coturn
       restart: unless-stopped
       ports:
         - '[ipAddress]:3478:3478/tcp'
         - '[ipAddress]:3478:3478/udp'
       networks:
         - nextcloud
       command:
         - -n
         - --log-file=stdout
         - --min-port=49160
         - --max-port=49200
         - --realm=[dnsName]
         - --use-auth-secret
         - --static-auth-secret=changemenow
       depends_on:
         - app
volumes:
   nextcloud-volume:

networks:
   nextcloud:
` + "\n" +
         "EOF\n" +
         "sudo wg-quick up wg0 \n" + 
         "docker-compose up -d \n" +
         "\n"
  },
  {
    info_url: '',
emoji: '✏️',
    value: 'PenPot',
    description:
    "apt-get -yq install docker-compose \n" +
    "mkdir /opt/penpot \n" +
    "cd /opt/penpot \n" +
    "cat <<EOF >> /opt/penpot/config.env \n" +
    `
    ## Should be set to the public domain where penpot is going to be served.
    ##
    ## NOTE: If you are going to serve it under different domain than
    ## 'localhost' without HTTPS, consider setting the
    ## 'disable-secure-session-cookies' flag on the 'PENPOT_FLAGS'
    ## setting.
    
    PENPOT_PUBLIC_URI=[dnsName]
    PENPOT_TENANT=pro
    
    ## Feature flags.
    
    PENPOT_FLAGS="enable-registration enable-login"
    
    ## Temporal workaround because of bad builtin default
    
    PENPOT_HTTP_SERVER_HOST=0.0.0.0
    
    ## Standard database connection parameters (only postgresql is supported):
    
    PENPOT_DATABASE_URI=postgresql://penpot-postgres/penpot
    PENPOT_DATABASE_USERNAME=penpot
    PENPOT_DATABASE_PASSWORD=penpot
    
    ## Redis is used for the websockets notifications.
    
    PENPOT_REDIS_URI=redis://penpot-redis/0
    
    ## By default, files uploaded by users are stored in local
    ## filesystem. But it can be configured to store in AWS S3.
    
    PENPOT_ASSETS_STORAGE_BACKEND=assets-fs
    PENPOT_STORAGE_ASSETS_FS_DIRECTORY=/opt/data/assets
    
    ## Telemetry. When enabled, a periodical process will send anonymous
    ## data about this instance. Telemetry data will enable us to learn on
    ## how the application is used, based on real scenarios. If you want
    ## to help us, please leave it enabled.
    
    PENPOT_TELEMETRY_ENABLED=true   
    
    ## Email sending configuration. By default, emails are printed in the
    ## console, but for production usage is recommended to setup a real
    ## SMTP provider. Emails are used to confirm user registrations.
    
    PENPOT_SMTP_ENABLED=false
    PENPOT_SMTP_DEFAULT_FROM=
    PENPOT_SMTP_DEFAULT_REPLY_TO=
    PENPOT_SMTP_HOST=0.0.0.0
    PENPOT_SMTP_PORT=25
    PENPOT_SMTP_USERNAME=
    PENPOT_SMTP_PASSWORD
    PENPOT_SMTP_TLS=true
    PENPOT_SMTP_SSL=false
    
    ## Comma separated list of allowed domains to register. Empty to allow
    ## all.
    
    # PENPOT_REGISTRATION_DOMAIN_WHITELIST=""
    
    ## Authentication providers
    
    ## Google
    
    # PENPOT_GOOGLE_CLIENT_ID=
    # PENPOT_GOOGLE_CLIENT_SECRET=
    
    ## GitHub
    
    # PENPOT_GITHUB_CLIENT_ID=
    # PENPOT_GITHUB_CLIENT_SECRET=
    
    ## GitLab
    
    # PENPOT_GITLAB_BASE_URI=https://gitlab.com
    # PENPOT_GITLAB_CLIENT_ID=
    # PENPOT_GITLAB_CLIENT_SECRET=
    
    ## OpenID Connect (since 1.5.0)
    
    # PENPOT_OIDC_BASE_URI=
    # PENPOT_OIDC_CLIENT_ID=
    # PENPOT_OIDC_CLIENT_SECRET=
    
    ## LDAP
    ##
    ## NOTE: to enable ldap, you will need to put 'enable-login-with-ldap'
    ## on the 'PENPOT_FLAGS' environment variable.
    
    # PENPOT_LDAP_HOST=ldap
    # PENPOT_LDAP_PORT=10389
    # PENPOT_LDAP_SSL=false
    # PENPOT_LDAP_STARTTLS=false
    # PENPOT_LDAP_BASE_DN=ou=people,dc=planetexpress,dc=com
    # PENPOT_LDAP_BIND_DN=cn=admin,dc=planetexpress,dc=com
    # PENPOT_LDAP_BIND_PASSWORD=GoodNewsEveryone
    # PENPOT_LDAP_ATTRS_USERNAME=uid
    # PENPOT_LDAP_ATTRS_EMAIL=mail
    # PENPOT_LDAP_ATTRS_FULLNAME=cn
    `+ "\n" +
     "EOF\n" +
    "cat <<EOF >> /opt/penpot/docker-compose.yml \n" +`
    ---
    version: "3.5"
    
    networks:
      penpot:
    
    volumes:
      penpot_postgres_data:
      penpot_assets_data:
    
    services:
      penpot-frontend:
        image: "penpotapp/frontend:latest"
        ports:
          - "[ipAddress]:9001:80"
    
        volumes:
          - penpot_assets_data:/opt/data
    
        env_file:
          - config.env
          
        depends_on:
          - penpot-backend
          - penpot-exporter
    
        networks:
          - penpot
    
      penpot-backend:
        image: "penpotapp/backend:latest"
        volumes:
          - penpot_assets_data:/opt/data
    
        depends_on:
          - penpot-postgres
          - penpot-redis
    
        env_file:
          - config.env
    
        networks:
          - penpot
    
      penpot-exporter:
        image: "penpotapp/exporter:latest"
        env_file:
          - config.env
        environment:
          # Don't touch it; this uses internal docker network to
          # communicate with the frontend.
          - PENPOT_PUBLIC_URI=http://penpot-frontend
        networks:
          - penpot
    
      penpot-postgres:
        image: "postgres:13"
        restart: always
        stop_signal: SIGINT
    
        environment:
          - POSTGRES_INITDB_ARGS=--data-checksums
          - POSTGRES_DB=penpot
          - POSTGRES_USER=penpot
          - POSTGRES_PASSWORD=penpot
    
        volumes:
          - penpot_postgres_data:/var/lib/postgresql/data
    
        networks:
          - penpot
    
      penpot-redis:
        image: redis:6
        restart: always
        networks:
          - penpot
    `+ "\n" +
     "EOF\n" +
     "sudo wg-quick up wg0 \n" + 
     "docker-compose up -d \n" +
     "\n"
  }
];


// const gridStyle = { 
  
//   //minWidth: 100, 
//   height: "80vh", 
//   marginTop: 20 

// };

  const useStyles = createStyles((theme) => ({
  button: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  menuControl: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: 0,
    borderLeft: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white}`,
  },
  
}));
async function dlWGScriptFile(value){

  var blob = new Blob([value], {type: "text/plain;charset=utf-8"});

  FileSaver.saveAs(blob, "io.setip.peer.conf");
  
}
export default function ConfTable({ columns, data, dns, app_data, email }) {

  //console.log(JSON.stringify(data));
  const clipboard = useClipboard();
  
  const [appList, setAppList] = useState([]);

  useEffect(() => {
    // Fetch the app list from the API
    fetch("https://setip.io/api/poc/apps/scrtmpl")
      .then(response => response.json())
      .then(data => {
        setAppList(data);
      })
      .catch(error => {
        console.error("Error fetching app list:", error);
      });
  }, []);
  
  
  const { classes, theme } = useStyles();
  const menuIconColor = theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 5 : 6];




  const rows = data.map((element) => {
    // const app_list_merged = app_list.map((item) => {
    //   let replace = item.description.replace("[ipAddress]",element.conf.wgInterface.address)
    //   item.description = replace;
    //   return item;
    // });
    const items = appList.map((item) => {
      let replace = item.description.replaceAll("[ipAddress]",element.conf.wgInterface.address);
      replace = replace.replaceAll("[dnsName]",dns);
      replace = replace.replaceAll("[contactEmail]",email);
       return (<Accordion.Item key={item.value} value={item.value}>
        <Accordion.Control icon={item.emoji}>{item.value}</Accordion.Control>
        <Accordion.Panel>
          {/* {item.description} */}
          <Stack
          //  h={200}
      // bg="var(--mantine-color-body)"
      align="flex-start"
      gap="sm">
          {item["info_url"] && item["info_url"]!=="" && 
          <>
          <Anchor href={item["info_url"]} target="_blank">Tap for Info </Anchor>
          </>}

          <CopyButton value={replace}>
              {({ copied, copy }) => (
                <Button leftsection={<IconCopy size={14} />} variant="default"  color={copied ? 'teal' : 'blue'} onClick={copy}>
                  {copied ? 'Copied script' : 'Copy script'}
                </Button>
              )}
        </CopyButton>
          </Stack>
          {/* <Button
          variant="light"
  
          rightIcon={
            clipboard.copied ? (
              <IconCheck size={20} stroke={1.5} />
            ) : (
              <IconCopy size={20} stroke={1.5} />
            )
          }
          radius="sm"
          styles={{
            root: { paddingRight: 14, height: 48 },
            rightIcon: { marginLeft: 22 },
          }}
          onClick={() => (clipboard.copy( item.description ))}
        >
  <Text></Text>
        </Button> */}
          </Accordion.Panel>
      </Accordion.Item>)
  });

return (
    <tr key={element.id}>
   <td><code>{element.id}</code></td>
    <td><code>{element.url_tip}</code></td>
    <td><code>{element.conf.wgInterface.address}</code></td>
    <td><span>


   
    <CopyButton value={ element.conf_a}>
            {({ copied, copy }) => (
              <Button leftsection={<IconCopy size={14} />} variant="default" color={copied ? 'teal' : 'blue'} onClick={copy}>
                {copied ? 'Copied config' : 'Copy config'}
              </Button>
            )}
      </CopyButton>

    {/* <Button
        variant="light"
        rightIcon={
          clipboard.copied ? (
            <IconCheck size={20} stroke={1.5} />
          ) : (
            <IconCopy size={20} stroke={1.5} />
          )
        }
        radius="sm"
        styles={{
          root: { paddingRight: 14, height: 48 },
          rightIcon: { marginLeft: 22 },
        }}
        onClick={() => (clipboard.copy( element.conf_a ))}
      >
<h5 title={element.name}> .{element.id}</h5>
      </Button> */}
   

    
    </span>
    </td>
    <td>
        <span>
        <CopyButton value={"echo your public ip is now: `wget -qO- ifconfig.me` \n" +
                "wg-quick down wg0 \n" +
                "rm /etc/resolv.conf \n" +
                "systemctl stop systemd-resolved.service \n" +
                "systemctl disable systemd-resolved.service \n" +
                "echo nameserver 8.8.8.8 >> /etc/resolv.conf \n" +
                "echo nameserver 1.1.1.1 >> /etc/resolv.conf \n" +
                "echo nameserver 8.8.8.8 >> /run/systemd/resolve/stub-resolv.conf \n" +
                "echo nameserver 1.1.1.1 >> /run/systemd/resolve/stub-resolv.conf \n" +
                "apt-get update  -q -y \n" +
                "apt-get install wireguard-tools gpg qrencode grepcidr openresolv  -q -y \n" +
                "cat <<EOF >> /etc/wireguard/wg0.conf \n" +
                element.conf_a + "\n" +
                "EOF\n" +
                "wg-quick up wg0 \n" +
                "echo your public ip is now: `wget -qO- ifconfig.me` \n" +
                "echo Login to https://setip.io to configure access to this host. \n" +
                "\n"}>
            {({ copied, copy }) => (
              <Button leftsection={<IconCopy size={14} />} variant="default" color={copied ? 'teal' : 'blue'} onClick={copy}>
                {copied ? 'Copied script' : 'Copy script'}
              </Button>
            )}
      </CopyButton>
        {/* <Button
                variant="light"
                rightIcon={
                  clipboard.copied ? (
                    <IconCheck size={20} stroke={1.5} />
                  ) : (
                    <IconCopy size={20} stroke={1.5} />
                  )
                }
                radius="sm"
                size="sm"
                styles={{
                  root: { paddingRight: 14, height: 48 },
                  rightIcon: { marginLeft: 22 },
                }}
                onClick={() => (clipboard.copy( 
                "echo your public ip is now: `wget -qO- ifconfig.me` \n" +
                "wg-quick down wg0 \n" +
                "rm /etc/resolv.conf \n" +
                "systemctl stop systemd-resolved.service \n" +
                "systemctl disable systemd-resolved.service \n" +
                "echo nameserver 8.8.8.8 >> /etc/resolv.conf \n" +
                "echo nameserver 1.1.1.1 >> /etc/resolv.conf \n" +
                "echo nameserver 8.8.8.8 >> /run/systemd/resolve/stub-resolv.conf \n" +
                "echo nameserver 1.1.1.1 >> /run/systemd/resolve/stub-resolv.conf \n" +
                "apt-get update  -q -y \n" +
                "apt-get install wireguard-tools gpg qrencode grepcidr openresolv  -q -y \n" +
                "cat <<EOF >> /etc/wireguard/wg0.conf \n" +
                element.conf_a + "\n" +
                "EOF\n" +
                "wg-quick up wg0 \n" +
                "echo your public ip is now: `wget -qO- ifconfig.me` \n" +
                "echo Login to https://setip.io to configure access to this host. \n" +
                "\n" ))}
              >
        <h5 title="{element.id}">.{element.id}</h5>
              </Button> */}
        </span>
    </td>  
    
    <td>
       <span>
       <Button
       
               variant="light"
               rightIcon={<IconDownload size={20} stroke={3} />}
               radius="sm"
               styles={{
                 root: { paddingRight: 14, height: 48 },
                 rightIcon: { marginLeft: 22 },
               }}
               onClick={() => (dlWGScriptFile( element.conf_a ))}
             >
       {/* <h5 title={element.name}> .{element.id}</h5> */}
             </Button>
       </span>
    </td> 
    
    <td>
     
    {/* <Table.ScrollContainer minWidth={500}>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Element position</Table.Th>
            <Table.Th>Element name</Table.Th>
            <Table.Th>Symbol</Table.Th>
            <Table.Th>Atomic mass</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{apps}</Table.Tbody>
      </Table>
    </Table.ScrollContainer> */}
    
     <Menu  trigger="hover" openDelay={100} closeDelay={400} transition="pop-top-right" position="top-end" width={220}
     >
     
     
      <Menu.Target>
    
        <ActionIcon>
          <IconDots size={16} stroke={3} />
        </ActionIcon>
      </Menu.Target>
      
      <Menu.Dropdown>
      <ScrollArea w={400} h={250} type="auto">
<Paper>
<Accordion defaultValue="Apples">
      {items}
    </Accordion>
</Paper>

    </ScrollArea>

      
      </Menu.Dropdown>
    </Menu> 
    </td>
    </tr>
)
});
  
  return (
    // <ScrollArea w={400} h={500} >
    <Table>
      <thead>
          <tr>

          <th><span>Peer</span></th>
          <th><span>Auto URL</span></th>
          <th><span>Private IP</span></th>
          <th><span>Wireguard Config</span></th>
          
          <th><span>Wireguard install + Config</span></th>
          <th><span>Wireguard config download</span></th>
          <th><span>Apps</span></th>
         </tr>
      </thead>
      
      <Tooltip
        label="Copied!"
        offset={5}
        position="bottom"
        radius="xl"
        transition="slide-down"
        transitionDuration={100}
        opened={clipboard.copied}
      >
      <tbody>
        
        {rows}
    
      </tbody>
      </Tooltip>
    </Table>
    // </ScrollArea>
 

  );

}
  

