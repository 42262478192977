import React, { useContext,useEffect } from "react";

import MarkdownPreview from '@uiw/react-markdown-preview';

import { showLeftSideState } from "../recoil_state";

import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import {
  Container,
  Title,
  Box,
  createStyles,
  Paper,
  rem,
  Text,
  Stack,
} from "@mantine/core";
import { useRef } from "react";
import Autoplay from "embla-carousel-autoplay";
import { Carousel } from "@mantine/carousel";
// import timeLineAnimated from "../assets/TimelineAnimated.gif";

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.sm * 2,
    paddingBottom: theme.spacing.sm * 2,
    minHeight: 650,
  },

  title: {
    marginBottom: theme.spacing.xl * 1.5,
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,

    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

export default function ServiceAgreement() {
  // const { classes } = useStyles();
  const [showLeftSide, setShowLeftSide] = useRecoilState(showLeftSideState);

  useEffect(() => {
    //console.log("setShowLeftSide true");
    setShowLeftSide(true);
  });


//https://github.com/CommonPaper/CSA/blob/main/CSA.md
  const cloudserviceagreement = `
  # Cloud Service Agreement

1. <span class="header_2" id="1">Service</span>
    1. <span class="header_3" id="1.1">Access and Use.</span>  During the <span class="orderform_link">Subscription Period</span> and subject to the terms of this Agreement, <span class="coverpage_link">Customer</span> may (a) access and use the Cloud Service; and (b) copy and use the included Software and Documentation only as needed to access and use the Cloud Service, in each case, for its internal business purposes. If a <span class="coverpage_link">Customer</span> Affiliate enters a separate Order Form with <span class="coverpage_link">Provider</span>, the <span class="coverpage_link">Customer’s</span> Affiliate creates a separate agreement between <span class="coverpage_link">Provider</span> and that Affiliate, where <span class="coverpage_link">Provider’s</span> responsibility to the Affiliate is individual and separate from <span class="coverpage_link">Customer</span> and <span class="coverpage_link">Customer</span> is not responsible for its Affiliates’ agreement.
    2. <span class="header_3" id="1.2">Support.</span>  During the <span class="orderform_link">Subscription Period</span>, <span class="coverpage_link">Provider</span> will provide <span class="orderform_link">Technical Support</span> as described in the Order Form.
    3. <span class="header_3" id="1.3">User Accounts.</span>  <span class="coverpage_link">Customer</span> is responsible for all actions on Users’ accounts and for all Users’ compliance with this Agreement. <span class="coverpage_link">Customer</span> and Users must protect the confidentiality of their passwords and login credentials. <span class="coverpage_link">Customer</span> will promptly notify <span class="coverpage_link">Provider</span> if it suspects or knows of any fraudulent activity with its accounts, passwords, or credentials, or if they become compromised.
    4. <span class="header_3" id="1.4">Feedback and Usage Data.</span>  <span class="coverpage_link">Customer</span> may, but is not required to, give <span class="coverpage_link">Provider</span> Feedback, in which case <span class="coverpage_link">Customer</span> gives Feedback "AS IS". <span class="coverpage_link">Provider</span> may use all Feedback freely without any restriction or obligation. In addition, <span class="coverpage_link">Provider</span> may collect and analyze Usage Data, and <span class="coverpage_link">Provider</span> may freely use Usage Data to maintain, improve, enhance, and promote <span class="coverpage_link">Provider’s</span> products and services without restriction or obligation. However, <span class="coverpage_link">Provider</span> may only disclose Usage Data to others if the Usage Data is aggregated and does not identify <span class="coverpage_link">Customer</span> or Users.
    5. <span class="header_3" id="1.5">Customer Content.</span>  <span class="coverpage_link">Provider</span> may copy, display, modify, and use Customer Content only as needed to provide and maintain the Product and related offerings. <span class="coverpage_link">Customer</span> is responsible for the accuracy and content of Customer Content.
    6. <span class="header_3" id="1.6">Machine Learning.</span>  Usage Data and Customer Content may be used to develop, train, or enhance artificial intelligence or machine learning models that are part of <span class="coverpage_link">Provider’s</span> products and services, including third-party components of the Product, and <span class="coverpage_link">Customer</span> authorizes <span class="coverpage_link">Provider</span> to process its Usage Data and Customer Content for such purposes. However, (a) Usage Data and Customer Content must be aggregated before it can be used for these purposes, and (b) <span class="coverpage_link">Provider</span> will use commercially reasonable efforts consistent with industry standard technology to de-identify Usage Data and Customer Content before such use. Nothing in this section will reduce or limit <span class="coverpage_link">Provider’s</span> obligations regarding Personal Data that may be contained in Usage Data or Customer Content under Applicable Data Protection Laws. Due to the nature of artificial intelligence and machine learning, information generated by these features may be incorrect or inaccurate. Product features that include artificial intelligence or machine learning models are not human and are not a substitute for human oversight.

2. <span class="header_2" id="2">Restrictions & Obligations</span>
    1. <span class="header_3" id="2.1">Restrictions on Customer.</span>
        a. Except as expressly permitted by this Agreement, <span class="coverpage_link">Customer</span> will not (and will not allow anyone else to): (i) reverse engineer, decompile, or attempt to discover any source code or underlying ideas or algorithms of the Product (except to the extent Applicable Laws prohibit this restriction); (ii) provide, sell, transfer, sublicense, lend, distribute, rent, or otherwise allow others to access or use the Product; (iii) remove any proprietary notices or labels; (iv) copy, modify, or create derivative works of the Product; (v) conduct security or vulnerability tests on, interfere with the operation of, cause performance degradation of, or circumvent access restrictions of the Product; (vi) access accounts, information, data, or portions of the Product to which <span class="coverpage_link">Customer</span> does not have explicit authorization; (vii) use the Product to develop a competing service or product; (viii) use the Product with any High Risk Activities or with any activity prohibited by Applicable Laws; (ix) use the Product to obtain unauthorized access to anyone else’s networks or equipment; or (x) upload, submit, or otherwise make available to the Product any Customer Content to which <span class="coverpage_link">Customer</span> and Users do not have the proper rights.
        b. Use of the Product must comply with all Documentation and <span class="orderform_link">Use Limitations</span>.
    2. <span class="header_3" id="2.2">Suspension.</span>  If <span class="coverpage_link">Customer</span> (a) has an outstanding, undisputed balance on its account for more than 30 days; (b) breaches Section 2.1 (Restrictions on Customer); or (c) uses the Product in violation of the Agreement or in a way that materially and negatively impacts the Product or others, then <span class="coverpage_link">Provider</span> may temporarily suspend <span class="coverpage_link">Customer’s</span> access to the Product with or without notice. However, <span class="coverpage_link">Provider</span> will try to inform <span class="coverpage_link">Customer</span> before suspending <span class="coverpage_link">Customer’s</span> account when practical. <span class="coverpage_link">Provider</span> will reinstate <span class="coverpage_link">Customer’s</span> access to the Product only if <span class="coverpage_link">Customer</span> resolves the underlying issue.

3. <span class="header_2" id="3">Privacy & Security</span>
    1. <span class="header_3" id="3.1">Personal Data.</span>  Before submitting Personal Data governed by GDPR, <span class="coverpage_link">Customer</span> must enter into a data processing agreement with <span class="coverpage_link">Provider</span>. If the parties have a <span class="keyterms_link">DPA</span>, each party will comply with its obligations in the <span class="keyterms_link">DPA</span>, the terms of the <span class="keyterms_link">DPA</span> will control each party’s rights and obligations as to Personal Data, and the terms of the <span class="keyterms_link">DPA</span> will control in the event of any conflict with this Agreement.
    2. <span class="header_3" id="3.2">Prohibited Data.</span>  <span class="coverpage_link">Customer</span> will not (and will not allow anyone else to) submit Prohibited Data to the Product unless authorized by the Order Form or Key Terms.

4. <span class="header_2" id="4">Payment & Taxes</span>
    1. <span class="header_3" id="4.1">Fees.</span>  Unless the Order Form specifies a different currency, all Fees are in U.S. Dollars and are exclusive of taxes. Except for the prorated refund of prepaid Fees allowed with specific termination rights given in the Agreement, Fees are non-refundable.
    2. <span class="header_3" id="4.2">Invoicing.</span>  For a <span class="orderform_link">Payment Process</span> with invoicing, <span class="coverpage_link">Provider</span> will send invoices for usage-based Fees in arrears and for all other Fees in advance, in each case according to the <span class="orderform_link">Payment Process</span>.
    3. <span class="header_3" id="4.3">Automatic Payment.</span>  For a <span class="orderform_link">Payment Process</span> with automatic payment, <span class="coverpage_link">Provider</span> will automatically charge the credit card, debit card, or other payment method on file for Fees according to the <span class="orderform_link">Payment Process</span> and <span class="coverpage_link">Customer</span> authorizes all such charges. In this case, <span class="coverpage_link">Provider</span> will make a copy of <span class="coverpage_link">Customer’s</span> bills or transaction history available to <span class="coverpage_link">Customer</span>.
    4. <span class="header_3" id="4.4">Taxes.</span>  <span class="coverpage_link">Customer</span> is responsible for all duties, taxes, and levies that apply to Fees, including sales, use, VAT, GST, or withholding, that <span class="coverpage_link">Provider</span> itemizes and includes in an invoice. However, <span class="coverpage_link">Customer</span> is not responsible for <span class="coverpage_link">Provider’s</span> income taxes.
    5. <span class="header_3" id="4.5">Payment.</span>  <span class="coverpage_link">Customer</span> will pay <span class="coverpage_link">Provider</span> Fees and taxes in U.S. Dollars, unless the Order Form specifies a different currency, according to the <span class="orderform_link">Payment Process</span>.
    6. <span class="header_3" id="4.6">Payment Dispute.</span>  If <span class="coverpage_link">Customer</span> has a good-faith disagreement about the Fees charged or invoiced, <span class="coverpage_link">Customer</span> must notify <span class="coverpage_link">Provider</span> about the dispute before payment is due, or within 30 days of an automatic payment, and must pay all undisputed amounts on time. The parties will work together to resolve the dispute within 15 days. If no resolution is agreed, each party may pursue any remedies available under the Agreement or Applicable Laws.

5. <span class="header_2" id="5">Term & Termination</span>
    1. <span class="header_3" id="5.1">Order Form and Agreement.</span>  For each Order Form, the Agreement will start on the <span class="orderform_link">Order Date</span>, continue through the <span class="orderform_link">Subscription Period</span>, and automatically renew for additional <span class="orderform_link">Subscription Periods</span> unless one party gives notice of non-renewal to the other party before the <span class="orderform_link">Non-Renewal Notice Date</span>.
    2. <span class="header_3" id="5.2">Framework Terms.</span>  These Framework Terms will start on the <span class="keyterms_link">Effective Date</span> and continue for the longer of one year or until all Order Forms governed by the Framework Terms have ended.
    3. <span class="header_3" id="5.3">Termination.</span>  Either party may terminate the Framework Terms or an Order Form immediately:
        a. <span id="5.3.a">if</span> the other party fails to cure a material breach of the Framework Terms or an Order Form following 30 days notice;
        b. <span id="5.3.b">upon</span> notice if the other party (i) materially breaches the Framework Terms or an Order Form in a manner that cannot be cured; (ii) dissolves or stops conducting business without a successor; (iii) makes an assignment for the benefit of creditors; or (iv) becomes the debtor in insolvency, receivership, or bankruptcy proceedings that continue for more than 60 days.
    4. <span class="header_3" id="5.4">Force Majeure.</span>  Either party may terminate an affected Order Form upon notice if a Force Majeure Event prevents the Product from materially operating for 30 or more consecutive days. <span class="coverpage_link">Provider</span> will pay to <span class="coverpage_link">Customer</span> a prorated refund of any prepaid Fees for the remainder of the <span class="orderform_link">Subscription Period</span>. A Force Majeure Event does not excuse <span class="coverpage_link">Customer’s</span> obligation to pay Fees accrued prior to termination.
    5. <span class="header_3" id="5.5">Effect of Termination.</span>  Termination of the Framework Terms will automatically terminate all Order Forms governed by the Framework Terms. Upon any expiration or termination:
        a. <span class="coverpage_link" id="5.5.a">Customer</span> will no longer have any right to use the Product.
        b. Upon <span class="coverpage_link" id="5.5.b">Customer’s</span> request, <span class="coverpage_link">Provider</span> will delete Customer Content within 60 days.
        c. <span id="5.5.c">Each</span> Recipient will return or destroy Discloser’s Confidential Information in its possession or control.
        d. <span class="coverpage_link" id="5.5.d">Provider</span> will submit a final bill or invoice for all outstanding Fees accrued before termination and <span class="coverpage_link">Customer</span> will pay the invoice according to Section 4 (Payment & Taxes).
    6. <span class="header_3" id="5.6">Survival.</span>
        a. <span id="5.6.a">The</span> following sections will survive expiration or termination of the Agreement: Section 1.4 (Feedback and Usage Data), Section 1.6 (Machine Learning), Section 2.1 (Restrictions on Customer), Section 4 (Payment & Taxes) for Fees accrued or payable before expiration or termination, Section 5.5 (Effect of Termination), Section 5.6 (Survival), Section 6 (Representations & Warranties), Section 7 (Disclaimer of Warranties), Section 8 (Limitation of Liability), Section 9 (Indemnification), Section 10 (Confidentiality), Section 11 (Reservation of Rights), Section 12 (General Terms), Section 13 (Definitions), and the portions of a Cover Page referenced by these sections.
        b. <span id="5.4.b">Each</span> Recipient may retain Discloser’s Confidential Information in accordance with its standard backup or record retention policies maintained in the ordinary course of business or as required by Applicable Laws, in which case Section 3 (Privacy & Security) and Section 10 (Confidentiality) will continue to apply to retained Confidential Information.

6. <span class="header_2" id="6">Representations & Warranties</span>
    1. <span class="header_3" id="6.1">Mutual.</span>  Each party represents and warrants to the other that: (a) it has the legal power and authority to enter into this Agreement; (b) it is duly organized, validly existing, and in good standing under the Applicable Laws of the jurisdiction of its origin; (c) it will comply with all Applicable Laws in performing its obligations or exercising its rights in this Agreement; and (d) it will comply with the <span class="keyterms_link">Additional Warranties</span>.
    2. <span class="header_3" id="6.2">From Customer.</span>  <span class="coverpage_link">Customer</span> represents and warrants that it, all Users, and anyone submitting Customer Content each have and will continue to have all rights necessary to submit or make available Customer Content to the Product and to allow the use of Customer Content as described in the Agreement.
    3. <span class="header_3" id="6.3">From Provider.</span>  <span class="coverpage_link">Provider</span> represents and warrants to <span class="coverpage_link">Customer</span> that it will not materially reduce the general functionality of the Cloud Service during the <span class="orderform_link">Subscription Period</span>.
    4. <span class="header_3" id="6.4">Provider Warranty Remedy.</span>  If <span class="coverpage_link">Provider</span> breaches the warranty in Section 6.3 (Representations & Warranties from Provider), <span class="coverpage_link">Customer</span> must give <span class="coverpage_link">Provider</span> notice (with enough detail for <span class="coverpage_link">Provider</span> to understand or replicate the issue) within 45 days of discovering the issue. Within 45 days of receiving sufficient details of the warranty issue, <span class="coverpage_link">Provider</span> will attempt to restore the general functionality of the Cloud Service. If <span class="coverpage_link">Provider</span> cannot resolve the issue, <span class="coverpage_link">Customer</span> may terminate the affected Order Form and <span class="coverpage_link">Provider</span> will pay to <span class="coverpage_link">Customer</span> a prorated refund of prepaid Fees for the remainder of the <span class="orderform_link">Subscription Period</span>. <span class="coverpage_link">Provider’s</span> restoration obligation, and <span class="coverpage_link">Customer’s</span> termination right, are <span class="coverpage_link">Customer’s</span> only remedies if <span class="coverpage_link">Provider</span> does not meet the warranty in Section 6.3 (Representations & Warranties from Provider).

7. <span class="header_2" id="7">Disclaimer of Warranties</span>
    1. <span class="coverpage_link" id="7.1">Provider</span> makes no guarantees that the Product will always be safe, secure, or error-free, or that it will function without disruptions, delays, or imperfections. The warranties in Section 6 (Representations & Warranties) do not apply to any misuse or unauthorized modification of the Product, nor to any product or service provided by anyone other than <span class="coverpage_link">Provider</span>. Except for the warranties in Section 6 (Representations & Warranties), <span class="coverpage_link">Provider</span> and <span class="coverpage_link">Customer</span> each **disclaim all other warranties and conditions, whether express or implied, including the implied warranties and conditions of merchantability, fitness for a particular purpose, title, and non-infringement. These disclaimers apply to the maximum extent permitted by Applicable Laws**.

8. <span class="header_2" id="8">Limitation of Liability</span>
    1. <span class="header_3" id="8.1">Liability Caps.</span>
        a. **<span id="8.1.a">Except</span> as provided in Section 8.4 (Exceptions), each party’s total cumulative liability for all claims arising out of or relating to this Agreement will not be more than the <span class="keyterms_link">General Cap Amount</span>.**
        b. **<span id="8.1.b">If</span> there are <span class="keyterms_link">Increased Claims</span>, each party’s total cumulative liability for all <span class="keyterms_link">Increased Claims</span> arising out of or relating to this Agreement will not be more than the <span class="keyterms_link">Increased Cap Amount</span>.**
    2. <span class="header_3" id="8.2">Damages Waiver.</span>  **Except as provided in Section 8.4 (Exceptions), under no circumstances will either party be liable to the other for lost profits or revenues (whether direct or indirect), or for consequential, special, indirect, exemplary, punitive, or incidental damages relating to this Agreement, even if the party is informed of the possibility of this type of damage in advance.**
    3. <span class="header_3" id="8.3">Applicability.</span>  **The limitations and waivers contained in Sections 8.1 (Liability Caps) and 8.2 (Damages Waiver) apply to all liability, whether in tort (including negligence), contract, breach of statutory duty, or otherwise.**
    4. <span class="header_3" id="8.4">Exceptions.</span>  The liability cap in Section 8.1(a) does not apply to any <span class="keyterms_link">Increased Claims</span>. Section 8.1 (Liability Caps) does not apply to any <span class="keyterms_link">Unlimited Claims</span>. Section 8.2 (Damages Waiver) does not apply to any <span class="keyterms_link">Increased Claims</span> or a breach of Section 12 (Confidentiality). Nothing in this Agreement will limit, exclude, or restrict a party's liability to the extent prohibited by Applicable Laws.

9. <span class="header_2" id="9">Indemnification</span>
    1. <span class="header_3" id="9.1">Protection by Provider.</span>  <span class="coverpage_link">Provider</span> will indemnify, defend, and hold harmless <span class="coverpage_link">Customer</span> from and against all <span class="keyterms_link">Provider Covered Claims</span> made by someone other than <span class="coverpage_link">Customer</span>, <span class="coverpage_link">Customer’s</span> Affiliates, or Users, and all out-of-pocket damages, awards, settlements, costs, and expenses, including reasonable attorneys’ fees and other legal expenses, that arise from the <span class="keyterms_link">Provider Covered Claims</span>.
    2. <span class="header_3" id="9.2">Protection by Customer.</span>  <span class="coverpage_link">Customer</span> will indemnify, defend, and hold harmless <span class="coverpage_link">Provider</span> from and against all <span class="keyterms_link">Customer Covered Claims</span> made by someone other than <span class="coverpage_link">Provider</span> or its Affiliates, and all out-of-pocket damages, awards, settlements, costs, and expenses, including reasonable attorneys’ fees and other legal expenses, that arise from the <span class="keyterms_link">Customer Covered Claims</span>.
    3. <span class="header_3" id="9.3">Procedure.</span>  The Indemnifying Party’s obligations in this section are contingent upon the Protected Party: (a) promptly notifying the Indemnifying Party of each Covered Claim for which it seeks protection; (b) providing reasonable assistance to the Indemnifying Party at the Indemnifying Party’s expense; and (c) giving the Indemnifying Party sole control over the defense and settlement of each Covered Claim. A Protected Party may participate in a Covered Claim for which it seeks protection with its own attorneys only at its own expense. The Indemnifying Party may not agree to any settlement of a Covered Claim that contains an admission of fault or otherwise materially and adversely impacts the Protected Party without the prior written consent of the Protected Party.
    4. <span class="header_3" id="9.4">Changes to Product.</span>  If required by settlement or court order, or if deemed reasonably necessary in response to a <span class="keyterms_link">Provider Covered Claim</span>, <span class="coverpage_link">Provider</span> may: (a) obtain the right for <span class="coverpage_link">Customer</span> to continue using the Product; (b) replace or modify the affected component of the Product without materially reducing the general functionality of the Product; or (c) if neither (a) nor (b) are reasonable, terminate the affected Order Form and issue a pro-rated refund of prepaid Fees for the remainder of the <span class="orderform_link">Subscription Period</span>.
    5. <span class="header_3" id="9.5">Exclusions.</span>
        a. <span class="coverpage_link" id="9.5.a">Provider’s</span> obligations as an Indemnifying Party will not apply to <span class="keyterms_link">Provider Covered Claims</span> that result from (i) modifications to the Product that were not authorized by Provider or that were made in compliance with <span class="coverpage_link">Customer’s</span> instructions; (ii) unauthorized use of the Product, including use in violation of this Agreement; (iii) use of the Product in combination with items not provided by <span class="coverpage_link">Provider</span>; or (iv) use of an old version of the Product where a newer release would avoid the <span class="keyterms_link">Provider Covered Claim</span>.
        b. <span class="coverpage_link" id="9.5.b">Customer’s</span> obligations as an Indemnifying Party will not apply to <span class="keyterms_link">Customer Covered Claims</span> that result from the unauthorized use of the Customer Content, including use in violation of this Agreement.
    6. <span class="header_3" id="9.6">Exclusive Remedy.</span>  This Section 9 (Indemnification), together with any termination rights, describes each Protected Party’s exclusive remedy and each Indemnifying Party’s entire liability for a Covered Claim.

10. <span class="header_2" id="10">Confidentiality</span>
    1. <span class="header_3" id="10.1">Non-Use and Non-Disclosure.</span>  Except as otherwise authorized in the Agreement or as needed to fulfill its obligations or exercise its rights under this Agreement, Recipient will not (a) use Discloser’s Confidential Information; nor (b) disclose Discloser’s Confidential Information to anyone else. In addition, Recipient will protect Discloser’s Confidential Information using at least the same protections Recipient uses for its own similar information but no less than a reasonable standard of care.
    2. <span class="header_3" id="10.2">Exclusions.</span>  Confidential Information does not include information that (a) Recipient knew without any obligation of confidentiality before disclosure by Discloser; (b) is or becomes publicly known and generally available through no fault of Recipient; (c) Recipient receives under no obligation of confidentiality from someone else who is authorized to make the disclosure; or (d) Recipient independently developed without use of or reference to Discloser’s Confidential Information.
    3. <span class="header_3" id="10.3">Required Disclosures.</span>  Recipient may disclose Discloser’s Confidential Information to the extent required by Applicable Laws if, unless prohibited by Applicable Laws, Recipient provides Discloser reasonable advance notice of the required disclosure and reasonably cooperates, at Discloser’s expense, with Discloser’s efforts to obtain confidential treatment for the Confidential Information.
    4. <span class="header_3" id="10.4">Permitted Disclosures.</span>  Recipient may disclose Discloser’s Confidential Information to Users, employees, advisors, contractors, and representatives who each have a need to know the Confidential Information, but only if the person or entity is bound by confidentiality obligations at least as protective as those in this Section 10 (Confidentiality) and Recipient remains responsible for everyone’s compliance with the terms of this Section 10 (Confidentiality).

11. <span class="header_2" id="11">Reservation of Rights</span>
    1. <span id="11.1">Except</span> for the limited license to copy and use Software and Documentation in Section 1.1 (Access and Use), <span class="coverpage_link">Provider</span> retains all right, title, and interest in and to the Product, whether developed before or after the <span class="keyterms_link">Effective Date</span>. Except for the limited rights in Section 1.5 (Customer Content) and 1.6 (Machine Learning), <span class="coverpage_link">Customer</span> retains all right, title, and interest in and to the Customer Content.

12. <span class="header_2" id="12">General Terms</span>
    1. <span class="header_3" id="12.1">Entire Agreement.</span>  This Agreement is the only agreement between the parties about its subject and this Agreement supersedes all prior or contemporaneous statements (whether in writing or not) about its subject. <span class="coverpage_link">Provider</span> expressly rejects any terms included in <span class="coverpage_link">Customer’s</span> purchase order or similar document, which may only be used for accounting or administrative purposes. No terms or conditions in any <span class="coverpage_link">Customer</span> documentation or online vendor portal will apply to <span class="coverpage_link">Customer’s</span> use of the Product unless expressly agreed to in a legally binding written agreement signed by an authorized <span class="coverpage_link">Provider</span> representative, regardless of what such terms may say.
    2. <span class="header_3" id="12.2">Modifications, Severability, and Waiver.</span>  Any waiver, modification, or change to the Agreement must be in writing and signed or electronically accepted by each party. If any term of this Agreement is determined to be invalid or unenforceable by a relevant court or governing body, the remaining terms of this Agreement will remain in full force and effect. The failure of a party to enforce a term or to exercise an option or right in this Agreement will not constitute a waiver by that party of the term, option, or right.
    3. <span class="header_3" id="12.3">Governing Law and Chosen Courts.</span>  The <span class="keyterms_link">Governing Law</span> will govern all interpretations and disputes about this Agreement, without regard to its conflict of laws provisions. The parties will bring any legal suit, action, or proceeding about this Agreement in the <span class="keyterms_link">Chosen Courts</span> and each party irrevocably submits to the exclusive jurisdiction of the <span class="keyterms_link">Chosen Courts</span>.
    4. <span class="header_3" id="12.4">Injunctive Relief.</span>  Despite Section 12.3 (Governing Law and Chosen Courts), a breach of Section 10 (Confidentiality) or the violation of a party’s intellectual property rights may cause irreparable harm for which monetary damages cannot adequately compensate. As a result, upon the actual or threatened breach of Section 10 (Confidentiality) or violation of a party’s intellectual property rights, the non-breaching or non-violating party may seek appropriate equitable relief, including an injunction, in any court of competent jurisdiction without the need to post a bond and without limiting its other rights or remedies.
    5. <span class="header_3" id="12.5">Non-Exhaustive Remedies.</span>  Except where the Agreement provides for an exclusive remedy, seeking or exercising a remedy does not limit the other rights or remedies available to a party.
    6. <span class="header_3" id="12.6">Assignment.</span>  Neither party may assign any rights or obligations under this Agreement without the prior written consent of the other party. However, either party may assign this Agreement upon notice if the assigning party undergoes a merger, change of control, reorganization, or sale of all or substantially all its equity, business, or assets to which this Agreement relates. Any attempted but non-permitted assignment is void. This Agreement will be binding upon and inure to the benefit of the parties and their permitted successors and assigns.
    7. <span class="header_3" id="12.7">Beta Products.</span>  If <span class="coverpage_link">Provider</span> gives <span class="coverpage_link">Customer</span> access to a Beta Product, the Beta Product is provided "AS IS" and Section 6.3 (Representations & Warranty From Provider) does not apply to any Beta Products. <span class="coverpage_link">Customer</span> acknowledges that Beta Products are experimental in nature and may be modified or removed at <span class="coverpage_link">Provider’s</span> discretion with or without notice.
    8. <span class="header_3" id="12.8">Logo Rights.</span>  <span class="coverpage_link">Provider</span> may identify <span class="coverpage_link">Customer</span> and use <span class="coverpage_link">Customer’s</span> name and logo in marketing to identify <span class="coverpage_link">Customer</span> as a user of <span class="coverpage_link">Provider’s</span> products and services.
    9. <span class="header_3" id="12.9">Notices.</span>  Any notice, request, or approval about the Agreement must be in writing and sent to the Notice Address. Notices will be deemed given (a) upon confirmed delivery if by email, registered or certified mail, or personal delivery; or (b) two days after mailing if by overnight commercial delivery.
    10. <span class="header_3" id="12.10">Independent Contractors.</span>  The parties are independent contractors, not agents, partners, or joint venturers. Neither party is authorized to bind the other to any liability or obligation.
    11. <span class="header_3" id="12.11">No Third-Party Beneficiary.</span>  There are no third-party beneficiaries of this Agreement.
    12. <span class="header_3" id="12.12">Force Majeure.</span>  Neither party will be liable for a delay or failure to perform its obligations of this Agreement if caused by a Force Majeure Event. However, this section does not excuse <span class="coverpage_link">Customer’s</span> obligations to pay Fees.
    13. <span class="header_3" id="12.13">Export Controls.</span>  <span class="coverpage_link">Customer</span> may not remove or export from the United States or allow the export or re-export of the Product or any related technology or materials in violation of any restrictions, laws, or regulations of the United States Department of Commerce, OFAC, or any other United States or foreign agency or authority. <span class="coverpage_link">Customer</span> represents and warrants that it is not (a) a resident or national of an Embargoed Country; (b) an entity organized under the laws of an Embargoed Country; (c) designated on any list of prohibited, restricted, or sanctioned parties maintained by the U.S. government or agencies or other applicable governments or agencies, including OFAC’s Specially Designated Nationals and Blocked Persons List and the UN Security Council Consolidated List; nor (d) 50% or more owned by any party designated on any of the above lists. <span class="coverpage_link">Provider</span> may terminate this Agreement immediately without notice or liability to comply, as determined in <span class="coverpage_link">Provider’s</span> sole discretion, with applicable export controls and sanctions laws and regulations.
    14. <span class="header_3" id="12.14">Government Rights.</span>  The Cloud Service and Software are deemed "commercial items" or "commercial computer software" according to FAR section 12.212 and DFAR section 227.7202, and the Documentation is "commercial computer software documentation" according to DFAR section 252.227-7014(a)(1) and (5). Any use, modification, reproduction, release, performance, display, or disclosure of the Product by the U.S. Government will be governed solely by the terms of this Agreement and all other use is prohibited.
    15. <span class="header_3" id="12.15">Anti-Bribery.</span>  Neither party will take any action that would be a violation of any Applicable Laws that prohibit the offering, giving, promising to offer or give, or receiving, directly or indirectly, money or anything of value to any third party to assist <span class="coverpage_link">Provider</span> or <span class="coverpage_link">Customer</span> in retaining or obtaining business. Examples of these kinds of laws include the U.S. Foreign Corrupt Practices Act and the UK Bribery Act 2010.
    16. <span class="header_3" id="12.16">Titles and Interpretation.</span>  Section titles are for convenience and reference only. All uses of "including" and similar phrases are non-exhaustive and without limitation. The United Nations Convention for the International Sale of Goods and the Uniform Computer Information Transaction Act do not apply to this Agreement.
    17. <span class="header_3" id="12.17">Signature.</span>  This Agreement may be signed in counterparts, including by electronic copies or acceptance mechanism. Each copy will be deemed an original and all copies, when taken together, will be the same agreement.

13. <span class="header_2" id="13">Definitions</span>
    1. <span class="header_3" id="13.1">Defining Variables.</span>  Variables have the meanings or descriptions given on a Cover Page. However, if the Order Form and the governing Framework Terms omit or do not define a Variable, the default meaning will be "none" or "not applicable" and the correlating clause, sentence, or section does not apply to that Agreement.
    2. <span id="13.2">**"Affiliate"**</span> means an entity that, directly or indirectly, controls, is under the control of, or is under common control with a party, where control means having more than fifty percent (50%) of the voting stock or other ownership interest.
    3. <span id="13.3">**"Agreement"**</span> means the Order Form between <span class="coverpage_link">Provider</span> and <span class="coverpage_link">Customer</span> as governed by the Framework Terms.
    4. <span id="13.4">**"Applicable Data Protection Laws"**</span> means the Applicable Laws that govern how the Cloud Service may process or use an individual’s personal information, personal data, personally identifiable information, or other similar term.
    5. <span id="13.5">**"Applicable Laws"**</span> means the laws, rules, regulations, court orders, and other binding requirements of a relevant government authority that apply to or govern <span class="coverpage_link">Provider</span> or <span class="coverpage_link">Customer</span>.
    6. <span id="13.6">**"Beta Product"**</span> means an early or prerelease feature or version of the Product that is identified as beta or similar, or a version of the Product that is not generally available.
    7. <span id="13.7">**"Cloud Service"**</span> means the product described in the Order Form.
    8. <span id="13.8">**"Confidential Information"**</span> means information in any form disclosed by or on behalf of a Discloser, including before the <span class="keyterms_link">Effective Date</span>, to a Recipient in connection with this Agreement that (a) the Discloser identifies as "confidential", "proprietary", or the like; or (b) should be reasonably understood as confidential or proprietary due to its nature and the circumstances of its disclosure. Confidential Information includes the existence of this Agreement and the information on each Cover Page. <span class="coverpage_link">Customer’s</span> Confidential Information includes non-public Customer Content and <span class="coverpage_link">Provider’s</span> Confidential Information includes non-public information about the Product.
    9. <span id="13.9">**"Cover Page"**</span> means a document that is signed or electronically accepted by the parties, incorporates these Standard Terms or is governed by the Framework Terms, and identifies <span class="coverpage_link">Provider</span> and <span class="coverpage_link">Customer</span>. A Cover Page may include an Order Form, Key Terms, or both.
    10. <span id="13.10">**"Covered Claim"**</span> means either a <span class="keyterms_link">Provider Covered Claim</span> or <span class="keyterms_link">Customer Covered Claim</span>.
    11. <span id="13.11">**"Customer Content"**</span> means data, information, or materials submitted by or on behalf of <span class="coverpage_link">Customer</span> or Users to the Product but excludes Feedback.
    12. <span id="13.12">**"Discloser"**</span> means a party to this Agreement when the party is providing or disclosing Confidential Information to the other party.
    13. <span id="13.13">**"Documentation"**</span> means the usage manuals and instructional materials for the Cloud Service or Software that are made available by <span class="coverpage_link">Provider</span>.
    14. <span id="13.14">**"Embargoed Country"**</span> means any country or region to or from where Applicable Laws generally restrict the export or import of goods, services, or money.
    15. <span id="13.15">**"Feedback"**</span> means suggestions, feedback, or comments about the Product or related offerings.
    16. <span id="13.16">**"Fees"**</span> means the applicable amounts described in an Order Form.
    17. <span id="13.17">**"Force Majeure Event"**</span> means an unforeseen event outside a party’s reasonable control where the affected party took reasonable measures to avoid or mitigate the impacts of the event. Examples of these kinds of events include unpredicted natural disasters like a major earthquake, war, pandemic, riot, act of terrorism, or public utility or internet failure.
    18. <span id="13.18">**"Framework Terms"**</span> means these Standard Terms, the Key Terms between <span class="coverpage_link">Provider</span> and <span class="coverpage_link">Customer</span>, and any policies and documents referenced in or attached to the Key Terms.
    19. <span id="13.19">**"GDPR"**</span> means European Union Regulation 2016/679 as implemented by local law in the relevant European Union member nation, and by section 3 of the United Kingdom’s European Union (Withdrawal) Act of 2018 in the United Kingdom.
    20. <span id="13.20">**"High Risk Activity"**</span> means any situation where the use or failure of the Product could be reasonably expected to lead to death, bodily injury, or environmental damage. Examples include full or partial autonomous vehicle technology, medical life-support technology, emergency response services, nuclear facilities operation, and air traffic control.
    21. <span id="13.21">**"Indemnifying Party"**</span> means a party to this Agreement when the party is providing protection for a particular Covered Claim.
    22. <span id="13.22">**"Key Terms"**</span> means a Cover Page that includes the key legal details and Variables for this Agreement. The Key Terms may include details about Covered Claims, set the <span class="keyterms_link">Governing Law</span>, or contain other details about this Agreement.
    23. <span id="13.23">**"OFAC"**</span> means the United States Department of Treasury's Office of Foreign Assets Control.
    24. <span id="13.24">**"Order Form"**</span> means a Cover Page that includes the key business details and Variables for this Agreement that are not defined in the Framework Terms. An Order Form includes the policies and documents referenced in or attached to the Order Form. An Order Form may include details about the level of access and use granted to the Cloud Service, length of <span class="orderform_link">Subscription Period</span>, or other details about the Product.
    25. <span id="13.25">**"Personal Data"**</span> will have the meaning(s) set forth in the Applicable Data Protection Laws for personal information, personal data, personally identifiable information, or other similar term.
    26. <span id="13.26">**"Product"**</span> means the Cloud Service, Software, and Documentation.
    27. <span id="13.27">**"Prohibited Data"**</span> means (a) patient, medical, or other protected health information regulated by the Health Insurance Portability and Accountability Act; (b) credit, debit, bank account, or other financial account numbers; (c) social security numbers, driver’s license numbers, or other unique and private government ID numbers; (d) special categories of data as defined in the GDPR; and (e) other similar categories of sensitive information as set forth in the Applicable Data Protection Laws.
    28. <span id="13.28">**"Protected Party"**</span> means a party to this Agreement when the party is receiving the benefit of protection for a particular Covered Claim.
    29. <span id="13.29">**"Recipient"**</span> means a party to this Agreement when the party receives Confidential Information from the other party.
    30. <span id="13.30">**"Software"**</span> means the client-side software or applications made available by <span class="coverpage_link">Provider</span> for <span class="coverpage_link">Customer</span> to install, download (whether onto a machine or in a browser), or execute as part of the Product.
    31. <span id="13.31">**"Standard Terms"**</span> means these Common Paper Cloud Service Agreement Standard Terms Version 2.0, which are posted at <https://commonpaper.com/standards/cloud-service-agreement/2.0/>.
    32. <span id="13.32">**"Usage Data"**</span> means data and information about the provision, use, and performance of the Product and related offerings based on <span class="coverpage_link">Customer’s</span> or User’s use of the Product.
    33. <span id="13.33">**"User"**</span> means any individual who uses the Product on <span class="coverpage_link">Customer’s</span> behalf or through <span class="coverpage_link">Customer’s</span> account.
    34. <span id="13.34">**"Variable"**</span></span> means a word or phrase that is highlighted and capitalized, such as <span class="orderform_link">Subscription Period</span> or <span class="keyterms_link">Governing Law</span>.
    
`

  return (
    <Container 
    // className={classes.wrapper}
    >
<Stack

direction="column"
spacing="md"
align="center"
justify="center"
styles={{ marginTop: "50px" }}
>
<Box>
<Paper>
<MarkdownPreview source={cloudserviceagreement} style={{ padding: 16 }} />
</Paper>


</Box>
</Stack>
    
    </Container>
  );
}
