import React, { Link, useContext, useEffect } from "react";
// import Footer from '../sections/Footer';
import { MantineProvider, Global, Title, createStyles } from "@mantine/core";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
// import { Redirect } from 'react-router-dom';
// import { UserContext } from '../hooks/UserContext';
// import LandingCenterBlock from '../components/LandingCenterBlock';
 
import LandingCenterBlock from "../components/LandingCenterBlock";
import { showLeftSideState } from "../recoil_state";

import InlineButton from "./../components/InlineButton";
const support_link_mm =
  "https://mm.setip.io/setipio/channels/setipio-community-help";
const support_link_frond =
  "https://frond.com/5vcvon/home?invite=07ff6b5c-628a-4f89-b098-1540e1eb90d6";
const support_link_slack = "https://setipio.slack.com";
const docs_link = "";
const blog_link = "https://blog.setip.io";
const youtube_link =
  "https://youtube.com/playlist?list=PLIWLq5yeCZaKXnw2TySkF1vkkKxLzuHTD";
const reddit_link = "https://www.reddit.com/r/setipio/";

const useStyles = createStyles((theme) => ({
  wrapper: {
    // paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    paddingLeft: theme.spacing.xl * 2,
    paddingRight: theme.spacing.xl * 2,
    minHeight: 650,
  },

  title: {
    marginBottom: theme.spacing.sm * 1.5,
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,

    border: `0px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

export default function Support() {
  const { classes } = useStyles();

  const [showLeftSide, setShowLeftSide] = useRecoilState(showLeftSideState);

  useEffect(() => {
    console.log("setShowLeftSide false");
    setShowLeftSide(true);
  });
  return (
    <>


      <Title order={2}>Community Support</Title>

      <div className="LandingCenterBlockFrame">
        <span>On Frond</span>
        <a
          href={`${support_link_frond}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {" "}
          <InlineButton name={"join"} />{" "}
        </a>
      </div>

      <div className="LandingCenterBlockFrame">
        <span>On Reddit</span>
        <a href={`${reddit_link}`} rel="noopener noreferrer" target="_blank">
          {" "}
          <InlineButton name={"join"} />{" "}
        </a>
      </div>

      <Title order={2}>Learn</Title>

      {/*
            <div className="LandingCenterBlockFrame">
             <a href={`${docs_link}`} rel="noopener noreferrer" target="_blank"> <InlineButton name={"Docs"} /> </a> 
            </div>
            <div className="LandingCenterBlockFrame">
             <a href={`${blog_link}`} rel="noopener noreferrer" target="_blank"> <InlineButton name={"blog"} /> </a> 
            </div>
            */}
      <div className="LandingCenterBlockFrame">
        <a href={`${youtube_link}`} rel="noopener noreferrer" target="_blank">
          {" "}
          <InlineButton name={"youtube"} />{" "}
        </a>
      </div>

      {/* <Footer/> */}

      <div />
    </>
  );
}
